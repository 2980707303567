import React, { useMemo } from 'react';
import {
  useProjectsQuery,
  useSetProjectNameMutation,
  useFindProjectQuery,
  useSetProjectPhaseMutation,
  ProjectPhase,
  useCreateTaskGroupMutation,
  useCreateTaskMutation,
  FindProjectDocument,
  FindProjectQuery,
  VisibilityStatus,
  useUpdateTaskStatusMutation,
  useUpdateTaskNameMutation,
  useDeleteTaskMutation,
  useUpdateTaskGroupPositionMutation,
  useUpdateTaskLocationMutation,
  useUpdateTaskGroupNameMutation,
  useDeleteTaskGroupMutation,
  useToggleTaskCompleteMutation,
} from 'shared/generated/graphql';
import Navbar from 'App/Navbar';
import Button from 'shared/components/Button';
import onDragEnd from 'Projects/Edit/onDragEnd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Link,
  useParams,
  Switch,
  Route,
  useRouteMatch,
  RouteComponentProps,
  Router,
  useHistory,
} from 'react-router-dom';
import { Act, Check, Do, Plan, AngleRight } from 'shared/icons';
import styled, { css } from 'styled-components';

import { BoardContent, Container, InnerContainer, ProjectHeader, ProjectTitle } from './Styles';
import List, { Tasks } from 'Projects/Board/List';
import Card from 'Projects/Board/Card';
import ProjectTabs from 'Projects/Tabs';
import ProjectsEdit from 'Projects/Edit';
import Upload from 'Projects/Upload';
import { useCurrentUser } from 'App/context';
import Feedback from 'Projects/Feedback';
import Details from 'Projects/Details';
import AddList from 'shared/components/AddList';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import AddCard from 'shared/components/AddCard';
import Settings from './Settings';
import { Popup, usePopup } from 'shared/components/PopupMenu';

type TaskRouteProps = {
  taskID: string;
};
const ViewProjects = styled(Link)`
  position: absolute;
  top: 24px;
  left: 42px;
  padding: 6px 12px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
`;

type ProjectStatusProps = {
  status: ProjectPhase;
  onClick: (phase: ProjectPhase) => void;
};

const EditButton = styled.button`
  position: absolute;
  top: 24px;
  right: 42px;
  padding: 6px 12px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
`;

const TaskGroupTasks = styled.div`
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  border: 1px solid #e8ecee;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  flex-basis: 0;
  min-height: 35px;
  &:hover {
    border-color: #c5c7c9;
  }
`;

const TaskName = styled.div`
  flex: 1 1 0;
  padding-left: 8px;
`;

const TaskStatus = styled.div`
  padding-left: 8px;
`;

const ProjectStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TaskGroupTitle = styled.span`
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 50px;
`;

const AngleIcon = styled.div<{ current: boolean; complete: boolean }>`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  svg {
    fill: rgba(255, 255, 255, 0.35);
  }
  ${props =>
    props.complete &&
    css`
      svg {
        fill: rgba(255, 255, 255, 1);
      }
    `}
  ${props =>
    props.current &&
    css`
      svg {
        fill: rgba(255, 255, 255, 0.75);
      }
    `}
`;

const ProgressIcon = styled.div<{ current: boolean; complete: boolean }>`
  cursor: pointer;
  display: flex;
  margin: 0 8px;
  align-items: center;
  justify-content: center;
  svg {
    fill: rgba(255, 255, 255, 0.35);
  }
  ${props =>
    props.complete &&
    css`
      svg {
        fill: rgba(255, 255, 255, 1);
      }
    `}
  ${props =>
    props.current &&
    css`
      svg {
        fill: rgba(255, 255, 255, 0.75);
      }
    `}
`;

const phases = [ProjectPhase.Plan, ProjectPhase.Do, ProjectPhase.Tidy, ProjectPhase.Act];

const ProjectStatus: React.FC<ProjectStatusProps> = ({ status, onClick }) => {
  return (
    <ProjectStatusWrapper>
      {phases.map((phase, phaseIdx) => {
        let Icon = null;
        switch (phase) {
          case ProjectPhase.Plan:
            Icon = <Plan width={120} height={120} />;
            break;
          case ProjectPhase.Do:
            Icon = <Do width={120} height={120} />;
            break;
          case ProjectPhase.Tidy:
            Icon = <Check width={120} height={120} />;
            break;
          case ProjectPhase.Act:
            Icon = <Act width={120} height={120} />;
            break;
          default:
            break;
        }
        const statusIdx = phases.findIndex(p => p === status);
        return (
          <React.Fragment key={phase}>
            {phase !== ProjectPhase.Plan && (
              <AngleIcon current={phase === status} complete={statusIdx > phaseIdx}>
                <AngleRight width={48} height={48} />
              </AngleIcon>
            )}
            <ProgressIcon
              onClick={() => {
                onClick(phase);
              }}
              current={phase === status}
              complete={statusIdx > phaseIdx}
            >
              {Icon}
            </ProgressIcon>
          </React.Fragment>
        );
      })}
    </ProjectStatusWrapper>
  );
};

const ConfirmButton = styled(Button)`
  width: 100%;
  padding: 6px 12px;
`;
type ProjectParams = {
  projectID: string;
};
const ProjectGroups = styled.div<{ columns: number }>`
  user-select: none;
  white-space: nowrap;
  min-width: ${props => props.columns * 340}px;
`;

const BoardContentWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  outline: none;
  flex-grow: 1;
`;
const TaskGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

type ProjectsViewProps = {
  projectID: string;
};

const ProjectsView: React.FC<ProjectsViewProps> = ({ projectID }) => {
  const { data, error } = useFindProjectQuery({
    variables: { projectID },
    pollInterval: process.env.NODE_ENV === 'production' ? 5000 : 0,
  });
  const history = useHistory();
  const { user } = useCurrentUser();
  const [createTask] = useCreateTaskMutation({
    update: (client, resp) =>
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            const targetIdx = cache.findProject.taskGroups.findIndex(tg => tg.id === resp.data.createTask.taskGroupID);
            if (targetIdx !== -1) {
              draftCache.findProject.taskGroups[targetIdx].tasks.push({
                ...resp.data.createTask.task,
                complete: false,
                badges: { __typename: 'TaskBadges', comments: 0, description: false },
              });
            }
          }),
        { projectID },
      ),
  });

  const { showPopup, hidePopup } = usePopup();
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const [toggleComplete] = useToggleTaskCompleteMutation();
  const [updateTaskName] = useUpdateTaskNameMutation();
  const [updateTaskGroupName] = useUpdateTaskGroupNameMutation();
  const [updateTaskGroupPosition] = useUpdateTaskGroupPositionMutation();
  const [updateTaskLocation] = useUpdateTaskLocationMutation({
    update: (client, resp) =>
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            const taskID = resp.data.updateTaskLocation.task.id;
            const prevTaskGroupID = resp.data.updateTaskLocation.previousTaskGroupID;
            const curTaskGroupID = resp.data.updateTaskLocation.task.taskGroup.id;
            const prevTaskGroupIdx = cache.findProject.taskGroups.findIndex(tg => tg.id === prevTaskGroupID);
            const curTaskGroupIdx = cache.findProject.taskGroups.findIndex(tg => tg.id === curTaskGroupID);
            if (prevTaskGroupID === curTaskGroupID) {
              return;
            }
            if (prevTaskGroupIdx !== -1 && curTaskGroupIdx !== -1) {
              const task = cache.findProject.taskGroups[prevTaskGroupIdx].tasks.find(t => t.id === taskID);
              if (!task) {
                return;
              }
              draftCache.findProject.taskGroups[prevTaskGroupIdx].tasks = cache.findProject.taskGroups[
                prevTaskGroupIdx
              ].tasks.filter(t => t.id !== taskID);
              draftCache.findProject.taskGroups[curTaskGroupIdx].tasks.push({
                ...task,
                __typename: 'Task',
                position: resp.data.updateTaskLocation.task.position,
                taskGroup: {
                  __typename: 'TaskGroupMinimal',
                  id: resp.data.updateTaskLocation.task.taskGroup.id,
                },
              });
            }
          }),
        { projectID },
      ),
  });
  const [deleteTask] = useDeleteTaskMutation({
    update: (client, resp) =>
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            const targetIdx = cache.findProject.taskGroups.findIndex(tg => tg.id === resp.data.deleteTask.taskGroupID);
            if (targetIdx !== -1) {
              draftCache.findProject.taskGroups[targetIdx].tasks = cache.findProject.taskGroups[targetIdx].tasks.filter(
                t => t.id !== resp.data.deleteTask.taskID,
              );
            }
          }),
        { projectID },
      ),
  });
  const [setProjectPhase] = useSetProjectPhaseMutation();
  const [setProjectName] = useSetProjectNameMutation();
  const [createTaskGroup] = useCreateTaskGroupMutation({
    update: (client, resp) =>
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            console.log('updating cache');
            draftCache.findProject.taskGroups.push({ ...resp.data.createTaskGroup.taskGroup, tasks: [] });
          }),
        { projectID },
      ),
  });
  const [deleteTaskGroup] = useDeleteTaskGroupMutation({
    update: (client, resp) =>
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            console.log('beep', draftCache);
            draftCache.findProject.taskGroups = cache.findProject.taskGroups.filter(
              c => c.id !== resp.data.deleteTaskGroup.taskGroupID,
            );
          }),
        { projectID },
      ),
  });
  if (data && user) {
    return (
      <>
        <Navbar
          title={data.findProject.name}
          inlayHeight={375}
          onTitleChange={title => {
            setProjectName({ variables: { name: title, projectID } });
          }}
        />
        <Container>
          <InnerContainer>
            <ProjectStatus
              onClick={phase => {
                setProjectPhase({
                  variables: {
                    projectID,
                    phase,
                  },
                });
              }}
              status={data.findProject.status}
            />
            <ProjectTabs
              meetingURL={data.findProject.meetingUrl}
              websiteURL={data.findProject.websiteUrl}
              projectID={projectID}
              editable={false}
            />
            <Switch>
              <Route path={`/projects/${projectID}/upload`}>
                <Upload projectID={projectID} />
              </Route>
              <Route path={`/projects/${projectID}/feedback`}>
                <Feedback projectID={projectID} />
              </Route>
              <Route path={`/projects/${projectID}/settings`}>
                <Settings
                  members={data.findProject.members}
                  websiteURL={data.findProject.websiteUrl}
                  meetingURL={data.findProject.meetingUrl}
                  projectID={projectID}
                />
              </Route>
              <Route path={`/projects/${projectID}`}>
                <BoardContentWrapper>
                  <BoardContent>
                    <DragDropContext
                      onDragEnd={result =>
                        onDragEnd(
                          result,
                          data.findProject.taskGroups,
                          taskGroup => {
                            updateTaskGroupPosition({
                              variables: { taskGroupID: taskGroup.id, position: taskGroup.position },
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateTaskGroupPosition: {
                                  __typename: 'UpdateTaskGroupPositionPayload',
                                  taskGroup: {
                                    __typename: 'TaskGroup',
                                    id: taskGroup.id,
                                    position: taskGroup.position,
                                  },
                                },
                              },
                            });
                          },
                          (task, id) => {
                            console.log(task, id);
                            updateTaskLocation({
                              variables: {
                                taskID: task.id,
                                taskGroupID: task.taskGroup.id,
                                position: task.position,
                              },
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateTaskLocation: {
                                  __typename: 'UpdateTaskLocationPayload',
                                  previousTaskGroupID: id,
                                  task: {
                                    __typename: 'Task',
                                    id: task.id,
                                    position: task.position,
                                    taskGroup: {
                                      __typename: 'TaskGroupMinimal',
                                      id: task.taskGroup.id,
                                    },
                                  },
                                },
                              },
                            });
                          },
                        )
                      }
                    >
                      <Droppable direction="horizontal" type="column" droppableId="root">
                        {rootProvided => (
                          <ProjectGroups
                            columns={data.findProject.taskGroups.length}
                            {...rootProvided.droppableProps}
                            ref={rootProvided.innerRef}
                          >
                            {data.findProject.taskGroups
                              .slice()
                              .sort((a, b) => a.position - b.position)
                              .map((tg, index) => {
                                return (
                                  <Draggable draggableId={tg.id} key={tg.id} index={index}>
                                    {columnDragProvided => (
                                      <Droppable type="tasks" droppableId={tg.id}>
                                        {(columnDropProvided, snapshot) => (
                                          <List
                                            innerRef={columnDragProvided.innerRef}
                                            handleProps={columnDragProvided.dragHandleProps}
                                            wrapperProps={columnDragProvided.draggableProps}
                                            key={tg.id}
                                            name={tg.name}
                                            editable={user.isAdmin()}
                                            onDelete={$target => {
                                              showPopup(
                                                { ref: $target },
                                                <Popup tab={0} title={null}>
                                                  <p>
                                                    Are you sure you want to delete this list? This can not be undone.
                                                  </p>
                                                  <ConfirmButton
                                                    onClick={() => {
                                                      hidePopup();
                                                      deleteTaskGroup({ variables: { projectID, taskGroupID: tg.id } });
                                                    }}
                                                    color="danger"
                                                  >
                                                    Delete list
                                                  </ConfirmButton>
                                                </Popup>,
                                              );
                                            }}
                                            onChangeName={name =>
                                              updateTaskGroupName({ variables: { taskGroupID: tg.id, name } })
                                            }
                                            onCreateTask={taskName => {
                                              const tasks = tg.tasks.slice().sort((a, b) => a.position - b.position);
                                              let pos = 2048;
                                              if (tasks[tasks.length - 1]) {
                                                pos = tasks[tasks.length - 1].position;
                                                pos *= 2;
                                              }
                                              createTask({
                                                variables: {
                                                  taskGroupID: tg.id,
                                                  name: taskName,
                                                  position: pos,
                                                  status: VisibilityStatus.Internal,
                                                },
                                              });
                                            }}
                                          >
                                            <Tasks
                                              ref={columnDropProvided.innerRef}
                                              {...columnDropProvided.droppableProps}
                                              editable={user.isAdmin()}
                                            >
                                              {tg.tasks
                                                .slice()
                                                .sort((a, b) => a.position - b.position)
                                                .map((task, taskIndex) => (
                                                  <Draggable key={task.id} draggableId={task.id} index={taskIndex}>
                                                    {taskProvided => (
                                                      <Card
                                                        innerRef={taskProvided.innerRef}
                                                        comments={task.badges.comments}
                                                        description={task.badges.description}
                                                        complete={task.complete}
                                                        handleProps={taskProvided.dragHandleProps}
                                                        wrapperProps={taskProvided.draggableProps}
                                                        editable={user.isAdmin()}
                                                        key={task.id}
                                                        onClick={() => {
                                                          history.push(`/projects/${projectID}/c/${task.id}`);
                                                        }}
                                                        actions={{
                                                          onNameChange: name => {
                                                            updateTaskName({ variables: { taskID: task.id, name } });
                                                          },
                                                          onStatusChange: status => {
                                                            updateTaskStatus({
                                                              variables: { taskID: task.id, status },
                                                            });
                                                          },
                                                          onToggleComplete: complete => {
                                                            toggleComplete({
                                                              variables: { taskID: task.id, complete },
                                                            });
                                                          },
                                                          onDelete: () => {
                                                            deleteTask({ variables: { taskID: task.id } });
                                                          },
                                                        }}
                                                        status={task.status}
                                                        name={task.name}
                                                      />
                                                    )}
                                                  </Draggable>
                                                ))}
                                            </Tasks>
                                          </List>
                                        )}
                                      </Droppable>
                                    )}
                                  </Draggable>
                                );
                              })}
                            <Route
                              path={`/projects/${projectID}/c/:taskID`}
                              render={(routeProps: RouteComponentProps<TaskRouteProps>) => (
                                <Details projectID={projectID} taskID={routeProps.match.params.taskID} />
                              )}
                            />
                          </ProjectGroups>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {user.isAdmin() && (
                      <AddList
                        onSave={name => {
                          const groups = data.findProject.taskGroups.slice().sort((a, b) => a.position - b.position);

                          let pos = 2048;
                          if (groups[groups.length - 1]) {
                            pos = groups[groups.length - 1].position;
                            pos *= 2;
                          }
                          createTaskGroup({ variables: { projectID, name, position: pos } });
                        }}
                      />
                    )}
                  </BoardContent>
                </BoardContentWrapper>
              </Route>
            </Switch>
          </InnerContainer>
        </Container>
      </>
    );
  }
  return <Navbar inlayHeight={375} />;
};

const Projects: React.FC = () => {
  const { projectID } = useParams<ProjectParams>();
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.url}>
        <ProjectsView projectID={projectID} />
      </Route>
    </Switch>
  );
};

export default Projects;
