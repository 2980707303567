import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Button from 'shared/components/Button';
import { Trash, Cross } from 'shared/icons';
import AsyncSelect from 'react-select/async';
import CommentCreator from 'Projects/CommentCreator';
import TaskAssignee from 'shared/components/TaskAssignee';
import Ellipsis from 'shared/icons/Ellipsis';
import Comments from 'Projects/Comments';
import {
  useSetMeetingUrlMutation,
  useAllFeedbackQuery,
  useFeedbackQuery,
  useCreateFeedbackCommentMutation,
  useDeleteProjectMemberMutation,
  useDeleteProjectMutation,
  FeedbackQuery,
  FeedbackDocument,
  useDeleteFeedbackCommentMutation,
  useUpdateFeedbackCommentMutation,
  useCreateProjectMemberMutation,
  useMeQuery,
  useSetWebsiteUrlMutation,
  useProjectViewLogQuery,
  FindProjectDocument,
  FindProjectQuery,
} from 'shared/generated/graphql';
import dayjs from 'dayjs';
import * as QueryString from 'query-string';
import { useLocation, useRouteMatch, Switch, Route, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import { usePopup, Popup } from 'shared/components/PopupMenu';
import { useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

const InviteUsers = styled(Button)`
  margin-top: 12px;
  padding: 6px 12px;
`;

type UserOptionProps = {
  innerProps: any;
  isDisabled: boolean;
  isFocused: boolean;
  label: string;
  data: any;
  getValue: any;
};

const ProjectViewLogEntry = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
`;

const ProjectViewLog = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  ${ProjectViewLogEntry}:first-child span {
    font-weight: bold;
    padding-top: 0;
  }
`;
const ProjectViewLogEntryName = styled.span`
  width: 60%;
`;
const ProjectViewLogEntryCount = styled.span`
  margin-right: 8px;
  width: 10%;
`;
const ProjectViewLogEntryLast = styled.span`
  width: 30%;
  text-align: right;
`;
const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const OptionLabel = styled.span<{ fontSize: number; quiet: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${p => p.fontSize}px;
  color: #000;
`;

const OptionWrapper = styled.div<{ isFocused: boolean }>`
  cursor: pointer;
  padding: 4px 8px;
  ${props => props.isFocused && `background: rgba(${props.theme.colors.primary});`}
  display: flex;
  align-items: center;
  ${props =>
    props.isFocused &&
    css`
      ${OptionLabel} {
        color: #fff;
      }
    `}
  &:hover ${OptionLabel} {
    color: #fff;
  }
`;

const UserOption: React.FC<UserOptionProps> = ({ isDisabled, isFocused, innerProps, label, data }) => {
  return !isDisabled ? (
    <OptionWrapper {...innerProps} isFocused={isFocused}>
      <TaskAssignee
        size={32}
        member={{
          id: '',
          profileIcon: data.value.profileIcon,
        }}
      />
      <OptionContent>
        <OptionLabel fontSize={16} quiet={false}>
          {label}
        </OptionLabel>
        <OptionLabel fontSize={14} quiet>
          {data.value.email}
        </OptionLabel>
      </OptionContent>
    </OptionWrapper>
  ) : null;
};

const OptionValueWrapper = styled.div`
  background: rgba(255, 163, 59);
  border-radius: 4px;
  margin: 2px;
  padding: 3px 6px 3px 4px;
  display: flex;
  align-items: center;
`;

const OptionValueLabel = styled.span`
  font-size: 12px;
  color: #fff;
`;

const OptionValueRemove = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-left: 4px;
  svg {
    fill: #fff;
    stroke: #fff;
  }
`;
const OptionValue = ({ data, removeProps }: any) => {
  return (
    <OptionValueWrapper>
      <OptionValueLabel>{data.label}</OptionValueLabel>
      <OptionValueRemove {...removeProps}>
        <Cross width={14} height={14} />
      </OptionValueRemove>
    </OptionValueWrapper>
  );
};

const fetchMembers = async (client: any, projectID: string, input: string, cb: any) => {
  if (input && input.trim().length < 3) {
    return [];
  }
  const res = await client.query({
    query: gql`
    query {
      searchMembers(input: {searchFilter:"${input}", projectID:"${projectID}"}) {
        id
        similarity
        user {
          id
          fullName
          email
        }
      }
    }
    `,
  });

  let results: any = [];
  if (res.data && res.data.searchMembers) {
    results = [
      ...res.data.searchMembers.map((m: any) => {
        return {
          label: m.user.fullName,
          value: {
            id: m.user.id,
            email: m.user.email,
            profileIcon: {
              bgColor: '#ccc',
              initials: m.user.fullName.charAt(0),
              url: null,
            },
          },
        };
      }),
    ];
  }

  return results;
};

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const MenuLabel = styled.span<{ fontSize: number; quiet: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${p => p.fontSize}px;
  color: #000;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  &:hover {
    background: rgba(${props => props.theme.colors.primary});
  }
  &:hover ${MenuLabel} {
    color: #fff;
  }
`;

const MembersCard = styled.div`
  margin-left: 12px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #00000026;
  border-radius: 18px;
  flex: 1 1;

  & .react_select__control {
    background: #383838;
    border-radius: 16px;
    border: none;
    box-shadow: none;
  }
  & .react_select__input {
    color: #fff;
  }
  & .react_select__placeholder {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.8px;
  }
  & .react_select__indicator-separator {
    display: none;
  }
  & .react_select__indicator {
    color: #fff;
  }
`;

const SettingsCard = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #e8ecee;
  border-radius: 8px;
`;

const Container = styled.div`
  display: flex;
  width: 960px;
`;

const Sidebar = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-left: 32px;
`;

const SidebarTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  margin-bottom: 14px;
`;

const EmptyWarning = styled.div`
  padding: 16px;
`;

const FeedbackItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedbackItem = styled(Link)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const FeedbackItemHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 675px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const HeaderTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`;

const LeaveFeedbackBtn = styled(Button)`
  padding: 6px 12px;
`;

const FeedbackCard = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid #e8ecee;
  border-radius: 8px;
`;

const FeedbackHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0;
`;

const FeedbackBody = styled.div`
  padding: 0 24px;
`;

const FeedbackComments = styled.div`
  background: #f6f8f9;
  border-top: 1px solid #e8ecee;
  flex-shrink: 0;
`;

const FeedbackTitle = styled.h3`
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
`;

const FeedbackControls = styled.div`
  display: flex;
  align-items: center;
`;

const FeedbackControlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const FeedbackBodyItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const CreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

const Summary = styled.div`
  padding-bottom: 24px;
`;

const SummaryTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #000;
`;

type LatestFeedbackProps = {
  name: string;
  createdBy: { fullName: string };
  createdAt: string;
  summary: string;
  comments: Array<{ id: string; summary: string; createdAt: string; createdBy: { id: string; fullName: string } }>;
  onCreateComment: (summary: string) => void;
  onDeleteComment: (id: string) => void;
  onEditComment: (id: string, message: string) => void;
  me: { id: string; profileIcon: ProfileIcon };
};
const LatestFeedback: React.FC<LatestFeedbackProps> = ({
  name,
  createdAt,
  createdBy,
  summary,
  me,
  comments,
  onCreateComment,
  onDeleteComment,
  onEditComment,
}) => {
  return (
    <FeedbackCard>
      <FeedbackHeader>
        <FeedbackTitle>{name}</FeedbackTitle>
        <FeedbackControls>
          <FeedbackControlIcon>
            <Ellipsis width={16} height={16} />
          </FeedbackControlIcon>
        </FeedbackControls>
      </FeedbackHeader>
      <FeedbackBody>
        <FeedbackBodyItems>
          <Creator>
            <CreatorName>{createdBy.fullName}</CreatorName>
            <CreatorDate>{dayjs.duration(dayjs(createdAt).diff(dayjs())).humanize(true)}</CreatorDate>
          </Creator>
          <SummaryTitle>Summary</SummaryTitle>
          <Summary>{summary}</Summary>
        </FeedbackBodyItems>
        <Comments onEdit={onEditComment} onDelete={onDeleteComment} comments={comments} />
      </FeedbackBody>
      <FeedbackComments>
        <CommentCreator member={me} onCreateComment={onCreateComment} />
      </FeedbackComments>
    </FeedbackCard>
  );
};

const CommentCreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CommentCreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;
const FormInput = styled.input`
  width: 100%;

  border: none;
  background: #383838;
  border-radius: 16px;
  margin-top: 8px;
  padding: 6px 12px;
  font-size: 18px;
  color: #fff;
  &:placeholder {
    color: #fff;
    opacity: 1;
  }
`;
const FormLabel = styled.label`
  font-size: 14px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const ActionRow = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SaveButton = styled(Button)`
  padding: 6px 12px;
`;

const ConfirmDelete = styled(Button)`
  padding: 6px 12px;
  width: 100%;
`;

type ClientEntryProps = {
  member: { id: string; email: string; fullName: string };
  onClick: ($target: React.RefObject<HTMLDivElement>) => void;
};
const ClientEntry: React.FC<ClientEntryProps> = ({ onClick, member }) => {
  const $container = useRef<HTMLDivElement>(null);

  return (
    <MenuItem ref={$container} onClick={() => onClick($container)}>
      <TaskAssignee
        size={32}
        member={{
          id: member.id,
          profileIcon: {
            url: null,
            bgColor: '#ccc',
            initials: member.fullName.charAt(0),
          },
        }}
      />
      <OptionContent>
        <MenuLabel fontSize={16} quiet={false}>
          {member.fullName}
        </MenuLabel>
        <MenuLabel fontSize={14} quiet>
          {member.email}
        </MenuLabel>
      </OptionContent>
    </MenuItem>
  );
};
type SettingsData = {
  website_url: string;
  meeting_url: string;
};

type SettingsProps = {
  projectID: string;
  websiteURL?: string | null;
  meetingURL?: string | null;
  members: Array<{ id: string; fullName: string; email: string }>;
};

const Settings: React.FC<SettingsProps> = ({ projectID, websiteURL, meetingURL, members }) => {
  const { handleSubmit, register } = useForm<SettingsData>();
  const [setWebsiteURL] = useSetWebsiteUrlMutation();
  const [setMeetingURL] = useSetMeetingUrlMutation();
  const { showPopup, hidePopup } = usePopup();
  const client = useApolloClient();
  const { data, loading } = useProjectViewLogQuery({ variables: { projectID } });
  const onSubmit = (data: SettingsData) => {
    setWebsiteURL({ variables: { projectID, websiteUrl: data.website_url.trim() !== '' ? data.website_url : null } });
    setMeetingURL({ variables: { projectID, meetingUrl: data.meeting_url.trim() !== '' ? data.meeting_url : null } });
    toast('Settings saved');
  };
  const history = useHistory();
  const [deleteProject] = useDeleteProjectMutation({ onCompleted: () => history.push('/') });
  const [deleteProjectMember] = useDeleteProjectMemberMutation({
    update: (client, r) =>
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.findProject.members = cache.findProject.members.filter(
              m => m.id !== r.data.deleteProjectMember.userID,
            );
          }),
        { projectID },
      ),
  });
  const [invitedUsers, setInvitedUsers] = useState<Array<any> | null>(null);
  const [createProjectMember] = useCreateProjectMemberMutation({
    update: (client, r) =>
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            r.data.createProjectMember.users.forEach((user: any) => {
              draftCache.findProject.members.push(user);
            });
          }),
        { projectID },
      ),
  });
  if (data) {
    return (
      <Container>
        <ContentContainer>
          <SettingsCard>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormField>
                <FormLabel>Website URL</FormLabel>
                <FormInput
                  placeholder="https://example.org"
                  name="website_url"
                  defaultValue={websiteURL ?? ''}
                  ref={register()}
                />
              </FormField>
              <FormField>
                <FormLabel>Meeting URL</FormLabel>
                <FormInput
                  placeholder="https://example.org"
                  name="meeting_url"
                  defaultValue={meetingURL ?? ''}
                  ref={register()}
                />
              </FormField>
              <ActionRow>
                <SaveButton type="submit">Save</SaveButton>
                <SaveButton
                  color="danger"
                  onClick={$ref =>
                    showPopup(
                      { ref: $ref },
                      <Popup tab={0} title="Delete project?">
                        <p>Are you sure you want to delete this project? This action can not be undone.</p>
                        <ConfirmDelete
                          color="danger"
                          onClick={() => {
                            hidePopup();
                            deleteProject({ variables: { projectID } });
                          }}
                        >
                          Delete project
                        </ConfirmDelete>
                      </Popup>,
                    )
                  }
                >
                  Delete Project
                </SaveButton>
              </ActionRow>
            </Form>
            <ProjectViewLog>
              <ProjectViewLogEntry>
                <ProjectViewLogEntryName>Name</ProjectViewLogEntryName>
                <ProjectViewLogEntryCount>View Count</ProjectViewLogEntryCount>
                <ProjectViewLogEntryLast>Last Viewed</ProjectViewLogEntryLast>
              </ProjectViewLogEntry>
              {data.projectViewLog.logs &&
                data.projectViewLog.logs.map(entry => (
                  <ProjectViewLogEntry>
                    <ProjectViewLogEntryName>{entry.user.fullname}</ProjectViewLogEntryName>
                    <ProjectViewLogEntryCount>{entry.count}</ProjectViewLogEntryCount>
                    <ProjectViewLogEntryLast>
                      {dayjs.duration(dayjs(entry.lastViewed).diff(dayjs())).humanize(true)}
                    </ProjectViewLogEntryLast>
                  </ProjectViewLogEntry>
                ))}
            </ProjectViewLog>
          </SettingsCard>
        </ContentContainer>
        <MembersCard>
          <h3>Collaboration Team</h3>
          <AsyncSelect
            getOptionValue={option => option.value.id}
            placeholder="Email address or username"
            noOptionsMessage={() => null}
            onChange={(e: any) => {
              setInvitedUsers(e);
            }}
            isMulti
            autoFocus
            cacheOptions
            classNamePrefix="react_select"
            defaultOption
            components={{
              MultiValue: OptionValue,
              Option: UserOption,
              IndicatorSeparator: null,
              DropdownIndicator: null,
            }}
            loadOptions={(i, cb) => fetchMembers(client, projectID, i, cb)}
          />
          <MenuItems>
            {members.map(member => (
              <ClientEntry
                key={member.id}
                member={member}
                onClick={$target => {
                  showPopup(
                    { ref: $target },
                    <Popup title={null} tab={0}>
                      <MenuItems>
                        <MenuItem
                          onClick={() => {
                            hidePopup();
                            deleteProjectMember({ variables: { projectID, userID: member.id } });
                          }}
                        >
                          Remove
                        </MenuItem>
                      </MenuItems>
                    </Popup>,
                  );
                }}
              />
            ))}
          </MenuItems>
          <InviteUsers
            onClick={() => {
              console.log(invitedUsers);
              if (invitedUsers) {
                setInvitedUsers(null);
                createProjectMember({ variables: { projectID, users: invitedUsers.map(u => u.value.id) } });
              }
            }}
            disabled={invitedUsers === null}
          >
            Invite
          </InviteUsers>
        </MembersCard>
      </Container>
    );
  }
  return (
    <Container>
      <ContentContainer>
        <SettingsCard>
          <h3>Loading</h3>
        </SettingsCard>
      </ContentContainer>
    </Container>
  );
};

export default Settings;
