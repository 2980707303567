import React, { useState, useRef } from 'react';
import {
  useUsersQuery,
  useDeleteUserAccountMutation,
  useCreateUserAccountMutation,
  useUpdateUserFullnameMutation,
  RoleCode,
  UsersQuery,
  UsersDocument,
  useResetPasswordMutation,
  useUpdateUserRoleMutation,
  useMeQuery,
  useRemoveAvatarMutation,
} from 'shared/generated/graphql';
import { Link, useHistory, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import { Cross, CheckSquare, CheckSquareOutline, Square, Inbox, Tasks, Payment, Support } from 'shared/icons';
import { usePopup, Popup } from 'shared/components/PopupMenu';
import Button from 'shared/components/Button';
import { useForm, Controller } from 'react-hook-form';
import { useCurrentUser } from 'App/context';
import Navbar from 'App/Navbar';
import { getAccessToken } from 'shared/utils/accessToken';
import axios from 'axios';

const InputField = styled.input`
  width: 100%;

  border: none;
  margin-top: 4px;
  padding: 6px 12px;
  font-size: 18px;
  color: #000;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const InputLabel = styled.span`
  margin-top: 8px;
  font-size: 18px;
`;

const TermSheetsTab = () => {
  return null;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InfoSave = styled(Button)`
  margin-top: 12px;
`;

const ChangePictureLabel = styled.span`
  font-size: 20px;
  margin-top: 8px;
  &:hover {
    text-decoration: underline;
  }
`;

const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 24px;
`;

const RemovePictureButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #000;
  svg {
    fill: #fff;
    stroke: #fff;
  }
`;

const ProfileIconImage = styled.img`
width 260px;
height 260px;
border-radius: 50%;
`;

type InfoOverviewTabProps = {
  name: string;
  email: string;
  onSave: (data: { name: string }) => void;
};
const InfoOverviewTab: React.FC<InfoOverviewTabProps> = ({ name, email, onSave }) => {
  const { handleSubmit, register, errors } = useForm<{ name: string }>();
  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <InputLabel>Name</InputLabel>
      <InputField
        ref={register({ required: 'Name is required' })}
        name="name"
        id="name"
        type="text"
        defaultValue={name}
      />
      {errors.name && <span>{errors.name.message}</span>}
      <InputLabel>Email</InputLabel>
      <InputField disabled type="text" value={email} />
      <InfoSave type="submit">Save</InfoSave>
    </Form>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  position: relative;
  z-index: 10;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  max-width: 100%;
  padding: 24px 32px;
  width: 100%;
  max-width: 1400px;
`;

const ProfileContainer = styled.div`
  background: #f4f4f4;
  border-radius: 33px;
  width: 27.5%;
`;

const InfoContainer = styled.div`
  background: #f4f4f4;
  border-radius: 33px;
  margin: 0 30px;
  width: 36.25%;
`;

const QuicklinksContainer = styled.div`
  background: #f4f4f4;
  border-radius: 33px;
  width: 36.25%;
`;

const QuicklinksGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 24px;
  padding-top: 0;
`;

const QuicklinksGridItemLabel = styled.div`
  font-size: 24px;
  text-align: center;
`;

const QuicklinksTitle = styled.h3`
  font-size: 20px;
  text-align: center;
  margin-top: 6px;
`;

const QuicklinksGridItemExternal = styled.a<{ negativeMargins?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 8px 0;
  flex: 1 1 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  svg {
    fill: #383838;
    stroke: #383838;
    ${p => p.negativeMargins && 'margin-right: -15px;'}
  }
  &:hover {
    margin-top: -3px;
  }
`;

const QuicklinksGridItem = styled(Link)<{ negativeMargins?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 8px 0;
  flex: 1 1 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  svg {
    fill: #383838;
    stroke: #383838;
    ${p => p.negativeMargins && 'margin-right: -15px;'}
  }
  &:hover {
    margin-top: -3px;
  }
`;

const Tab = styled.div<{ active: boolean }>`
  background: ${p => (p.active ? '#F4F4F4' : '#E5E5E5')};
  display: flex;
  align-item: center;
  justify-content: center;
  flex: 1 0;
  height: 100%;
  cursor: pointer;
`;

const Tabs = styled.div`
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  & ${Tab}:first-child {
    border-top-left-radius: 33px;
  }
  & ${Tab}:last-child {
    border-top-right-radius: 33px;
  }
`;

const TabLabel = styled.span`
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 12px;
`;

const Profile: React.FC = () => {
  const [tab, setTab] = useState(0);
  const { data, refetch } = useMeQuery();
  const [removeAvatar] = useRemoveAvatarMutation();
  const [updateUserFullname] = useUpdateUserFullnameMutation();
  const $fileUpload = useRef<HTMLInputElement>(null);

  if (data) {
    return (
      <>
        <input
          type="file"
          name="file"
          style={{ display: 'none' }}
          ref={$fileUpload}
          onChange={e => {
            if (e.target.files) {
              const fileData = new FormData();
              fileData.append('file', e.target.files[0]);
              const accessToken = getAccessToken();
              axios
                .post('/users/me/avatar', fileData, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                })
                .then(res => {
                  if ($fileUpload && $fileUpload.current) {
                    $fileUpload.current.value = '';
                    refetch();
                  }
                });
            }
          }}
        />
        <Navbar title="Your Account" inlayHeight={175} />
        <Container>
          <InnerContainer>
            <ProfileContainer>
              <ProfileIcon>
                <ProfileIconImage
                  src={data.me.avatar.default ? `${data.me.avatar.url}&size=200` : data.me.avatar.url}
                />
                {data.me.avatar.default ? (
                  <ChangePictureLabel
                    onClick={() => {
                      if ($fileUpload && $fileUpload.current) {
                        $fileUpload.current.click();
                      }
                    }}
                  >
                    Upload picture
                  </ChangePictureLabel>
                ) : (
                  <>
                    <ChangePictureLabel
                      onClick={() => {
                        if ($fileUpload && $fileUpload.current) {
                          $fileUpload.current.click();
                        }
                      }}
                    >
                      Change picture
                    </ChangePictureLabel>
                    <RemovePictureButton onClick={() => removeAvatar()}>
                      <Cross width={24} height={24} />
                    </RemovePictureButton>
                  </>
                )}
              </ProfileIcon>
            </ProfileContainer>
            <InfoContainer>
              <Tabs>
                <Tab onClick={() => setTab(0)} active={tab === 0}>
                  <TabLabel>info overview</TabLabel>
                </Tab>
                <Tab onClick={() => setTab(1)} active={tab === 1}>
                  <TabLabel>term sheets</TabLabel>
                </Tab>
              </Tabs>
              <TabContent>
                {tab === 0 && (
                  <InfoOverviewTab
                    onSave={({ name }) => {
                      updateUserFullname({ variables: { fullname: name } });
                    }}
                    email={data.me.email}
                    name={data.me.fullName}
                  />
                )}
                {tab === 1 && <TermSheetsTab />}
              </TabContent>
            </InfoContainer>
            <QuicklinksContainer>
              <QuicklinksTitle>quicklinks</QuicklinksTitle>
              <QuicklinksGrid>
                <QuicklinksGridItem to="/profile/inbox">
                  <Inbox width={72} height={72} />
                  <QuicklinksGridItemLabel>inbox</QuicklinksGridItemLabel>
                </QuicklinksGridItem>
                <QuicklinksGridItem negativeMargins to="/">
                  <Tasks width={72} height={72} />
                  <QuicklinksGridItemLabel>tasks</QuicklinksGridItemLabel>
                </QuicklinksGridItem>
                <QuicklinksGridItem to="/">
                  <Payment width={72} height={72} />
                  <QuicklinksGridItemLabel>payments</QuicklinksGridItemLabel>
                </QuicklinksGridItem>
                <QuicklinksGridItemExternal href="mailto:support@drivendigital.us">
                  <Support width={72} height={72} />
                  <QuicklinksGridItemLabel>support</QuicklinksGridItemLabel>
                </QuicklinksGridItemExternal>
              </QuicklinksGrid>
            </QuicklinksContainer>
          </InnerContainer>
        </Container>
      </>
    );
  }
  return <Navbar inlayHeight={175} />;
};

export default Profile;
