import React from 'react';
import Icon, { IconProps } from './Icon';

const Payment: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 1000 1000">
      <g>
        <path
          d="M600.03,556.71c-6.34-14.55-16.56-28.89-30.64-42.97l-85.24-85.24c-10.34-10.8-17.96-20.43-22.9-28.88
		c-4.93-8.46-7.4-17.61-7.4-27.47c0-15.02,6.22-25.36,18.67-31c12.44-5.63,28.99-8.45,49.67-8.45c16.43,0,30.87,1.65,43.32,4.93
		c12.44,3.29,21.01,6.34,25.71,9.16c5.16-6.1,8.81-13.27,10.92-21.49c2.12-8.21,3.17-14.2,3.17-17.97c0-2.82-3.17-6.22-9.51-10.21
		c-6.34-3.99-16.55-7.51-30.64-10.57c-8.79-1.9-19.6-3.21-32.41-3.93v-85.19c-16.9,0-29.71,1.41-38.39,4.23
		c-8.69,2.82-13.03,9.16-13.03,19.02v62.8c-24.38,3.01-44.23,10.49-59.53,22.44c-20.43,15.97-30.64,38.28-30.64,66.92
		c0,12.68,3.05,25.48,9.16,38.39c6.1,12.92,15.5,26.19,28.18,39.8l78.9,80.31c14.55,15.04,24.77,27.72,30.64,38.04
		c5.87,10.34,8.81,22.31,8.81,35.93c0,18.79-5.53,33.46-16.55,44.03c-11.04,10.56-27.37,15.85-48.96,15.85
		c-18.32,0-33.71-1.99-46.14-5.99c-12.45-3.99-22.19-8.81-29.24-14.44c-2.82,3.29-5.4,8.22-7.75,14.8
		c-2.36,6.58-4.23,12.92-5.64,19.02c-1.41,6.11-2.11,10.11-2.11,11.98c0,3.76,3.28,7.99,9.86,12.68c6.57,4.7,16.67,9.05,30.29,13.03
		c10.36,3.03,23.05,4.91,38.04,5.64v80.66c17.84,0,30.99-1.3,39.45-3.87c8.45-2.59,12.68-8.81,12.68-18.67v-60.74
		c15.23-2.64,28.19-7.01,38.74-13.23c18.32-10.8,31.23-25.01,38.75-42.62c7.5-17.61,11.27-36.74,11.27-57.41
		C609.55,587.71,606.37,571.27,600.03,556.71z"
        />
        <g>
          <path
            d="M500,953.93C249.7,953.93,46.07,750.3,46.07,500S249.7,46.07,500,46.07S953.93,249.7,953.93,500
			S750.3,953.93,500,953.93z M500,112.35c-213.75,0-387.65,173.9-387.65,387.65S286.25,887.65,500,887.65S887.65,713.75,887.65,500
			S713.75,112.35,500,112.35z"
          />
        </g>
      </g>
    </Icon>
  );
};

export default Payment;
