import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'shared/components/Button';
import TextareaAutosize from 'react-autosize-textarea';
import { Trash } from 'shared/icons';
import CommentCreator from 'Projects/CommentCreator';
import TaskAssignee from 'shared/components/TaskAssignee';
import Ellipsis from 'shared/icons/Ellipsis';
import Comments from 'Projects/Comments';
import {
  useAllFeedbackQuery,
  useFeedbackQuery,
  useCreateProjectFeedbackMutation,
  AllFeedbackDocument,
  AllFeedbackQuery,
} from 'shared/generated/graphql';
import dayjs from 'dayjs';
import * as QueryString from 'query-string';
import { useLocation, useRouteMatch, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';

const Summary = styled(TextareaAutosize)`
  width: 100%;
  line-height: 20px;
  border-radius: 6px;
  color: #000;
  background: #fff;
  border: none;
  margin-left: -5px;
  padding: 4px 6px;
  border: 1px solid #ccc;
  transition: max-height 200ms, height 200ms, min-height 200ms;
  min-height: 36px;
  max-height: 36px;
  &:not(:focus) {
    height: 36px;
  }
  &:focus {
    min-height: 80px;
    max-height: none;
    line-height: 20px;
  }
`;
const Container = styled.div`
  display: flex;
  width: 960px;
`;

const Sidebar = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-left: 32px;
  border-left: 1px solid #e8ecee;
`;

const SidebarTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  color: #273240;
  margin-bottom: 8px;
`;

const FeedbackItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedbackItem = styled(Link)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const FeedbackItemHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 675px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const HeaderTitle = styled.h3`
  font-size: 16px;
  color: #000;
`;
const CancelFeedbackBtn = styled(Button)`
  padding: 6px 12px;
  margin-right: 4px;
`;

const LeaveFeedbackBtn = styled(Button)`
  padding: 6px 12px;
`;

const FeedbackCard = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid #e8ecee;
  border-radius: 8px;
`;

const FeedbackHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0;
`;

const FeedbackBody = styled.div`
  padding: 0 24px;
`;

const FeedbackComments = styled.div`
  background: #f6f8f9;
  border-top: 1px solid #e8ecee;
  flex-shrink: 0;
`;

const FeedbackTitle = styled.input`
  line-height: 28px;
  border-radius: 6px;
  color: #000;
  background: #fff;
  margin-left: -5px;
  padding: 4px 6px;
  border: 1px solid #ccc;
`;

const FeedbackControls = styled.div`
  display: flex;
  align-items: center;
`;

const FeedbackControlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const FeedbackBodyItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const CreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

const SummaryTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #000;
`;

const CommentCreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CommentCreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

type FeedbackProps = {
  projectID: string;
};

const Feedback: React.FC<FeedbackProps> = ({ projectID }) => {
  const location = useLocation();
  const history = useHistory();
  const params = QueryString.parse(location.search);
  const [createProjectFeedback] = useCreateProjectFeedbackMutation({
    onCompleted: r => {
      const feedbackID = r.createProjectFeedback.feedback.id;
      history.push(`/projects/${projectID}/feedback?feedbackID=${feedbackID}`);
    },
    update: (client, r) =>
      updateApolloCache<AllFeedbackQuery>(
        client,
        AllFeedbackDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.allFeedback.feedback.unshift({
              __typename: 'ProjectFeedback',
              id: r.data.createProjectFeedback.feedback.id,
              name: r.data.createProjectFeedback.feedback.name,
              createdAt: r.data.createProjectFeedback.feedback.createdAt,
            });
          }),
        { projectID },
      ),
  });
  let feedbackID: string | null = null;
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [isCreating, setCreating] = useState(false);
  if (typeof params.feedbackID === 'string') {
    feedbackID = params.feedbackID;
  }

  return (
    <>
      <Header>
        <HeaderTitle>New feedback</HeaderTitle>
        <HeaderActions>
          <LeaveFeedbackBtn
            disabled={name.trim() === ''}
            onClick={() => {
              setCreating(true);
              createProjectFeedback({ variables: { projectID, name: name.trim(), summary } });
            }}
          >
            Post feedback
          </LeaveFeedbackBtn>
          <Link to={`/projects/${projectID}/feedback`}>
            <CancelFeedbackBtn color="danger">Cancel Feedback</CancelFeedbackBtn>
          </Link>
        </HeaderActions>
      </Header>
      <FeedbackCard>
        <FeedbackHeader>
          <FeedbackTitle
            disabled={isCreating}
            placeholder="Title"
            value={name}
            onChange={e => setName(e.currentTarget.value)}
          />
          <FeedbackControls>
            <FeedbackControlIcon>
              <Ellipsis width={16} height={16} />
            </FeedbackControlIcon>
          </FeedbackControls>
        </FeedbackHeader>
        <FeedbackBody>
          <FeedbackBodyItems>
            <SummaryTitle>Summary</SummaryTitle>
            <Summary
              disabled={isCreating}
              placeholder="Summary..."
              value={summary}
              onChange={e => setSummary(e.currentTarget.value)}
            />
          </FeedbackBodyItems>
        </FeedbackBody>
      </FeedbackCard>
    </>
  );
};

export default Feedback;
