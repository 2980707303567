import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { colourStyles } from 'shared/components/Select';
import { getAccessToken } from 'shared/utils/accessToken';
import * as QueryString from 'query-string';
import CreatableSelect from 'react-select/creatable';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useRouteMatch, useLocation, useHistory } from 'react-router';

import Dropzone from 'react-dropzone';
import Button from 'shared/components/Button';
import { Cross, Upload as UploadIcon, Plus } from 'shared/icons';
import axios from 'axios';
import produce from 'immer';
import { toast } from 'react-toastify';
import {
  useCategoriesQuery,
  useAttachmentsQuery,
  useCreateAttachmentCategoryMutation,
  useCreateAttachmentTagMutation,
  AttachmentsQuery,
  AttachmentsDocument,
  useDeleteAttachmentTagMutation,
} from 'shared/generated/graphql';
import { useCurrentUser } from 'App/context';
import UploadsBrowser, { Attachment, Category } from 'Projects/FileBrowser';
import Modal from 'shared/components/Modal';
import Checkmark from 'shared/icons/Checkmark';
import { Popup, usePopup } from 'shared/components/PopupMenu';
import updateApolloCache from 'shared/utils/cache';

const ConfirmButton = styled(Button)`
  padding: 8px 12px;
  width: 100%;
`;
const WarningLabel = styled.p`
  font-size: 14px;
  color: #000;
  padding: 8px 0;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  &:hover {
    background: rgba(${props => props.theme.colors.primary});
  }
`;

const AddTagButton = styled(Button)`
  padding: 6px 12px;
`;
const AddTagActions = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
`;
const CancelTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
`;

type AddTagManagerProps = {
  onAddTag: (tag: string) => void;
};

const AddTagInput = styled.input`
  width: 100%;

  border: none;
  background: #383838;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 18px;
  color: #fff;
`;

const AddTagManager: React.FC<AddTagManagerProps> = ({ onAddTag }) => {
  const [tag, setTag] = useState('');
  return (
    <>
      <AddTagInput
        type="text"
        autoFocus
        placeholder="Tag name"
        value={tag}
        onChange={e => setTag(e.currentTarget.value)}
      />
      <AddTagActions>
        <AddTagButton onClick={() => onAddTag(tag)}>Create tag</AddTagButton>
      </AddTagActions>
    </>
  );
};

const UploadedByProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const UploadedByProfileImage = styled.img`
  width: 24px;
  height: 24px;
`;

const UploadTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 12px;
`;
const UploadContainer = styled.div`
  padding: 24px 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CategoryCreatorInput = styled.input`
  width: 100%;

  border: none;
  background: #383838;
  border-radius: 16px;
  margin-top: 8px;
  padding: 6px 12px;
  font-size: 18px;
  color: #fff;
  &:placeholder {
    color: #fff;
    opacity: 1;
  }
`;

const CategoryCreatorButton = styled(Button)`
  margin-top: 8px;
  padding: 6px 12px;
  width: 100%;
`;

type CategoryCreatorProps = {
  onCreateCategory: (name: string) => void;
};

const CategoryCreator: React.FC<CategoryCreatorProps> = ({ onCreateCategory }) => {
  const [name, setName] = useState('');
  return (
    <>
      <CategoryCreatorInput
        autoFocus
        placeholder="Category Name"
        onChange={e => setName(e.currentTarget.value)}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            onCreateCategory(name);
          }
        }}
        value={name}
      />
      <CategoryCreatorButton onClick={() => onCreateCategory(name)}>Create</CategoryCreatorButton>
    </>
  );
};
const CommentCreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CommentCreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

const Container = styled.div`
  display: flex;
  max-width: 1400px;
  width: 100%;
`;

const Sidebar = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  min-width: 270px;
`;

const FilterTags = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterTag = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  cursor: pointer;
  justify-content: space-between;

  border-radius: 6px;
  color: #000;
  svg {
    fill: #fff;
    stroke: #fff;
  }
  ${props =>
    props.active
      ? css`
          background: rgba(${props.theme.colors.primary});
          color: #fff;
        `
      : css`
          &:hover {
            background: rgba(21, 27, 38, 0.04);
          }
        `}
`;
const FilterBar = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  min-width: 270px;
`;
const FilterBarCard = styled.div`
  margin-right: 12px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #00000026;
  border-radius: 18px;
`;

const SidebarCard = styled.div`
  margin-left: 12px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #00000026;
  border-radius: 18px;
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const SidebarAddTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  svg {
    fill: #000;
  }
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const SidebarTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  color: #000;
`;

const EmptyWarning = styled.div`
  padding: 16px;
`;

const FeedbackItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedbackItem = styled(Link)`
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const FeedbackItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0;
`;

const UploadMeta = styled.div``;

const UploadedBy = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #6f7782;
`;

const FileCancel = styled(Cross)`
  cursor: pointer;
  &:hover {
    fill: #ffa33b;
  }
`;
const Files = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  max-width: 688px;
  flex-wrap: wrap;
`;

const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px 18px;
  border: 1px solid #e8ecee;
  border-radius: 8px;
  margin: 0 4px;
  position: relative;
  overflow: hidden;
`;
const FileLabel = styled.div`
  display: flex;
  align-items: center;
`;

const FileUploadProgress = styled.div`
  margin-top: 4px;
  width: 100%;
`;

const FileUploadProgressBar = styled.div<{ progress?: number }>`
  width: ${props => props.progress}%;
  height: 6px;
  border-radius: 3px;
  background-color: #ffa33b;
`;

const FileName = styled.span`
  font-size: 12px;
  padding-right: 4px;
`;

const FilePreview = styled.img`
  width: 64px;
  height: 64px;
`;

const UploadButton = styled(Button)`
  margin-top: 12px;
  padding: 6px 12px;
  border-radius: 22px;
  background: #383838;
  color: #fff;
  margin-bottom: 24px;
`;
const DropSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 16px;

  background-color: #383838;
  border: 2px dashed #f59320;
  color: #fff;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 24px;
  width: 100%;
  min-height: 250px;
  justify-content: center;
`;
const ReactSelectStyles = createGlobalStyle`
`;

const SelectRow = styled.div`
  width: 100%;
  display: flex;
  & > div {
    width: 100%;
    margin: 0 5px;
  }
  & .react_select__control {
    background: #383838;
    border-radius: 16px;
    border: none;
  }
  & .react_select__placeholder {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.8px;
  }
  & .react_select__single-value {
    color: #fff;
  }
  & .react_select__indicator-separator {
    display: none;
  }
  & .react_select__indicator {
    color: #fff;
  }
  & .react_select__input {
    color: #fff;
  }
`;

type UploadDropzoneProps = {
  onUpload: () => void;
  projectID: string;
};

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const defaultOptions = [
  createOption('Homepage'),
  createOption('Landing Page'),
  createOption('Our Works'),
  createOption('Inner Pages'),
];

const defaultTagOptions = [
  createOption('SEO'),
  createOption('Website Design'),
  createOption('Website Hosting'),
  createOption('Website Optimization'),
];

const UploadDropzone: React.FC<UploadDropzoneProps> = ({ projectID, onUpload }) => {
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState<any>(null);
  const [isLoadingTags, setLoadingTags] = useState(false);
  const [tagOptions, setTagOptions] = useState(defaultTagOptions);
  const [createCategory] = useCreateAttachmentCategoryMutation({
    onCompleted: data => {
      setLoading(false);
      const newOption = {
        label: data.createAttachmentCategory.category.name,
        value: data.createAttachmentCategory.category.id,
      };
      setOptions([...options, newOption]);
      setValue(newOption);
    },
  });
  const [tagValue, setTagValue] = useState<any>(null);
  const [createTag] = useCreateAttachmentTagMutation({
    onCompleted: data => {
      setLoadingTags(false);
      const newOption = {
        label: data.createAttachmentTag.tag.name,
        value: data.createAttachmentTag.tag.id,
      };
      setTagOptions([...tagOptions, newOption]);
      setTagValue([...(tagValue ?? []), newOption]);
    },
  });
  const handleChange = (newValue: any, actionMeta: any) => {
    setValue(newValue);
  };
  const handleCreate = (inputValue: any) => {
    setLoading(true);
    createCategory({ variables: { name: inputValue, projectID } });
  };
  const handleChangeTags = (newValue: any, actionMeta: any) => {
    setTagValue(newValue);
  };
  const [isUploading, setUploading] = useState(false);
  const [files, setFiles] = useState<Array<File> | null>(null);
  const [fileProgress, setFileProgress] = useState<Map<string, number>>(new Map<string, number>());
  const [fileThumbnail, setFileThumbnail] = useState<Map<string, any>>(new Map<string, any>());
  const { data, loading } = useCategoriesQuery({ variables: { projectID } });
  const handleCreateTags = (inputValue: any) => {
    setLoadingTags(true);
    setTimeout(() => {
      setLoadingTags(false);
      createTag({ variables: { name: inputValue, projectID } });
    }, 1000);
  };
  useEffect(() => {
    if (fileProgress) {
      const total = fileProgress.size;
      let complete = 0;
      fileProgress.forEach(progress => {
        if (progress === 100) {
          complete += 1;
        }
      });
      if (total !== 0 && complete === total) {
        toast('Upload complete');
        setUploading(false);
        onUpload();
      }
    }
  }, [fileProgress]);
  const { user } = useCurrentUser();
  if (data) {
    return (
      <UploadContainer>
        <UploadTitle>File Upload</UploadTitle>
        <ReactSelectStyles />
        <SelectRow>
          <CreatableSelect
            isClearable
            isDisabled={isLoading}
            isLoading={isLoading}
            classNamePrefix="react_select"
            placeholder="Select category"
            onChange={handleChange}
            onCreateOption={handleCreate}
            options={data.categories.categories.map(c => ({ label: c.name, value: c.id }))}
            value={value}
          />

          <CreatableSelect
            isMulti
            isClearable
            placeholder="Select tags"
            isDisabled={isLoadingTags}
            isLoading={isLoadingTags}
            classNamePrefix="react_select"
            onChange={handleChangeTags}
            onCreateOption={handleCreateTags}
            options={data.tags.tags.map(c => ({ label: c.name, value: c.id }))}
            value={tagValue}
          />
        </SelectRow>
        <Dropzone
          onDrop={acceptedFiles => {
            console.log(acceptedFiles);
            setFiles(prevFiles => [...acceptedFiles, ...(prevFiles ?? [])]);
            setFileProgress(prevProgress =>
              produce(prevProgress, draftProgress => {
                acceptedFiles.forEach(f => {
                  var reader = new FileReader();
                  var url = reader.readAsDataURL(f);
                  reader.onloadend = function(e) {
                    setFileThumbnail(prev =>
                      produce(prev, draftPrev => {
                        draftPrev.set(f.name, reader.result);
                      }),
                    );
                  };
                  if (draftProgress) {
                    draftProgress.set(f.name, 0);
                  }
                });
              }),
            );
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <DropSection {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </DropSection>
          )}
        </Dropzone>
        {files && (
          <Files>
            {files.map(file => (
              <File>
                <FileLabel>
                  <FilePreview src={fileThumbnail.get(file.name)} />
                  <FileName>{file.name}</FileName>
                  {fileProgress && fileProgress.get(file.name) === 0 && (
                    <FileCancel
                      width={12}
                      height={12}
                      onClick={() => {
                        setFiles(files.filter(f => f.name !== file.name));
                      }}
                    />
                  )}
                </FileLabel>
                {fileProgress && (
                  <FileUploadProgress>
                    <FileUploadProgressBar progress={fileProgress ? fileProgress.get(file.name) : 0} />
                  </FileUploadProgress>
                )}
              </File>
            ))}
          </Files>
        )}
        <UploadButton
          disabled={value === null || files === null || isUploading}
          onClick={() => {
            if (files) {
              setFileProgress(
                produce(new Map<string, number>(), draftProgress => {
                  files.forEach(file => draftProgress.set(file.name, 0));
                }),
              );
              setUploading(true);
              files.forEach(file => {
                const fileData = new FormData();
                fileData.append('file', file);
                let tags = [];
                if (tagValue) {
                  tags = tagValue.map((t: any) => t.value);
                }
                const data = {
                  projectID,
                  categoryID: value.value,
                  tags,
                };
                console.log(data);
                fileData.append('params', JSON.stringify(data));
                const accessToken = getAccessToken();
                axios.post('/api/projects/attachment', fileData, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                  onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setFileProgress(currentProgress => {
                      return produce(currentProgress, draftProgress => {
                        if (draftProgress) {
                          draftProgress.set(file.name, percentCompleted);
                        }
                      });
                    });
                  },
                });
              });
            }
          }}
        >
          Upload
        </UploadButton>
      </UploadContainer>
    );
  }
  return null;
};

type FilterTagEntryProps = {
  id: string;
  name: string;
  params: { tag: any };
  url: string;
  onClick: (nextUrl: string) => void;
  onContext: ($target: React.RefObject<HTMLElement>) => void;
};

const FilterTagEntry: React.FC<FilterTagEntryProps> = ({ id, name, params, url, onClick, onContext }) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  return (
    <FilterTag
      ref={$wrapper}
      key={id}
      active={id === params.tag}
      onContextMenu={e => {
        e.preventDefault();
        onContext($wrapper);
      }}
      onClick={() => {
        if (id === params.tag) {
          onClick(url);
        } else {
          onClick(`${url}?tag=${id}`);
        }
      }}
    >
      <span>{name}</span>
      {id === params.tag && <Checkmark width={16} height={16} />}
    </FilterTag>
  );
};

type UploadProps = {
  projectID: string;
};

const Upload: React.FC<UploadProps> = ({ projectID }) => {
  const { data, loading, refetch } = useAttachmentsQuery({ variables: { projectID }, fetchPolicy: 'network-only' });
  const [showUpload, setShowUpload] = useState(false);
  const history = useHistory();
  const location = useLocation<{ category: string; attachment: string }>();
  const match = useRouteMatch();
  const params = QueryString.parse(location.search);
  let findAttachment = null;
  const [deleteAttachmentTag] = useDeleteAttachmentTagMutation({
    update: (client, r) =>
      updateApolloCache<AttachmentsQuery>(
        client,
        AttachmentsDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.tags.tags = cache.tags.tags.filter(t => t.id !== r.data.deleteAttachmentTag.id);
            for (let i = 0; i < cache.attachments.attachments.length; i++) {
              draftCache.attachments.attachments[i].tags = cache.attachments.attachments[i].tags.filter(
                a => a.id !== r.data.deleteAttachmentTag.id,
              );
            }
          }),
        {
          projectID,
        },
      ),
  });
  const $addTag = useRef<HTMLDivElement>(null);
  const [createCategory] = useCreateAttachmentCategoryMutation({
    update: (client, r) =>
      updateApolloCache<AttachmentsQuery>(
        client,
        AttachmentsDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.categories.categories.push({
              id: r.data.createAttachmentCategory.category.id,
              name: r.data.createAttachmentCategory.category.name,
              __typename: 'AttachmentCategory',
            });
          }),
        {
          projectID,
        },
      ),
  });
  const { showPopup, setTab, hidePopup } = usePopup();
  if (location.state && location.state['category'] && location.state['attachment']) {
    findAttachment = {
      category: location.state['category'],
      attachment: location.state['attachment'],
    };
  }
  if (data) {
    const latestUploads = data.attachments.attachments.slice(0, 5);
    const names = new Map<string, string>();
    const pool = new Map<string, Array<Attachment>>();
    data.attachments.attachments.forEach(attachment => {
      if (params.tag && attachment.tags.findIndex(c => c.id === params.tag) === -1) {
        return;
      }
      if (!names.has(attachment.category.id)) {
        names.set(attachment.category.id, attachment.category.name);
      }

      if (!pool.has(attachment.category.id)) {
        pool.set(attachment.category.id, []);
      }
      pool.set(attachment.category.id, [
        {
          id: attachment.id,
          filename: attachment.filename,
          filepath: attachment.filepath,
          tags: attachment.tags,
          size: attachment.size,
        },
        ...pool.get(attachment.category.id),
      ]);
    });
    const categories = new Map<string, Category>();
    pool.forEach((attachments, categoryID) => {
      const name = names.get(categoryID);
      if (name) {
        categories.set(categoryID, {
          name,
          attachments,
        });
      }
    });
    data.categories.categories.forEach(category => {
      if (!categories.has(category.id)) {
        categories.set(category.id, { name: category.name, attachments: [] });
      }
    });
    return (
      <>
        <Container>
          <FilterBar>
            <FilterBarCard>
              <SidebarHeader>
                <SidebarTitle>Tags</SidebarTitle>
                <SidebarAddTag
                  ref={$addTag}
                  onClick={() => {
                    showPopup(
                      { ref: $addTag },
                      <Popup tab={0} title="Create a new tag" onClose={() => hidePopup()}>
                        <AddTagManager onAddTag={() => {}} />
                      </Popup>,
                    );
                  }}
                >
                  <Plus width={12} height={12} />
                </SidebarAddTag>
              </SidebarHeader>
              <FilterTags>
                {data.tags.tags.map(t => (
                  <FilterTagEntry
                    key={t.id}
                    name={t.name}
                    id={t.id}
                    url={match.url}
                    onContext={$target => {
                      showPopup(
                        { ref: $target },
                        <>
                          <Popup tab={0} title="Create a new category" onClose={() => hidePopup()}>
                            <MenuItems>
                              <MenuItem onClick={() => setTab(1)}>Delete tag</MenuItem>
                            </MenuItems>
                          </Popup>
                          <Popup tab={1} title={'Delete tag?'}>
                            <WarningLabel>
                              Are you sure you want to delete this tag? No attachments will be deleted.{' '}
                            </WarningLabel>
                            <ConfirmButton
                              color="danger"
                              onClick={() => {
                                deleteAttachmentTag({ variables: { id: t.id } });
                                history.push(match.url);
                                hidePopup();
                              }}
                            >
                              Delete tag
                            </ConfirmButton>
                          </Popup>
                        </>,
                      );
                    }}
                    onClick={next => history.push(next)}
                    params={{ tag: params.tag }}
                  />
                ))}
              </FilterTags>
            </FilterBarCard>
          </FilterBar>
          <UploadsBrowser
            onNewCategoryClick={$target =>
              showPopup(
                { ref: $target },
                <Popup tab={0} title={null}>
                  <CategoryCreator
                    onCreateCategory={name => {
                      createCategory({ variables: { projectID, name } });
                      hidePopup();
                    }}
                  />
                </Popup>,
              )
            }
            findAttachment={findAttachment}
            projectID={projectID}
            categories={categories}
            onUploadClick={() => setShowUpload(true)}
          />
          <Sidebar>
            <SidebarCard>
              <SidebarTitle>Recent uploads</SidebarTitle>
              <FeedbackItems>
                {latestUploads.length === 0 && <EmptyWarning>No recent uploads</EmptyWarning>}
                {latestUploads.map(upload => (
                  <FeedbackItem
                    to={{ pathname: `${match.url}`, state: { attachment: upload.id, category: upload.category.id } }}
                    key={upload.id}
                  >
                    <UploadedByProfile>
                      <UploadedByProfileImage src={upload.uploadedBy.avatar.url} />
                    </UploadedByProfile>
                    <FeedbackItemHeader>
                      <UploadMeta>
                        <CommentCreatorName>{upload.filename}</CommentCreatorName>
                        <UploadedBy>{upload.uploadedBy.fullname}</UploadedBy>
                      </UploadMeta>
                      <CommentCreatorDate>
                        {dayjs.duration(dayjs(upload.uploadedAt).diff(dayjs())).humanize(true)}
                      </CommentCreatorDate>
                    </FeedbackItemHeader>
                  </FeedbackItem>
                ))}
              </FeedbackItems>
            </SidebarCard>
          </Sidebar>
        </Container>
        {showUpload && (
          <Modal
            width={1070}
            onClose={() => setShowUpload(false)}
            renderContent={() => <UploadDropzone onUpload={() => refetch()} projectID={projectID} />}
          />
        )}
      </>
    );
  }
  return (
    <Container>
      <FilterBar>
        <FilterBarCard>
          <SidebarTitle>Tags</SidebarTitle>
        </FilterBarCard>
      </FilterBar>
      <UploadsBrowser
        onNewCategoryClick={() => {}}
        projectID=""
        findAttachment={null}
        categories={new Map<string, Category>()}
        onUploadClick={() => {}}
      />
      <Sidebar>
        <SidebarCard>
          <SidebarTitle>Recent uploads</SidebarTitle>
        </SidebarCard>
      </Sidebar>
    </Container>
  );
};
export default Upload;
