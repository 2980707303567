import React, { useEffect } from 'react';

/**
 * A simple React hook for differentiating single and double clicks on the same component.
 *
 * @param {node} ref Dom node to watch for double clicks
 * @param {number} [latency=300] The amount of time (in milliseconds) to wait before differentiating a single from a double click
 * @param {function} onSingleClick A callback function for single click events
 * @param {function} onDoubleClick A callback function for double click events
 */

type DoubleClickOptions = {
  ref: React.RefObject<HTMLElement>;
  latency: number;
  onSingleClick: () => void;
  onDoubleClick: () => void;
};
type UseDoubleClickFn = (options: DoubleClickOptions) => void;

const useDoubleClick: UseDoubleClickFn = ({ ref, latency = 300, onSingleClick, onDoubleClick }) => {
  useEffect(() => {
    const clickRef = ref.current;
    let clickCount = 0;
    const handleClick = () => {
      clickCount += 1;

      setTimeout(() => {
        if (clickCount === 1) {
          onSingleClick();
        } else if (clickCount === 2) {
          onDoubleClick();
        }
        clickCount = 0;
      }, latency);
    };

    // Add event listener for click events
    if (clickRef) {
      clickRef.addEventListener('click', handleClick);
    }

    // Remove event listener
    return () => {
      if (clickRef) {
        clickRef.removeEventListener('click', handleClick);
      }
    };
  });
};

export default useDoubleClick;
