import React from 'react';
import Icon, { IconProps } from './Icon';

const SocialLinkedIn: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 374.20708 94.14739">
      <path
        d="M433.56881,19.74158c-.01476-5.6189-2.25075-7.93091-7.81139-7.93929q-39.07214-.05778-78.14428-.00679c-6.14807.00818-8.23082,2.17533-8.22882,8.22922q.00957,32.90082.03112,65.80185c.0024,4.55458-.07462,9.111.03273,13.66316.09138,3.89492,2.43033,6.16742,6.31089,6.39449.71753.0421,1.43984.01437,2.15977.01457q38.89315.01017,77.78551.01975c5.60374.0014,7.84293-2.20346,7.84771-7.79q.01617-19.59654.0032-39.19326H433.58C433.58,45.87069,433.60393,32.80613,433.56881,19.74158ZM367.29447,92.48844H352.92915V47.02061h14.36532Zm-6.97175-51.219a8.9039,8.9039,0,0,1-8.79949-8.61033,8.74549,8.74549,0,0,1,8.69972-8.64426,8.634,8.634,0,0,1,.09977,17.25459ZM420.25584,92.4228H405.67941c-.0826-1.39894-.21709-2.67417-.22268-3.9498-.02953-6.47351-.01875-12.94723-.03911-19.42074a32.411,32.411,0,0,0-.14526-3.95059c-.49165-3.90291-2.45468-6.02576-5.73463-6.34641-4.312-.42182-7.19524,1.27842-8.28869,5.17474a20.75612,20.75612,0,0,0-.65687,5.31641c-.07582,6.59283-.01237,13.18687-.04469,19.7803-.01756,3.60021-.09578,3.65568-3.6036,3.67543-3.56809.02016-7.13617.00439-10.98.00439V46.98689h13.649c.14007,1.47755.27057,2.85933.40146,4.24091.21869-.03791.54234.00459.65208-.1287,5.55426-6.7347,15.32349-7.25289,22.32278-3.28514,5.23619,2.96828,6.8636,8.222,7.06593,13.76412.30648,8.38167.15843,16.77989.19914,25.17114C420.263,88.54024,420.25584,90.33147,420.25584,92.4228Z"
        transform="translate(-59.38088 -11.77115)"
      />
      <path
        d="M311.16967,51.68973a12.57224,12.57224,0,0,1-1.78384-.95737C300.90759,42.81561,285.47,44.32669,278.95,56.25568c-2.823,5.165-3.30829,10.638-3.092,16.34092a21.06884,21.06884,0,0,0,7.32213,15.44481c6.55672,5.89047,19.8234,7.57055,26.76642-.41105a15.63546,15.63546,0,0,1,1.91674-1.53681c.2111,2.13662.38031,3.84763.5559,5.62489,2.11228,0,4.00666.00918,5.90145-.0026,1.97539-.01217,3.95159-.04469,5.994-.069V25.96628h-13.145ZM300.37283,81.53238c-6.72991-.07982-11.62251-5.17574-11.56345-12.04513.06226-7.29839,4.7202-11.935,11.94257-11.8875,7.058.04629,11.391,4.57793,11.38547,11.90805C312.13183,76.8876,307.505,81.617,300.37283,81.53238Z"
        transform="translate(-59.38088 -11.77115)"
      />
      <path
        d="M226.517,47.27222c-4.91056,0-9.20575.108-13.49136-.04709a5.23007,5.23007,0,0,0-4.56336,2.1887c-4.09207,4.85409-8.28789,9.62178-12.47413,14.39645a23.17637,23.17637,0,0,1-2.45548,1.98038V25.89125h-12.872a13.74374,13.74374,0,0,0-.27935,1.76948q-.02274,30.54979-.0008,61.0994a18.73172,18.73172,0,0,0,.51,3.06526h12.7c.07223-7.365-.20872-14.56366.83884-22.09132.83326.2586.95657.89312,1.28461,1.394,4.06692,6.20953,8.22283,12.362,12.21473,18.61861A4.16688,4.16688,0,0,0,212.34,91.89c3.69938-.24763,7.42948-.022,11.14722-.01557.73668.0012,1.473-.093,2.54048-.16561-6.01837-8.151-11.755-15.9207-17.58382-23.8157C214.54366,60.93338,220.35053,54.30822,226.517,47.27222Z"
        transform="translate(-59.38088 -11.77115)"
      />
      <path
        d="M262.20578,49.841c-6.22868-4.4171-13.02245-4.77367-20.22207-2.79728-9.179,2.51993-15.82112,9.54735-16.92455,18.83451-1.16449,9.804,1.63738,18.199,10.53345,23.39787,8.679,5.07218,17.77457,5.32778,26.69738.41284,2.21005-1.21717,4.043-3.11654,6.37434-4.959-3.424-2.49917-6.28375-4.58611-9.21931-6.72852-4.51747,4.3377-9.30711,6.9518-15.36419,3.98931A9.64354,9.64354,0,0,1,238.26,73.63378h32.25124c0-3.12551.2139-5.64065-.0415-8.1073C269.81765,59.23434,267.58285,53.65415,262.20578,49.841Zm-23.5555,14.57823c-.22627-5.40241,3.82549-9.03794,9.71376-8.97429,5.54588.06006,9.00741,3.58445,8.67539,8.97429Z"
        transform="translate(-59.38088 -11.77115)"
      />
      <path
        d="M161.41029,46.82446c-7.09466-1.32591-13.69687-.62773-18.24946,6.01678-.08461.12331-.4322.06705-1.03679.14127-.21869-1.79322-.439-3.59881-.66764-5.47165H129.464V91.62645h13.01127V87.519c.0016-5.51075-.06145-11.02251.03512-16.53147a39.16735,39.16735,0,0,1,.688-6.77182,7.833,7.833,0,0,1,7.97342-6.44817c4.05495-.08321,6.56948,1.94666,7.47058,6.27458a29.97607,29.97607,0,0,1,.59941,5.68894c.079,5.98884.02793,11.97948.05347,17.96912.00479,1.17007.12771,2.33955.2147,3.82389h13.35647c-.33322-10.97163-.41543-21.73734-1.07788-32.46713C171.38744,52.56449,167.18643,47.904,161.41029,46.82446Z"
        transform="translate(-59.38088 -11.77115)"
      />
      <path
        d="M72.65953,25.96069H59.38088V91.68052H99.75239V79.05914H72.65953Z"
        transform="translate(-59.38088 -11.77115)"
      />
      <path
        d="M113.85393,47.2798c-2.09672.03532-4.19183.13549-6.21672.20473v44.1932h12.85245V47.272C118.12717,47.272,115.98975,47.24389,113.85393,47.2798Z"
        transform="translate(-59.38088 -11.77115)"
      />
      <path
        d="M114.091,24.91413a8.05089,8.05089,0,0,0-7.96823,7.5953c-.07781,4.12558,3.87138,7.91974,8.1542,7.83274a7.71349,7.71349,0,0,0,7.42031-7.67631A7.486,7.486,0,0,0,114.091,24.91413Z"
        transform="translate(-59.38088 -11.77115)"
      />
    </Icon>
  );
};

export default SocialLinkedIn;
