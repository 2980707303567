import React from 'react';
import Icon, { IconProps } from './Icon';

const SocialFacebook: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 370.35011 72.0166">
      <path
        d="M264.9822,88.43778v6.349c-.43871.04108-.78231.10071-1.126.10124q-5.42888.00832-10.85776-.00035c-.19545-.00012-.391-.0084-.58631-.01792a.51615.51615,0,0,1-.53788-.56409c-.00974-.2605-.01544-.52133-.01545-.782q-.001-33.398.00209-66.79607c0-.383.0408-.76591.06934-1.2683,4.57924-.47993,9.10946-.828,13.75979-1.23376.04258.53044.1081.968.10837,1.40558q.00893,13.78953.00457,27.57905v1.2411c.1446-.07957.25342-.101.28454-.1621a11.43731,11.43731,0,0,1,6.14979-5.32027c5.4347-2.27407,13.26533-1.608,18.16641,2.441a18.23,18.23,0,0,1,5.359,7.69806,30.44257,30.44257,0,0,1,1.94164,9.933,45.85036,45.85036,0,0,1-.80213,11.47609,24.21356,24.21356,0,0,1-2.58859,7.03724,15.41374,15.41374,0,0,1-9.51281,7.46951,20.80026,20.80026,0,0,1-10.63612.24774,13.44678,13.44678,0,0,1-8.52992-6.08479q-.18195-.28936-.37157-.57383A1.90054,1.90054,0,0,0,264.9822,88.43778Zm18.78511-16.47086c0-.97678.055-1.9573-.0112-2.92957a15.75709,15.75709,0,0,0-1.53867-6.21551,6.94506,6.94506,0,0,0-5.34766-3.97345,12.62515,12.62515,0,0,0-3.10939-.12475,8.04922,8.04922,0,0,0-6.97677,5.07251,12.42632,12.42632,0,0,0-.97014,4.85407q-.04416,3.1749-.00618,6.35066a14.46753,14.46753,0,0,0,.19908,2.52533,8.40537,8.40537,0,0,0,5.175,6.73387,10.16546,10.16546,0,0,0,5.86107.45,7.26151,7.26151,0,0,0,5.50209-4.513,16.29122,16.29122,0,0,0,.817-2.50157A20.37284,20.37284,0,0,0,283.76731,71.96692Z"
        transform="translate(-76.86571 -24.01145)"
      />
      <path
        d="M447.21581,94.88873h-1.23156c-4.14147,0-8.28311-.01893-12.42427.01609a1.78184,1.78184,0,0,1-1.726-.95981q-6.48374-10.30748-13.0318-20.57448a1.34717,1.34717,0,0,0-.5763-.51306c-.01482.36585-.04241.7317-.04255,1.09755q-.004,9.73212-.00578,19.46423c-.00039.4457-.05433.89135-.08744,1.39977H404.23792c-.03854-.44256-.10438-.85015-.10493-1.25782q-.01046-7.87371-.00473-15.74743V25.47545c4.65815-.51119,9.22094-.83149,13.84433-1.24785a5.77656,5.77656,0,0,1,.1931.80187c.03146.35606.01538.71655.01538,1.07515q.00069,21.37154.00039,42.743v1.245l.21565.07977c.18971-.27773.38946-.5492.56766-.83413q6.19765-9.90955,12.37415-19.83225a1.83866,1.83866,0,0,1,1.80587-.99429c4.07585.04035,8.15229.01686,12.22854.02028.35038.00029.7007.03151,1.2443.05767-.22407.43193-.35826.74162-.53548,1.02439q-3.09035,4.93131-6.19191,9.8556c-2.35982,3.75384-4.705,7.51706-7.09357,11.2525a1.32538,1.32538,0,0,0,.01495,1.6643q5.66271,8.74309,11.28168,17.51451c.82911,1.28868,1.66509,2.57293,2.49038,3.864C446.76938,94.05561,446.92349,94.36633,447.21581,94.88873Z"
        transform="translate(-76.86571 -24.01145)"
      />
      <path
        d="M145.048,55.00039q0-.47345,0-.94689c.0009-1.53252-.01051-3.06519.008-4.5975.00936-.774.11145-.90526.82276-.90767,3.94607-.01339,7.89221-.00387,11.83832.004a1.72242,1.72242,0,0,1,.40139.08285,1.34814,1.34814,0,0,1,.13063.11541.22575.22575,0,0,1,.04443.08651,1.87616,1.87616,0,0,1,.07071.38066q.00844,22.30307.00914,44.60614a5.6014,5.6014,0,0,1-.03145.58517,1.4735,1.4735,0,0,1-.07975.27106,105.59712,105.59712,0,0,1-13.12271.04087c-.22952-1.97019.01565-3.988-.28532-6.07809-.18936.27121-.39065.535-.56618.81484a12.93883,12.93883,0,0,1-5.33986,4.88918,15.20311,15.20311,0,0,1-5.8529,1.44046,20.31488,20.31488,0,0,1-9.46967-1.54578,16.12271,16.12271,0,0,1-8.515-9.13319,26.553,26.553,0,0,1-1.97036-8.343,75.43472,75.43472,0,0,1-.18544-8.30068,26.53431,26.53431,0,0,1,3.39086-12.52752A15.96221,15.96221,0,0,1,128.64048,47.883a20.32947,20.32947,0,0,1,6.71942.07805,13.1679,13.1679,0,0,1,8.96469,6.2323c.16964.27542.33259.555.49864.83257Zm-.5513,17.11427-.09317-.00409c0-1.20573.02968-2.41238-.00707-3.617a13.39756,13.39756,0,0,0-1.01954-5.04591,7.54852,7.54852,0,0,0-5.10755-4.47819,10.315,10.315,0,0,0-4.3632-.17123,7.17221,7.17221,0,0,0-5.76458,4.58674,15.78955,15.78955,0,0,0-.89306,2.88725,33.77978,33.77978,0,0,0-.33542,9.34689,12.94858,12.94858,0,0,0,1.12352,4.53406,7.42206,7.42206,0,0,0,5.68464,4.58262,12.33172,12.33172,0,0,0,2.33305.15729,7.8333,7.8333,0,0,0,6.77651-3.78867,8.3778,8.3778,0,0,0,1.379-3.729C144.38165,75.62932,144.4078,73.8689,144.49666,72.11466Z"
        transform="translate(-76.86571 -24.01145)"
      />
      <path
        d="M325.42733,47.67868a27.302,27.302,0,0,1,7.95436.96977A18.73341,18.73341,0,0,1,346.973,61.74957a33.90318,33.90318,0,0,1,1.27616,12.959,30.48046,30.48046,0,0,1-1.41924,7.47212,18.51964,18.51964,0,0,1-13.58569,12.70622,30.45418,30.45418,0,0,1-10.28013.84,28.97225,28.97225,0,0,1-6.61615-1.22943,18.37077,18.37077,0,0,1-12.40982-12.54483,30.32005,30.32005,0,0,1-1.41145-8.55659,36.46312,36.46312,0,0,1,1.00916-10.76517,20.26806,20.26806,0,0,1,4.6808-8.69724,18.95975,18.95975,0,0,1,9.01147-5.25146A28.25577,28.25577,0,0,1,325.42733,47.67868Zm9.18554,24.10873-.12407-.00669c0-1.1087.05746-2.22126-.01669-3.325a17.19421,17.19421,0,0,0-.44259-3.0867A8.17365,8.17365,0,0,0,326.6,58.75585c-3.72944-.41933-7.49837.99525-9.2514,5.18136a10.73259,10.73259,0,0,0-.87667,3.99017c-.03959,2.54272-.07177,5.08859.01608,7.6289a14.32982,14.32982,0,0,0,.60033,3.354,7.85581,7.85581,0,0,0,2.71585,4.20807c2.92313,2.25235,6.13231,2.30054,9.44272,1.03933a7.20732,7.20732,0,0,0,3.22968-2.61173,9.185,9.185,0,0,0,1.75638-4.3061C334.45474,75.43406,334.49423,73.60587,334.61287,71.78741Z"
        transform="translate(-76.86571 -24.01145)"
      />
      <path
        d="M216.54166,76.65609c.45372,3.221,1.11151,6.07269,4.01123,7.76665a10.5274,10.5274,0,0,0,4.09553,1.229,29.73329,29.73329,0,0,0,10.20834-.52867c2.0957-.46891,4.15059-1.12055,6.2226-1.69447.34-.09416.67394-.21048,1.10492-.34624.14021.35462.28213.63824.36621.93807q1.05623,3.76717,2.09136,7.54021c.24521.89667.21766.96221-.62189,1.34379a32.274,32.274,0,0,1-8.05477,2.34746,54.47023,54.47023,0,0,1-10.52012.75755,32.375,32.375,0,0,1-10.37228-1.74406,17.12146,17.12146,0,0,1-9.65785-8.25654,23.57747,23.57747,0,0,1-2.46116-8.30057,55.92481,55.92481,0,0,1-.17369-10.74018,24.485,24.485,0,0,1,1.71374-7.503,17.17639,17.17639,0,0,1,10.71-10.18643,29.65071,29.65071,0,0,1,18.00836-.4821c6.244,1.75675,10.09989,5.95845,11.80793,12.17512a28.76393,28.76393,0,0,1,.97327,7.15251c.04224,2.44505.01554,4.89134.01193,7.3371-.00173,1.17411-.01926,1.19284-1.21465,1.1932q-13.40549.004-26.811.00165Zm16.97215-9.21366a3.41086,3.41086,0,0,0,.118-.59084,13.33273,13.33273,0,0,0-1.02479-5.42686,6.81069,6.81069,0,0,0-5.26175-4.06024,11.19623,11.19623,0,0,0-3.69564-.02215A7.22848,7.22848,0,0,0,217.875,61.4872a12.95631,12.95631,0,0,0-1.21357,4.69857c-.09936,1.25122-.03167,1.3064,1.25881,1.30713q7.228.00411,14.456-.00092C232.72983,67.49175,233.08341,67.462,233.51381,67.44243Z"
        transform="translate(-76.86571 -24.01145)"
      />
      <path
        d="M398.89265,71.59741a31.71578,31.71578,0,0,1-1.389,10.22772c-2.32117,7.254-7.2482,11.68264-14.6662,13.28815a30.23192,30.23192,0,0,1-16.5891-.84732,18.03839,18.03839,0,0,1-11.37067-11.21911,30.59907,30.59907,0,0,1-1.81448-9.66421,36.5521,36.5521,0,0,1,.777-9.7184,21.593,21.593,0,0,1,3.5742-8.08235,17.2766,17.2766,0,0,1,8.34529-6.22505,29.699,29.699,0,0,1,19.86363-.15163c6.06879,2.08143,9.932,6.40133,11.88417,12.46487A30.37541,30.37541,0,0,1,398.89265,71.59741Zm-13.77471.41145c0-1.10956.02456-2.02228-.00528-2.93324a14.45807,14.45807,0,0,0-.95513-5.06,7.8136,7.8136,0,0,0-5.70176-5.011,11.29539,11.29539,0,0,0-3.38774-.26722,8.03033,8.03033,0,0,0-7.20437,5.31775,11.8677,11.8677,0,0,0-.87928,4.28394c-.03829,2.2791-.03466,4.55965-.00165,6.83891a12.61125,12.61125,0,0,0,.80847,4.20294,7.82133,7.82133,0,0,0,4.046,4.68524,9.27317,9.27317,0,0,0,8.65211-.25965,8.77626,8.77626,0,0,0,4.245-6.16172A23.46632,23.46632,0,0,0,385.11794,72.00886Z"
        transform="translate(-76.86571 -24.01145)"
      />
      <path
        d="M76.93377,48.64425c.43455-.04168.81247-.10769,1.19067-.10933,2.2829-.00994,4.56587-.00454,6.84881-.00615.26074-.00018.52187-.00522.78211-.02025a.52247.52247,0,0,0,.5452-.56082c.00948-.26057.01583-.52141.01611-.78213.00254-2.3803-.07558-4.76416.031-7.13973a29.38352,29.38352,0,0,1,.638-5.22733c1.17047-5.107,4.44673-8.27674,9.41671-9.75475a27.1083,27.1083,0,0,1,6.65538-1.00855,56.27081,56.27081,0,0,1,7.90341.4374c1.10338.11027,1.1518.18126,1.15427,1.2512q.0096,4.15736-.00206,8.31474c-.001.34747-.06037.69477-.09964,1.11681-.49517.02181-.91048.045-1.32611.05759q-2.88131.08748-5.7627.17233a3.165,3.165,0,0,0-.48446.06414c-2.66271.50242-3.9807,2.00395-4.03545,4.71987-.04862,2.412-.023,4.82556-.01825,7.23842.00209,1.06141.05421,1.11564,1.10047,1.11847,3.033.00824,6.06611.00066,9.09915.00721.38079.00083.76148.04928,1.16725.0774a2.96534,2.96534,0,0,1,.06986.54883q-.42788,5.01629-.8723,10.03116c-.0654.72961-.17638.81858-1.03454.82265-2.446.01163-4.892.00424-7.338.0062-.52171.00042-1.04346.00662-1.56513.01413a.5486.5486,0,0,0-.61492.59767c-.00668.35853-.01449.71712-.01452,1.07569q-.00117,15.79791,0,31.59581c.00022,1.74257.03117,1.58252-1.60759,1.58447q-5.47907.00655-10.95814.00084c-.29344-.00006-.58688-.00835-.88033-.0112a.54213.54213,0,0,1-.60732-.60042c-.00523-.326-.01264-.652-.01266-.978q-.001-15.847-.00166-31.69392c-.00034-1.65865.12532-1.58493-1.60506-1.58556-2.21772-.0008-4.43545.00411-6.65315-.00241-1.1417-.00337-1.18634-.04364-1.18855-1.1285q-.00941-4.59755.00039-9.19514C76.87043,49.37305,76.90787,49.053,76.93377,48.64425Z"
        transform="translate(-76.86571 -24.01145)"
      />
      <path
        d="M197.65834,82.94623c.153.39732.28055.69026.37975.99253q1.28106,3.90322,2.552,7.80975c.32606,1.00356.29829,1.07751-.66975,1.602a19.69583,19.69583,0,0,1-6.50672,2.0268,37.25886,37.25886,0,0,1-14.47205-.5036,18.43347,18.43347,0,0,1-14.52995-15.15729,29.54619,29.54619,0,0,1-.64318-6.01847,51.14138,51.14138,0,0,1,.41126-8.97421,19.842,19.842,0,0,1,5.50593-11.22149,19.39952,19.39952,0,0,1,9.04311-4.82578,36.87565,36.87565,0,0,1,12.14015-.83333,26.01859,26.01859,0,0,1,8.1626,1.87129c.64859.27758,1.26052.64082,1.96939,1.00594-1.08778,3.38988-2.1391,6.66612-3.21333,10.01378-.33574-.11278-.58135-.17359-.80888-.275a19.86167,19.86167,0,0,0-9.8135-1.76979c-3.50623.28524-6.42786,1.64891-8.21087,4.86675a9.3518,9.3518,0,0,0-1.23863,4.385c-.04471,2.50964-.12469,5.02689.0131,7.52977a9.69919,9.69919,0,0,0,8.47093,9.43749,18.50164,18.50164,0,0,0,9.03764-1.04583C196.00524,83.59849,196.75741,83.28878,197.65834,82.94623Z"
        transform="translate(-76.86571 -24.01145)"
      />
    </Icon>
  );
};

export default SocialFacebook;
