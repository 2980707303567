import React, { useContext } from 'react';
import { RoleCode } from 'shared/generated/graphql';

export interface CurrentUserRaw {
  id: string;
  roleCode: string;
}

export interface CurrentUser {
  id: string;
  roleCode: string;
  isAdmin: () => boolean;
}

type UserContextState = {
  user: CurrentUserRaw | null;
  setUser: (user: CurrentUserRaw | null) => void;
};

export const UserContext = React.createContext<UserContextState>({
  user: null,
  setUser: _user => null,
});

export const useCurrentUser = () => {
  const { user: userRaw, setUser } = useContext(UserContext);
  let user: CurrentUser | null = null;

  if (userRaw) {
    user = {
      id: userRaw.id,
      roleCode: userRaw.roleCode,
      isAdmin: function() {
        return this.roleCode === 'admin';
      },
    };
  }
  return {
    user,
    setUser,
  };
};

export default UserContext;
