import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import onDragEnd from 'Projects/Edit/onDragEnd';
import List from './List';
import Card from './Card';
import {
  useProjectsQuery,
  useCreateProjectMutation,
  Project,
  ProjectsDocument,
  ProjectsQuery,
  useSetProjectLocationMutation,
  ProjectPhase,
  useDeleteProjectMutation,
  useSetProjectNameMutation,
} from 'shared/generated/graphql';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import { Cross } from 'shared/icons';
import Button from 'shared/components/Button';
import { useCurrentUser } from 'App/context';
import Navbar from 'App/Navbar';
import { getAfterDropDraggableList, getNewDraggablePosition, getSortedDraggables } from 'shared/utils/draggables';

const UsersButton = styled.button`
  padding: 6px 12px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
`;

const ActionButtons = styled.div`
  position: absolute;
  left: 42px;
  top: 24px;
  display: flex;
  align-items: center;
`;

function getProjectPhase(phase: string) {
  switch (phase) {
    case 'do':
      return ProjectPhase.Do;
    case 'act':
      return ProjectPhase.Act;
    case 'plan':
      return ProjectPhase.Plan;
    default:
      return ProjectPhase.Tidy;
  }
}

const DashboardTitle = styled.h3`
  text-align: center;
  margin-bottom: 8px;
`;

export const Projects = styled.div<{ isAdmin: boolean }>`
  padding: 24px 12px;
  padding-bottom: 0;
  background: #f4f4f4;
  ${props =>
    props.isAdmin
      ? css`
          border-top-left-radius: 28px;
          border-top-right-radius: 28px;
        `
      : css`
          border-radius: 28px;
        `}
  flex: 1 1 auto;
  min-height: 74px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ProjectGroups = styled.div`
  display: flex;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const NewProjectContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 42px;
`;

const NewProjectInnerContainer = styled.div`
  position: relative;
`;
const NewProject = styled.button`
  padding: 6px 12px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
`;

const NewProjectModal = styled.div`
  position: absolute;
  min-width: 272px;
  bottom: -4px;
  right: 0;
  transform: translateY(100%);
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  padding: 4px 6px;
  justify-content: center;
`;

const ModalTitle = styled.h3`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin: 6px 0;
`;

const ModalName = styled.input`
  background: none;
  outline: none;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 4px 6px;
  margin: 6px 0;

  &:focus,
  &:active {
    border: 1px solid #000;
  }
`;

const ModalButton = styled.button`
  background: #000;
  color: #fff;
  padding: 6px 12px;
`;

const StatusTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  padding: 4px 0;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
`;

const ProjectList = styled.div`
  display: flex;
`;

const ProjectStatus = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 172px;
`;

const ProjectLink = styled(Link)`
  font-size: 18px;
`;

const ClientMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #000;
  a {
    text-decoration: underline;
  }
`;

const Dashboard: React.FC = () => {
  const { data } = useProjectsQuery({ pollInterval: process.env.NODE_ENV === 'production' ? 5000 : 0 });
  const history = useHistory();
  const { user } = useCurrentUser();
  const [openCards, setOpenCards] = useState<Array<string>>([]);

  const [setProjectName] = useSetProjectNameMutation();
  const [deleteProject] = useDeleteProjectMutation({
    update: (client, r) =>
      updateApolloCache<ProjectsQuery>(client, ProjectsDocument, cache =>
        produce(cache, draftCache => {
          draftCache.projects = cache.projects.filter(p => p.id !== r.data.deleteProject.projectID);
        }),
      ),
  });
  const [setProjectLocation] = useSetProjectLocationMutation();
  const [createProject] = useCreateProjectMutation({
    update: (client, r) =>
      updateApolloCache<ProjectsQuery>(client, ProjectsDocument, cache =>
        produce(cache, draftCache => {
          console.log(r.data);
          draftCache.projects.push({ ...r.data.createProject.project });
        }),
      ),
  });

  if (data && user) {
    const projects = new Map<string, Array<Pick<Project, 'position' | 'id' | 'status' | 'name' | 'createdAt'>>>();
    projects.set('plan', []);
    projects.set('do', []);
    projects.set('tidy', []);
    projects.set('act', []);
    data.projects.forEach(p => {
      projects.set(p.status, [p, ...projects.get(p.status)]);
    });
    const titles = ['plan', 'do', 'check', 'act'];
    const render = [
      [...projects.get('plan')],
      [...projects.get('do')],
      [...projects.get('tidy')],
      [...projects.get('act')],
    ];
    return (
      <>
        <Navbar title="Projects Overview" />
        <Container>
          <InnerContainer>
            <DragDropContext
              onDragEnd={({ draggableId, source, destination, type }) => {
                console.log(draggableId, source, destination, type);
                if (!destination) return false;
                const isSameList = destination.droppableId === source.droppableId;

                const curProjects = projects.get(source.droppableId) ?? [];
                let targetProjects = curProjects;
                if (!isSameList) {
                  targetProjects = projects.get(destination.droppableId) ?? [];
                }
                const droppedProject = data.projects.find(p => p.id === draggableId);

                if (droppedProject) {
                  const droppedDraggable = {
                    id: draggableId,
                    position: droppedProject.position,
                  };
                  const beforeDropDraggables = getSortedDraggables(
                    targetProjects.map((task: any) => {
                      return { id: task.id, position: task.position };
                    }),
                  );
                  if (droppedDraggable === null || beforeDropDraggables === null) {
                    throw new Error('before drop draggables is null');
                  }
                  const afterDropDraggables = getAfterDropDraggableList(
                    beforeDropDraggables,
                    droppedDraggable,
                    false,
                    isSameList,
                    destination,
                  );
                  const newPosition = getNewDraggablePosition(afterDropDraggables, destination.index);
                  console.log('newPos', draggableId, newPosition);
                  setProjectLocation({
                    variables: {
                      position: newPosition,
                      projectID: draggableId,
                      phase: getProjectPhase(destination.droppableId),
                    },
                    optimisticResponse: {
                      __typename: 'Mutation',
                      setProjectLocation: {
                        __typename: 'SetProjectPhasePayload',
                        project: {
                          __typename: 'Project',
                          position: newPosition,
                          id: draggableId,
                          status: getProjectPhase(destination.droppableId),
                        },
                      },
                    },
                  });
                }
              }}
            >
              <ProjectGroups>
                {titles.map((status, index) => {
                  return (
                    <Droppable key={status} type="tasks" droppableId={status}>
                      {(columnDropProvided, snapshot) => (
                        <List
                          editable={user.isAdmin()}
                          key={status}
                          onDelete={() => {
                            // on delete
                          }}
                          onCreateProject={name => {
                            createProject({ variables: { name, phase: getProjectPhase(status) } });
                          }}
                          name={status.toUpperCase()}
                        >
                          <Projects
                            isAdmin={user.isAdmin()}
                            ref={columnDropProvided.innerRef}
                            {...columnDropProvided.droppableProps}
                          >
                            {render[index]
                              .slice()
                              .sort((a, b) => a.position - b.position)
                              .map((project, projectIndex) => (
                                <Draggable
                                  isDragDisabled={openCards.findIndex(c => c === project.id) !== -1}
                                  key={project.id}
                                  draggableId={project.id}
                                  index={projectIndex}
                                >
                                  {taskProvided => (
                                    <Card
                                      onOpenToggle={isOpen => {
                                        if (isOpen) {
                                          setOpenCards(cards => [...cards, project.id]);
                                        } else {
                                          setOpenCards(cards => cards.filter(c => c !== project.id));
                                        }
                                      }}
                                      innerRef={taskProvided.innerRef}
                                      handleProps={taskProvided.dragHandleProps}
                                      wrapperProps={taskProvided.draggableProps}
                                      actions={{
                                        onOpen: () => {
                                          history.push(`/projects/${project.id}`);
                                        },
                                        onNameChange: name => {
                                          setProjectName({ variables: { projectID: project.id, name } });
                                        },
                                        onDelete: () => {
                                          deleteProject({ variables: { projectID: project.id } });
                                        },
                                      }}
                                      key={project.id}
                                      name={project.name}
                                    />
                                  )}
                                </Draggable>
                              ))}
                            {columnDropProvided.placeholder}
                          </Projects>
                        </List>
                      )}
                    </Droppable>
                  );
                })}
              </ProjectGroups>
            </DragDropContext>
          </InnerContainer>
        </Container>
      </>
    );
  }
  return <Navbar />;
};

export default Dashboard;
