import React, { useState, useRef, useEffect, ReactText } from 'react';
import {
  useAttachmentsQuery,
  useRequestZipMutation,
  useRequestZipStatusLazyQuery,
  useDeleteAttachmentCategoryMutation,
  AttachmentsQuery,
  AttachmentsDocument,
  useDeleteAttachmentMutation,
  useTagsQuery,
  useToggleAttachmentTagMutation,
  TagsQuery,
  TagsDocument,
} from 'shared/generated/graphql';
import styled, { css } from 'styled-components';
import Viewer from 'react-viewer';
import { FileAlt, Download, Upload as UploadIcon, Plus } from 'shared/icons';
import useDoubleClick from 'shared/utils/useDoubleClick';
import { useHistory } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import { usePopup, Popup } from 'shared/components/PopupMenu';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import Checkmark from 'shared/icons/Checkmark';

const Actions = styled.div`
  display: flex;
  align-items: center;
`;
const TagLabel = styled.span`
  font-size: 14px;
  color: #000;
`;

const Tag = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  padding: 4px 6px;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
  ${props =>
    props.active &&
    css`
      background: rgb(${props => props.theme.colors.primary});
      &:hover {
        background: rgb(${props => props.theme.colors.primary});
      }
      ${TagLabel} {
        color: #fff;
      }
    `}
  svg {
    fill: #fff;
    stroke: #fff;
  }
`;
const Tags = styled.div`
  display: flex;
  flex-direction: column;
`;

type TagManagerProps = {
  projectID: string;
  attachment: string;
  tags: Array<{ id: string; name: string }>;
};

const TagManager: React.FC<TagManagerProps> = ({ projectID, attachment, tags: initTags }) => {
  const { data } = useTagsQuery({ variables: { projectID } });
  const [tags, setTags] = useState(initTags);
  const [toggleTag] = useToggleAttachmentTagMutation({
    update: (client, r) => {
      console.log(r);
      updateApolloCache<AttachmentsQuery>(
        client,
        AttachmentsDocument,
        cache =>
          produce(cache, draftCache => {
            const idx = cache.attachments.attachments.findIndex(a => a.id === attachment);
            if (idx !== -1) {
              draftCache.attachments.attachments[idx].tags = r.data.toggleAttachmentTag.attachment.tags;
            }
            setTags(r.data.toggleAttachmentTag.attachment.tags);
          }),
        { projectID },
      );
    },
  });
  if (data) {
    return (
      <Tags>
        {data.tags.tags.map(tag => (
          <Tag
            key={tag.id}
            onClick={() => {
              toggleTag({ variables: { tag: tag.id, attachment } });
            }}
            active={tags.findIndex(t => t.id === tag.id) !== -1}
          >
            <TagLabel>{tag.name}</TagLabel>
            {tags.find(t => t.id === tag.id) && <Checkmark width={16} height={16} />}
          </Tag>
        ))}
      </Tags>
    );
  }
  return null;
};

const ConfirmButton = styled(Button)`
  padding: 8px 12px;
  width: 100%;
`;
const WarningLabel = styled.p`
  font-size: 14px;
  color: #000;
  padding: 8px 0;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  &:hover {
    background: rgba(${props => props.theme.colors.primary});
  }
`;

const EmptyWarning = styled.span`
  font-size: 24px;
  color: #000;
  font-weight: 200;
`;

const Searchbar = styled.input`
  background: none;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 4px 6px;
  outline: none;
`;

const DownloadButton = styled.button<{ disabled: boolean }>`
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #000;
    stroke: #000;
  }
  ${props =>
    props.disabled &&
    css`
      svg {
        fill: #ccc;
        stroke: #ccc;
      }
      cursor: default;
      pointer-events: none;
    `}
`;

export type Attachment = {
  id: string;
  filename: string;
  filepath: string;
  tags: Array<{ id: string; name: string }>;
  size: number;
};
export type Category = {
  name: string;
  attachments: Array<Attachment>;
};

type UploadsBrowserProps = {
  projectID: string;
  categories: Map<string, Category>;
  onNewCategoryClick: ($target: React.RefObject<HTMLElement>) => void;
  onUploadClick: () => void;
  findAttachment: null | { attachment: string; category: string };
};

const FileBrowser = styled.div`
  width: 100%;
  color: #000;
  border: solid 1px #e4e4e4;
  height: 100%;
  display: flex;
  font-size: 15px;
  box-sizing: border-box;
  text-align: left;
  font-family: sans-serif;
  user-select: none;
  touch-action: manipulation;
  border-radius: 4px;
  flex-direction: column;

  padding: 12px 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #00000026;
  border-radius: 18px;
`;

const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FileEntryWrapper = styled.div`
  height: 138px;
  width: 173px;
  padding-right: 8px;
  padding-bottom: 8px;

  display: flex;
  flex-direction: column;
`;

const FilePreview = styled.div<{ background?: boolean }>`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  ${props =>
    props.background &&
    css`
      box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 0px 999px inset;
      background-color: #f5f5f5;
      border: 1px solid #dadce0;
    `}
`;

const FileBackSideMid = styled.div<{ isSelected: boolean }>`
  background-color: rgb(187, 187, 187);
  ${props =>
    !props.isSelected &&
    css`
      box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 999px inset;
    `}
  top: 10px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  border-top-right-radius: 5px;
`;

const FileBackSideTop = styled.div<{ isSelected: boolean }>`
  background-color: rgb(187, 187, 187);
  ${props =>
    !props.isSelected &&
    css`
      box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 999px inset;
    `}

  top: -10px;
  left: 0px;
  right: 60%;
  height: 13px;
  position: absolute;
  border-top-left-radius: 5px;
  border-top-right-radius: 10px;

  &:after {
    top: 0px;
    right: 0px;
    content: '';
    display: block;
    position: absolute;
    border-color: rgb(255, 255, 255) rgb(255, 255, 255) transparent transparent;
    border-style: solid;
    border-width: 0px 15px 10px 0px;
  }
`;
const FileFrontSide = styled.div<{ isSelected: boolean }>`
  ${props =>
    !props.isSelected &&
    css`
      box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 999px inset;
    `}
  background-color: rgb(187, 187, 187);

  top: 10px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  overflow: hidden;
  border-radius: 5px;
`;
const FileName = styled.div`
  font-size: 14px;
  text-align: center;
  word-break: break-word;
  padding-top: 5px;
`;

type FolderIconProps = {
  isSelected: boolean;
};

const FolderIcon: React.FC<FolderIconProps> = ({ isSelected }) => {
  return (
    <FileBackSideMid isSelected={isSelected}>
      <FileBackSideTop isSelected={isSelected} />
      <FileFrontSide isSelected={isSelected}>{isSelected && <SelectionIndicator />}</FileFrontSide>
    </FileBackSideMid>
  );
};

const Thumbnail = styled.div<{ url: string }>`
  background-image: url(${props => props.url});
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 6;
  position: absolute;
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

type FileIconProps = {
  url?: string;
  isSelected: boolean;
};

const FileIconAlt = styled(FileAlt)`
  top: 50%;
  left: 50%;
  z-index: 12;
  position: absolute;
  font-size: 2.4em;
  transform: translateX(-50%) translateY(-50%);
  fill: #fff;
  stroke: #fff;
`;

const FileIcon: React.FC<FileIconProps> = ({ url, isSelected }) => {
  return (
    <>
      {isSelected && <SelectionIndicator />}
      {url ? <Thumbnail url={url} /> : <FileIconAlt width={27} height={36} />}
    </>
  );
};

const Navbar = styled.div`
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

const Breadcrumbs = styled.ol`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
`;
const Breadcrumb = styled.li``;
const BreadcrumbSeperator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
`;

const BreadcrumbButton = styled.button<{ disabled: boolean }>`
  color: #000;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  height: 30px;
  background-color: transparent;
  justify-content: center;
  cursor: pointer;
  ${props =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}
`;
const SelectionIndicator = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  border: 3px solid #000;
  background: repeating-linear-gradient(
      45deg,
      rgba(0, 153, 255, 0.14),
      rgba(0, 153, 255, 0.14) 10px,
      rgba(0, 153, 255, 0.25) 0px,
      rgba(0, 153, 255, 0.25) 20px
    )
    rgba(0, 153, 255, 0.14);
`;

const Actionbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const RightActions = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedTotal = styled.div`
  font-size: 12px;
  color: #000;
  margin-left: 12px;
`;

type File = {
  id: string;
  name: string;
  tags: Array<{ id: string; name: string }>;
  isDir: boolean;
  filepath?: string;
};

type FileEntryProps = {
  file: File;
  isSelected: boolean;
  onSelect: () => void;
  onOpen: () => void;
  onContextMenu: (x: number, y: number) => void;
};
const FileEntry: React.FC<FileEntryProps> = ({ file, isSelected, onSelect, onOpen, onContextMenu }) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  useDoubleClick({
    ref: $wrapper,
    onDoubleClick: () => {
      onOpen();
    },
    onSingleClick: () => {
      onSelect();
    },
    latency: 200,
  });
  return (
    <FileEntryWrapper
      onContextMenu={e => {
        e.preventDefault();
        onContextMenu(e.pageX, e.pageY);
      }}
      ref={$wrapper}
    >
      <FilePreview background={!file.isDir}>
        {file.isDir ? (
          <FolderIcon isSelected={isSelected} />
        ) : (
          <FileIcon url={file.filepath ?? undefined} isSelected={isSelected} />
        )}
      </FilePreview>
      <FileName>{file.name}</FileName>
    </FileEntryWrapper>
  );
};

const NewCategoryButtonText = styled.span`
  padding-left: 4px;
`;

const NewCategoryButton = styled(Button)`
  align-items: center;
  padding: 4px 8px;
  margin-right: 8px;
  svg {
    margin-top: -4px;
    fill: rgba(255, 163, 59);
  }
`;

const UploadButtonText = styled.span`
  padding-left: 4px;
`;

const UploadButton = styled(Button)`
  align-items: center;
  padding: 4px 8px;
  svg {
    margin-top: -4px;
    fill: #fff;
  }
`;

type Files = {
  name: string;
  files: Array<File>;
};

const UploadsBrowser: React.FC<UploadsBrowserProps> = ({
  categories,
  projectID,
  onUploadClick,
  findAttachment,
  onNewCategoryClick,
}) => {
  const [requestKey, setRequestKey] = useState<string>('');
  const history = useHistory();
  console.log(categories.size);
  const [getZipStatus, { data, loading, stopPolling }] = useRequestZipStatusLazyQuery({
    variables: { requestKey },
    pollInterval: 1500,
  });
  const [requestingZip, setRequestingZip] = useState(false);
  const toastID = useRef<ReactText | null>(null);

  const [requestZip] = useRequestZipMutation({
    onCompleted: r => {
      setRequestKey(r.requestZip.requestKey);
      getZipStatus();
      // startPolling(200);
    },
  });

  useEffect(() => {
    if (data && data.requestZipStatus.complete) {
      stopPolling();
      if (data.requestZipStatus.filepath) {
        console.log(data.requestZipStatus.filepath);
        if (toastID && toastID.current !== null) {
          toast.dismiss(toastID.current ?? '');
        }
        window.open('/' + data.requestZipStatus.filepath + '?download=true', '_self');
        setRequestingZip(false);
      }
    }
  }, [data]);

  const files = new Map<string | null, Files>();
  files.set(null, {
    name: 'Home',
    files: [],
  });
  categories.forEach((category, id) => {
    const prev = files.get(null) ?? { files: [] };
    files.set(null, {
      name: 'Home',
      files: [
        {
          isDir: true,
          name: category.name,
          id,
          tags: [],
        },
        ...prev.files,
      ],
    });
    files.set(id, {
      name: category.name,
      files: category.attachments.map(attach => ({
        isDir: false,
        name: attach.filename,
        filepath: attach.filepath,
        tags: attach.tags,
        id: attach.id,
      })),
    });
  });
  const [previewFile, setPreviewFile] = useState<{ open: boolean; index: number; src: Array<string> }>({
    open: false,
    index: 0,
    src: [],
  });
  const { showPopup, hidePopup, setTab } = usePopup();
  const [curFolder, setCurFolder] = useState<null | string>(null);
  const [selectedFolders, setSelectedFolders] = useState<Array<{ id: string; type: 'folder' | 'file' }>>([]);
  const [deleteAttachment] = useDeleteAttachmentMutation({
    update: (client, r) =>
      updateApolloCache<AttachmentsQuery>(
        client,
        AttachmentsDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.attachments.attachments = cache.attachments.attachments.filter(
              a => a.id !== r.data.deleteAttachment.id,
            );
          }),
        {
          projectID,
        },
      ),
  });
  const [deleteAttachmentCategory] = useDeleteAttachmentCategoryMutation({
    update: (client, r) =>
      updateApolloCache<AttachmentsQuery>(
        client,
        AttachmentsDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.categories.categories = cache.categories.categories.filter(
              a => a.id !== r.data.deleteAttachmentCategory.id,
            );
            draftCache.attachments.attachments = cache.attachments.attachments.filter(
              a => a.category.id !== r.data.deleteAttachmentCategory.id,
            );
          }),
        {
          projectID,
        },
      ),
  });
  useEffect(() => {
    if (findAttachment) {
      setCurFolder(findAttachment.category);
      setSelectedFolders([{ id: findAttachment.attachment, type: 'file' }]);
    }
  }, [findAttachment]);
  const curFiles = files.get(curFolder);
  console.log('bee', curFiles ? curFiles.files?.length : 0, categories.size);
  return (
    <FileBrowser>
      <Navbar>
        <Breadcrumbs>
          <Breadcrumb>
            <BreadcrumbButton
              disabled={curFolder === null}
              onClick={() => {
                setSelectedFolders([]);
                setCurFolder(null);
              }}
            >
              Home
            </BreadcrumbButton>
          </Breadcrumb>
          {curFolder !== null && curFiles && (
            <>
              <BreadcrumbSeperator>/</BreadcrumbSeperator>
              <Breadcrumb>
                <BreadcrumbButton disabled={false}>{curFiles.name}</BreadcrumbButton>
              </Breadcrumb>
            </>
          )}
          {selectedFolders.length !== 0 && (
            <>
              <SelectedTotal>
                {selectedFolders.length} file{selectedFolders.length > 1 ? 's' : ''} selected
              </SelectedTotal>
              <DownloadButton
                disabled={requestingZip}
                onClick={() => {
                  if (!selectedFolders.find(s => s.type === 'folder')) {
                    setRequestingZip(true);
                    toastID.current = toast('Preparing archive...', {
                      autoClose: false,
                      hideProgressBar: false,
                    });
                    requestZip({
                      variables: {
                        projectID,
                        attachments: selectedFolders.map(f => f.id),
                      },
                    });
                    setSelectedFolders([]);
                  }
                }}
              >
                <Download width={16} height={16} />
              </DownloadButton>
            </>
          )}
        </Breadcrumbs>
        <Actions>
          <NewCategoryButton variant="outline" onClick={$target => onNewCategoryClick($target)}>
            <Plus width={12} height={12} />
            <NewCategoryButtonText>New Category</NewCategoryButtonText>
          </NewCategoryButton>
          <UploadButton onClick={() => onUploadClick()}>
            <UploadIcon width={12} height={12} />
            <UploadButtonText>Upload</UploadButtonText>
          </UploadButton>
        </Actions>
      </Navbar>

      <Actionbar>
        <RightActions></RightActions>
      </Actionbar>

      <FileList>
        {!curFiles && <EmptyWarning>There are no uploaded files from clients.</EmptyWarning>}
        {curFiles && curFiles.files.length === 0 && categories.size === 0 && (
          <EmptyWarning>There are no uploaded files from clients.</EmptyWarning>
        )}
        {curFiles &&
          curFiles.files.length === 0 &&
          categories.size !== 0 &&
          Array.from(categories).map(([id, category]) => (
            <FileEntry
              key={id}
              file={{
                id: id,
                tags: [],
                name: category.name,
                isDir: true,
                filepath: undefined,
              }}
              isSelected={selectedFolders.findIndex(s => s.id === id) !== -1}
              onContextMenu={(x, y) => {
                setSelectedFolders([{ id, type: 'folder' }]);
                showPopup(
                  { pos: { x, y } },
                  <>
                    <Popup tab={0} title={null}>
                      <MenuItems>
                        <MenuItem onClick={() => setTab(1)}>Delete category</MenuItem>
                      </MenuItems>
                    </Popup>
                    <Popup tab={1} title={`delete category?`}>
                      <WarningLabel>
                        Deleting a category will also remove all attachments associated with the category. This can not
                        be undone.
                      </WarningLabel>
                      <ConfirmButton
                        onClick={() => {
                          deleteAttachmentCategory({ variables: { id } });
                          hidePopup();
                        }}
                        color="danger"
                      >
                        Delete
                      </ConfirmButton>
                    </Popup>
                  </>,
                );
              }}
              onOpen={() => {
                setCurFolder(id);
                setSelectedFolders([]);
              }}
              onSelect={() => {
                if (selectedFolders.findIndex(s => s.id === id) !== -1) {
                  setSelectedFolders(folders => folders.filter(f => f.id !== id));
                } else {
                  setSelectedFolders(folders => [...folders, { id: id, type: 'folder' }]);
                }
              }}
            />
          ))}
        {curFiles &&
          curFiles.files.map((file, idx) => (
            <FileEntry
              key={file.id}
              file={file}
              isSelected={selectedFolders.findIndex(s => s.id === file.id) !== -1}
              onContextMenu={(x, y) => {
                setSelectedFolders([{ id: file.id, type: file.isDir ? 'folder' : 'file' }]);
                console.log(x, y);
                showPopup(
                  { pos: { x, y } },
                  <>
                    <Popup tab={0} title={null}>
                      <MenuItems>
                        <MenuItem onClick={() => setTab(1)}>Delete {file.isDir ? 'category' : 'attachment'}</MenuItem>
                        {!file.isDir && <MenuItem onClick={() => setTab(2)}>Manage tags</MenuItem>}
                      </MenuItems>
                    </Popup>
                    <Popup tab={2} title="Manage tags">
                      <TagManager attachment={file.id} tags={file.tags} projectID={projectID} />
                    </Popup>
                    <Popup tab={1} title={`delete ${file.isDir ? 'category' : 'attachment'}?`}>
                      <WarningLabel>
                        {file.isDir
                          ? 'Deleting a category will also remove all attachments associated with the category. This can not be undone.'
                          : 'Are you sure you want to delete this attachment? This can not be undone.'}
                      </WarningLabel>
                      <ConfirmButton
                        onClick={() => {
                          if (file.isDir) {
                            deleteAttachmentCategory({ variables: { id: file.id } });
                          } else {
                            deleteAttachment({ variables: { id: file.id } });
                          }
                          hidePopup();
                        }}
                        color="danger"
                      >
                        Delete
                      </ConfirmButton>
                    </Popup>
                  </>,
                );
              }}
              onOpen={() => {
                if (file.isDir) {
                  setCurFolder(file.id);
                  setSelectedFolders([]);
                } else {
                  setPreviewFile({ open: true, index: idx, src: curFiles.files.map(f => f.filepath ?? '') });
                }
              }}
              onSelect={() => {
                if (selectedFolders.findIndex(s => s.id === file.id) !== -1) {
                  setSelectedFolders(folders => folders.filter(f => f.id !== file.id));
                } else {
                  setSelectedFolders(folders => [...folders, { id: file.id, type: file.isDir ? 'folder' : 'file' }]);
                }
              }}
            />
          ))}
      </FileList>
      <Viewer
        visible={previewFile.open}
        activeIndex={previewFile.index}
        onClose={() => {
          setPreviewFile({ open: false, index: 0, src: [] });
        }}
        images={previewFile.src.map(i => ({ src: i, alt: '', downloadUrl: `${i}?download=true` }))}
        noNavbar
        onMaskClick={() => setPreviewFile({ open: false, index: 0, src: [] })}
        rotatable={false}
        scalable={false}
        drag={false}
        showTotal={false}
        downloadable
      />
    </FileBrowser>
  );
};

export default UploadsBrowser;
