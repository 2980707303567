import React, { useState, useRef } from 'react';
import {
  useUsersQuery,
  useDeleteUserAccountMutation,
  useCreateUserAccountMutation,
  RoleCode,
  UsersQuery,
  UsersDocument,
  useResetPasswordMutation,
  useUpdateUserRoleMutation,
} from 'shared/generated/graphql';
import { Link, useHistory, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import { Cross, CheckSquare, CheckSquareOutline, Square } from 'shared/icons';
import { usePopup, Popup } from 'shared/components/PopupMenu';
import Button from 'shared/components/Button';
import { useForm, Controller } from 'react-hook-form';
import { useCurrentUser } from 'App/context';
import Navbar from 'App/Navbar';

const DashboardButton = styled.button`
  padding: 6px 12px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
`;

const ActionButtons = styled.div`
  position: absolute;
  left: 42px;
  top: 24px;
  display: flex;
  align-items: center;
`;
const WarningLabel = styled.p`
  font-size: 14px;
  margin: 6px 0;
`;

const ConfirmDelete = styled(Button)`
  padding: 6px 12px;
  margin: 6px 0;
  width: 100%;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const ConfirmResetButton = styled(Button)`
  padding: 6px 12px;
  width: 100%;
`;

type SetRoleData = {
  roleCode: RoleCode;
};

type SetRoleTabProps = {
  initialRoleCode: RoleCode;
  onChangeRole: (roleCode: RoleCode) => void;
};

const SetRoleTab: React.FC<SetRoleTabProps> = ({ initialRoleCode, onChangeRole }) => {
  const { handleSubmit, control } = useForm<SetRoleData>();
  const onSubmit = (data: SetRoleData) => {
    onChangeRole(data.roleCode);
  };
  return (
    <FormContent onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="roleCode"
        defaultValue={initialRoleCode}
        render={props => (
          <Checkbox
            label="Is Admin?"
            onChange={v => props.onChange(toRoleCode(v))}
            value={roleCodeToBoolean(props.value)}
          />
        )}
      />
      <ConfirmResetButton type="submit">Update role</ConfirmResetButton>
    </FormContent>
  );
};
type ResetPasswordData = {
  password: string;
};

type ResetPasswordTabProps = {
  onResetPassword: (newPassword: string) => void;
};

const ResetPasswordTab: React.FC<ResetPasswordTabProps> = ({ onResetPassword }) => {
  const { register, handleSubmit, errors } = useForm<ResetPasswordData>();
  const onSubmit = (data: ResetPasswordData) => {
    onResetPassword(data.password);
  };
  return (
    <FormContent onSubmit={handleSubmit(onSubmit)}>
      <InputField
        autoFocus
        placeholder="New password"
        name="password"
        ref={register({ required: 'New password is required' })}
      />
      {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
      <ConfirmResetButton type="submit">Reset password</ConfirmResetButton>
    </FormContent>
  );
};

type UserContextMenuProps = {
  initialRoleCode: RoleCode;
  onDeleteUser: () => void;
  onResetPassword: (newPassword: string) => void;
  onChangeRole: (roleCode: RoleCode) => void;
};

const UserContextMenu: React.FC<UserContextMenuProps> = ({
  initialRoleCode,
  onDeleteUser,
  onResetPassword,
  onChangeRole,
}) => {
  const { hidePopup, setTab } = usePopup();
  return (
    <>
      <Popup tab={0} title={null}>
        <MenuItems>
          <MenuItem onClick={() => setTab(1)}>Set role</MenuItem>
          <MenuItem onClick={() => setTab(2)}>Reset password</MenuItem>
          <MenuItem onClick={() => setTab(3)}>Delete user</MenuItem>
        </MenuItems>
      </Popup>
      <Popup tab={1} title="Set role">
        <SetRoleTab
          initialRoleCode={initialRoleCode}
          onChangeRole={roleCode => {
            hidePopup();
            onChangeRole(roleCode);
          }}
        />
      </Popup>
      <Popup tab={2} title="Reset password">
        <ResetPasswordTab
          onResetPassword={newPassword => {
            hidePopup();
            onResetPassword(newPassword);
          }}
        />
      </Popup>
      <Popup tab={3} title="Delete user?">
        <WarningLabel>User deletion can not be undone. Do you want to proceed?</WarningLabel>
        <ConfirmDelete
          onClick={() => {
            hidePopup();
            onDeleteUser();
          }}
          color="danger"
        >
          Delete user
        </ConfirmDelete>
      </Popup>
    </>
  );
};

const ErrorMessage = styled.span`
  font-size: 14px;
  display: flex;
  padding: 3px 0;
  color: rgba(${props => props.theme.colors.danger});
`;
function toRoleCode(v: boolean) {
  return v ? RoleCode.Admin : RoleCode.Client;
}

function roleCodeToBoolean(r: RoleCode) {
  return r === RoleCode.Admin ? true : false;
}

type UserData = {
  fullName: string;
  username: string;
  password: string;
  email: string;
  roleCode: RoleCode;
};

const FormContent = styled.form`
  margin: 6px 12px;
`;

const UserContent = styled.form`
  margin: 6px 12px;
`;

const InputField = styled.input`
  width: 100%;

  border: none;
  background: #383838;
  border-radius: 16px;
  margin-top: 8px;
  padding: 6px 12px;
  font-size: 18px;
  color: #fff;
  &:placeholder {
    color: #fff;
    opacity: 1;
  }
`;

const CreateButton = styled(Button)`
  margin-top: 4px;
  padding: 6px 12px;
  width: 100%;
`;

const IsAdminCheckbox = styled.input``;
const IsAdminLabel = styled.label``;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 4px 0;
  svg {
    fill: #000;
    stroke: #000;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  padding-left: 6px;
  user-select: none;
`;

type CheckboxProps = {
  onChange: (value: boolean) => void;
  value: boolean;
  label?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({ onChange, value, label }) => {
  return (
    <CheckboxContainer onClick={() => onChange(!value)}>
      {value ? <CheckSquareOutline width={16} height={16} /> : <Square width={16} height={16} />}
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxContainer>
  );
};

type NewUserPopupProps = {
  onCreateUser: (user: UserData) => void;
};

const NewUserPopup: React.FC<NewUserPopupProps> = ({ onCreateUser }) => {
  const { register, handleSubmit, errors, control } = useForm<UserData>();
  console.log(errors);
  return (
    <UserContent
      onSubmit={handleSubmit(data => {
        onCreateUser(data);
      })}
    >
      <InputField name="fullName" ref={register({ required: 'Full name is required' })} placeholder="Full name" />
      {errors.fullName && <ErrorMessage>{errors.fullName.message}</ErrorMessage>}

      <InputField name="email" ref={register({ required: 'Email is required' })} placeholder="Email" />
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <InputField name="username" ref={register({ required: 'Username is required' })} placeholder="Username" />
      {errors.username && <ErrorMessage>{errors.username.message}</ErrorMessage>}

      <InputField
        name="password"
        ref={register({ required: 'Password is required' })}
        type="password"
        placeholder="Password"
      />
      {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}

      <Controller
        control={control}
        name="roleCode"
        defaultValue={RoleCode.Client}
        render={props => (
          <Checkbox
            label="Is Admin?"
            onChange={v => props.onChange(toRoleCode(v))}
            value={roleCodeToBoolean(props.value)}
          />
        )}
      />
      <CreateButton type="submit">Create user</CreateButton>
    </UserContent>
  );
};

const NewUserContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  flex-direction: row-reverse;
`;

const NewUserButton = styled.button`
  background: #000;
  color: #fff;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 6px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 10;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  background: #fff;
  border-radius: 18px;
  padding: 24px 32px;
`;

const UsersTitle = styled.h3`
  text-align: center;
  margin-bottom: 16px;
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  width: 840px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 700;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: #f7f7f7;
  }
`;

const Fullname = styled.span`
  font-size: 14px;
  width: 100%;
  padding-left: 6px;
`;

const Email = styled.span`
  font-size: 14px;
  min-width: 260px;
`;

const Username = styled.span`
  font-size: 14px;
  min-width: 120px;
`;

const Role = styled.span`
  font-size: 14px;
  min-width: 80px;
`;
const Users: React.FC = () => {
  const { loading, data } = useUsersQuery();
  const { showPopup, hidePopup } = usePopup();
  const $userBtn = useRef<HTMLButtonElement>(null);
  const [updateUserRole] = useUpdateUserRoleMutation();
  const [deleteUser] = useDeleteUserAccountMutation({
    update: (client, r) =>
      updateApolloCache<UsersQuery>(client, UsersDocument, cache =>
        produce(cache, draftCache => {
          draftCache.users = cache.users.filter(u => u.id !== r.data.deleteUserAccount.userID);
        }),
      ),
  });
  const { user } = useCurrentUser();
  const [resetPassword] = useResetPasswordMutation();
  const [createUser] = useCreateUserAccountMutation({
    update: (client, r) =>
      updateApolloCache<UsersQuery>(client, UsersDocument, cache =>
        produce(cache, draftCache => {
          draftCache.users.push(r.data.createUserAccount);
        }),
      ),
  });

  if (user && !user.isAdmin()) {
    return <Redirect to="/" />;
  }

  if (data) {
    return (
      <>
        <Navbar title="Users" inlayHeight={375} />
        <Container>
          <InnerContainer>
            <UserList>
              <HeaderRow>
                <Fullname>Name</Fullname>
                <Email>Email</Email>
                <Username>Username</Username>
                <Role>Role</Role>
              </HeaderRow>
              {data.users.map(user => (
                <UserRow
                  onClick={e => {
                    console.log(e.clientX);
                    console.log(e.clientY);
                    showPopup(
                      { pos: { x: e.clientX, y: e.clientY } },
                      <UserContextMenu
                        initialRoleCode={user.role.code}
                        onChangeRole={roleCode => updateUserRole({ variables: { userID: user.id, roleCode } })}
                        onResetPassword={newPassword => resetPassword({ variables: { userID: user.id, newPassword } })}
                        onDeleteUser={() => deleteUser({ variables: { userID: user.id } })}
                      />,
                    );
                  }}
                >
                  <Fullname>{user.fullName}</Fullname>
                  <Email>{user.email}</Email>
                  <Username>{user.username}</Username>
                  <Role>{user.role.name}</Role>
                </UserRow>
              ))}
            </UserList>
            <NewUserContainer>
              <NewUserButton
                onClick={() => {
                  showPopup(
                    { ref: $userBtn },
                    <Popup tab={0} title="Create user" onClose={() => hidePopup()}>
                      <NewUserPopup
                        onCreateUser={user => {
                          createUser({ variables: { ...user } });
                          hidePopup();
                        }}
                      />
                    </Popup>,
                  );
                }}
                ref={$userBtn}
              >
                New User
              </NewUserButton>
            </NewUserContainer>
          </InnerContainer>
        </Container>
      </>
    );
  }
  return <Navbar inlayHeight={375} />;
};

export default Users;
