import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';
import TaskAssignee from 'shared/components/TaskAssignee';
import { Paperclip, At, Smile } from 'shared/icons';
import Button from 'shared/components/Button';
import useOnOutsideClick from 'shared/hooks/onOutsideClick';

const CommentContainer = styled.div<{ background: boolean }>`
  flex: 0 0 auto;
  margin-top: auto;
  ${props =>
    props.background &&
    css`
      padding: 15px 26px;
      background: #f6f8f9;
    `}
`;

const CommentInnerWrapper = styled.div`
  display: flex;
  position: relative;
`;

const CommentEditorContainer = styled.div`
  flex: 1;
  border-radius: 6px;
  border: 1px solid #cbd4db;
  display: flex;
  flex-direction: column;
`;

const CommentProfile = styled(TaskAssignee)`
  margin-right: 8px;
  position: relative;
  top: 0;
  padding-top: 3px;
  align-items: normal;
`;

const CommentTextArea = styled(TextareaAutosize)<{ active: boolean }>`
  width: 100%;
  line-height: 28px;
  padding: 4px 6px;
  border-radius: 6px;
  color: #000;
  background: #fff;
  border: none;
  transition: max-height 200ms, height 200ms, min-height 200ms;
  min-height: 36px;
  max-height: 36px;
  ${props =>
    props.active
      ? css`
          min-height: 80px;
          max-height: none;
          line-height: 20px;
        `
      : css`
          height: 36px;
        `}
`;

const CommentEditorActions = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  padding: 5px 5px 5px 9px;
  border-top: 1px solid #cbd4db;
`;

const CommentEditorActionIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #6f7782;
  }
`;

const CommentEditorSaveButton = styled(Button)`
  margin-left: auto;
  padding: 8px 16px;
`;

type CommentCreatorProps = {
  member?: { id: string; profileIcon: ProfileIcon } | null;
  initialComment?: string | null;
  onCancel?: () => void;
  onCreateComment: (comment: string) => void;
  autoFocus?: boolean;
  background?: boolean;
};

const CommentCreator: React.FC<CommentCreatorProps> = ({
  onCreateComment,
  member,
  initialComment,
  autoFocus = false,
  background = true,
  onCancel,
}) => {
  const [showCommentActions, setShowCommentActions] = useState(false);
  const [comment, setComment] = useState(initialComment ?? '');
  const $container = useRef<HTMLDivElement>(null);
  useOnOutsideClick(
    $container,
    true,
    () => {
      if (onCancel) {
        onCancel();
      }
      setShowCommentActions(false);
    },
    null,
  );
  return (
    <CommentContainer background={background} ref={$container}>
      <CommentInnerWrapper>
        {member && (
          <CommentProfile
            member={member}
            size={32}
            onMemberProfile={$target => {
              // onMemberProfile($target, me.id);
            }}
          />
        )}
        <CommentEditorContainer>
          <CommentTextArea
            active={showCommentActions}
            placeholder="Write a comment..."
            value={comment}
            autoFocus={autoFocus}
            onChange={e => setComment(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.keyCode === 13 && e.ctrlKey) {
                e.preventDefault();
                onCreateComment(comment);
                setComment('');
              }
            }}
            onFocus={() => {
              setShowCommentActions(true);
            }}
          />
          <CommentEditorActions visible={showCommentActions}>
            <CommentEditorActionIcon>
              <Paperclip width={12} height={12} />
            </CommentEditorActionIcon>
            <CommentEditorActionIcon>
              <At width={12} height={12} />
            </CommentEditorActionIcon>
            <CommentEditorActionIcon>
              <Smile width={12} height={12} />
            </CommentEditorActionIcon>
            <CommentEditorSaveButton
              disabled={comment.trim() === ''}
              onClick={() => {
                onCreateComment(comment);
                setComment('');
              }}
            >
              Save
            </CommentEditorSaveButton>
          </CommentEditorActions>
        </CommentEditorContainer>
      </CommentInnerWrapper>
    </CommentContainer>
  );
};

export default CommentCreator;
