import React from 'react';
import Icon, { IconProps } from './Icon';

const Do: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 843.73 720.83">
      <path
        d="M486.65,458.42c6.33-3.41,11.8-9,16.24-16.6s7.71-17.69,9.92-30.3,3.32-28.16,3.32-46.55c0-15.25-1.07-28.18-3.19-38.44s-5.34-18.73-9.47-24.81a35.43,35.43,0,0,0-16.28-13.37,59.48,59.48,0,0,0-22.37-3.9H440.37c-3.2,0-5.32.55-6.71,1.77a6.36,6.36,0,0,0-2,4.93V463.49l33.37,0h.79A45,45,0,0,0,486.65,458.42ZM475.82,309.29c2.85,1.48,5.17,4.15,7.11,8.16,2,4.17,3.57,10,4.62,17.2A202.67,202.67,0,0,1,489.18,363c0,17.19-.73,31.19-2.17,41.6s-3.28,18.16-5.64,23.39c-2.27,5.05-4.77,8.43-7.46,10.07a16.25,16.25,0,0,1-8.56,2.42h-9.48V307h9.76A21.71,21.71,0,0,1,475.82,309.29Z"
        transform="translate(-17 -13.69)"
      />
      <path
        d="M551.34,461.72c6,2.86,13.37,4.31,21.85,4.31,11.49,0,21-3,28.3-9s12.65-16,16-29.67c3.29-13.44,5-32,5-55,0-17-.82-31.4-2.42-42.71s-4.27-20.73-7.88-27.64c-3.68-7.09-8.62-12.25-14.67-15.34S584.29,282,576,282c-11.3,0-20.81,2.95-28.26,8.78S534.64,306.34,531,319.7c-3.56,13.11-5.36,31-5.36,53.31,0,18,.85,33.15,2.54,45s4.44,21.54,8.13,28.54C540.15,453.68,545.2,458.8,551.34,461.72Zm35.58-26.62a14.72,14.72,0,0,1-11.09,6v0l-2,.08a14.92,14.92,0,0,1-12.46-5.9c-3.22-4.14-5.54-10.88-6.92-20-1.4-9.38-2.12-21.93-2.12-37.28,0-17.83.86-32.22,2.52-42.77,1.62-10.31,4.2-17.78,7.71-22.24a15.65,15.65,0,0,1,12.53-6.25h.32c5.48,0,9.57,1.88,12.48,5.75,3.06,4.1,5.26,11,6.56,20.6,1.33,9.81,2,23.16,2,39.69s-.8,30.27-2.37,40.65C592.59,423.46,590.17,430.76,586.92,435.1Z"
        transform="translate(-17 -13.69)"
      />
      <path
        d="M860.55,362.7C856.11,222.5,769.8,133.05,743.45,109,712.21,80.39,626,13.86,500.74,13.69h-.53c-24.76.05-75.32,3.83-135.78,28.5a412.27,412.27,0,0,0-65.69,34.32,298.4,298.4,0,0,0-36.09,27.1h48.7c42-29.29,105.81-60,188.88-60h.47c114.69.17,193.8,61.23,222.49,87.48,24.17,22.1,103.34,104.19,107.38,232.65,3.52,111.24-51.43,190.53-75.22,219.46-24.1,29.27-92.2,99.3-203.83,117.28C513.38,706.58,437.27,711.12,356,672.3a327.55,327.55,0,0,1-107.48-82.21l-4.47-5.19H48.53V557.21L195,400.72a220.46,220.46,0,0,0,25.21-29.45c12-16.83,30.2-42.28,31.21-76.27a87,87,0,0,0-5.66-34.65l0-.1c-2.13-5.36-5.34-13.45-13-21.45-16.31-17-38.07-18.16-45.23-18.52-15.47-.79-29.58,2.68-42,10.32a70.07,70.07,0,0,0-20.37,19.19,81.32,81.32,0,0,0-15,38.61H49.29c3.26-19.91,12.6-50.41,39-76.19,24.33-23.77,55.82-36.78,93.61-38.66,19.22-1,84.47-.43,117.39,45.84,33.41,46.92,24.1,108.17-25.55,168.05-23.55,28.4-49.47,57.15-72.34,82.53l-1.26,1.4c-11.71,13-23.83,26.49-35.63,39.87l-21.92,24.81h172.6V506.17H209.12l7.88-8.74,7.55-8.39c24-26.72,48.88-54.34,72.29-82.55,59-71.11,68.75-145.6,26.89-204.38s-120.24-59.54-143.3-58.4C135.14,146,97.09,161.83,67.32,190.88c-42.24,41.26-48.75,92-49.59,111.82L17,318.3H140l-.61-15.48c-.35-9.46,1.11-23.28,10-35.43l.08-.13A41.15,41.15,0,0,1,161.29,256c7-4.37,15.32-6.34,24.59-5.85H186c5.09.28,17,.93,25,9.33,3.51,3.63,5.07,7.53,6.73,11.67l0,.1A57,57,0,0,1,221.45,294v.1c-.75,25-15.76,46-25.69,59.92a191.9,191.9,0,0,1-22,25.69L18.55,545.42v69.31H230.4A360.53,360.53,0,0,0,556.31,729.9c121.75-19.59,196-95.94,222.23-127.85h0C804.48,570.5,864.39,484,860.55,362.7Z"
        transform="translate(-17 -13.69)"
      />
    </Icon>
  );
};

export default Do;
