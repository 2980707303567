import React from 'react';
import styled, { css } from 'styled-components/macro';

export type IconProps = {
  width: number | string;
  height: number | string;
  className?: string;
  onClick?: () => void;
};

type Props = {
  width: number | string;
  height: number | string;
  viewBox: string;
  className?: string;
  color?: string;
  onClick?: () => void;
};

const Svg = styled.svg<{ color?: string }>`
  fill: rgba(${props => props.theme.colors.text.primary});
  stroke: rgba(${props => props.theme.colors.text.primary});
  ${props =>
    props.color &&
    css`
      fill: ${props.color};
      stroke: ${props.color};
    `}
`;

const Icon: React.FC<Props> = ({ width, height, viewBox, className, onClick, color, children }) => {
  return (
    <Svg
      color={color}
      onClick={e => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
    >
      {children}
    </Svg>
  );
};

export default Icon;
