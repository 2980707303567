import React, { useState, useRef, useEffect } from 'react';
import {
  useUsersQuery,
  useDeleteUserAccountMutation,
  useCreateUserAccountMutation,
  useUpdateUserFullnameMutation,
  useInboxQuery,
  RoleCode,
  UsersQuery,
  UsersDocument,
  useResetPasswordMutation,
  useUpdateUserRoleMutation,
  useMeQuery,
  useMarkInboxReadMutation,
  NotiType,
  useRemoveAvatarMutation,
  MeQuery,
  MeDocument,
} from 'shared/generated/graphql';
import { Link, useHistory, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import {
  Cross,
  CheckSquare,
  CheckSquareOutline,
  Square,
  Inbox as InboxIcon,
  Tasks,
  Payment,
  Support,
} from 'shared/icons';
import { usePopup, Popup } from 'shared/components/PopupMenu';
import Button from 'shared/components/Button';
import { useForm, Controller } from 'react-hook-form';
import { useCurrentUser } from 'App/context';
import Navbar from 'App/Navbar';
import { getAccessToken } from 'shared/utils/accessToken';
import axios from 'axios';
import dayjs from 'dayjs';

const InputField = styled.input`
  width: 100%;

  border: none;
  margin-top: 4px;
  padding: 6px 12px;
  font-size: 18px;
  color: #000;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const InputLabel = styled.span`
  margin-top: 8px;
  font-size: 18px;
`;

const TermSheetsTab = () => {
  return null;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InfoSave = styled(Button)`
  margin-top: 12px;
`;

const ChangePictureLabel = styled.span`
  font-size: 20px;
  margin-top: 8px;
  &:hover {
    text-decoration: underline;
  }
`;

const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 24px;
`;

const RemovePictureButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #000;
  svg {
    fill: #fff;
    stroke: #fff;
  }
`;

const ProfileIconImage = styled.img`
width 260px;
height 260px;
border-radius: 50%;
`;

type InfoOverviewTabProps = {
  name: string;
  email: string;
  onSave: (data: { name: string }) => void;
};
const InfoOverviewTab: React.FC<InfoOverviewTabProps> = ({ name, email, onSave }) => {
  const { handleSubmit, register, errors } = useForm<{ name: string }>();
  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <InputLabel>Name</InputLabel>
      <InputField
        ref={register({ required: 'Name is required' })}
        name="name"
        id="name"
        type="text"
        defaultValue={name}
      />
      {errors.name && <span>{errors.name.message}</span>}
      <InputLabel>Email</InputLabel>
      <InputField disabled type="text" value={email} />
      <InfoSave type="submit">Save</InfoSave>
    </Form>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  position: relative;
  z-index: 10;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  max-width: 100%;
  padding: 24px 32px;
  width: 100%;
  max-width: 1400px;
`;

const ProfileContainer = styled.div`
  background: #f4f4f4;
  border-radius: 33px;
  width: 27.5%;
`;

const InfoContainer = styled.div`
  background: #f4f4f4;
  border-radius: 33px;
  margin: 0 30px;
  width: 36.25%;
`;

const QuicklinksContainer = styled.div`
  background: #f4f4f4;
  border-radius: 33px;
  width: 36.25%;
`;

const QuicklinksGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 24px;
  padding-top: 0;
`;

const QuicklinksGridItemLabel = styled.div`
  font-size: 24px;
  text-align: center;
`;

const QuicklinksTitle = styled.h3`
  font-size: 20px;
  text-align: center;
  margin-top: 6px;
`;

const QuicklinksGridItem = styled.div<{ negativeMargins?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 8px 0;
  flex: 1 1 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  svg {
    fill: #383838;
    stroke: #383838;
    ${p => p.negativeMargins && 'margin-right: -15px;'}
  }
  &:hover {
    margin-top: -3px;
  }
`;

const Tab = styled.div<{ active: boolean }>`
  background: ${p => (p.active ? '#F4F4F4' : '#E5E5E5')};
  display: flex;
  align-item: center;
  justify-content: center;
  flex: 1 0;
  height: 100%;
  cursor: pointer;
`;

const Tabs = styled.div`
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  & ${Tab}:first-child {
    border-top-left-radius: 33px;
  }
  & ${Tab}:last-child {
    border-top-right-radius: 33px;
  }
`;

const TabLabel = styled.span`
  font-size: 20px;
  display: flex;
  align-items: center;
`;
const EmptyInbox = styled.span`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 12px;
`;

const InboxWrapper = styled.div`
  opacity: 1;
  width: 100%;
  padding: 12px 24px;
`;

const NotificationContainer = styled(Link)<{ read: boolean }>`
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
  border-bottom: 1px solid #dde0e5;
  padding: 18px 18px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 10%);
  cursor: pointer;
  ${props =>
    props.read
      ? css`
          background-color: #e8ecf0;
          box-shadow: none;
        `
      : css`
          background-color: #fff;
        `}
`;

const InboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${NotificationContainer}:last-child {
    margin-bottom: 0;
  }
`;

const CausedBy = styled.div`
  display: flex;
  align-items: center;
`;
const CommentText = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;
const TaskLink = styled(Link)``;
const TaskLinkButton = styled(Button)`
  padding: 8px 12px;
`;
const Username = styled.span`
  font-weight: bold;
`;
const TaskTitle = styled.span`
  font-weight: bold;
`;
const NotificationData = styled.div`
  display: flex;
  flex-direction: column;
`;

const Timestamp = styled.span``;

type NotificationProps = {
  type: NotiType;
  createdAt: string;
  causedBy: string;
  read: boolean;
  readAt?: string;
  data: Array<{ key: string; value: string }>;
};

function getData(key: string, data: Array<{ key: string; value: string }>) {
  return data.find(c => c.key === key)?.value ?? '';
}

const Notification: React.FC<NotificationProps> = ({ data, createdAt, read }) => {
  return (
    <NotificationContainer read={read} to={`/projects/${getData('ProjectID', data)}/c/${getData('TaskID', data)}`}>
      <NotificationData>
        <CausedBy>
          <Username>{getData('CausedBy', data)}</Username>
          <span style={{ whiteSpace: 'break-spaces' }}> left a comment on </span>
          <TaskTitle>{getData('TaskTitle', data)}</TaskTitle>
        </CausedBy>
        <CommentText>{getData('Comment', data)}</CommentText>
      </NotificationData>
      <Timestamp>{dayjs.duration(dayjs(createdAt).diff(dayjs())).humanize(true)}</Timestamp>
    </NotificationContainer>
  );
};

const Inbox: React.FC = () => {
  const { data, refetch } = useInboxQuery();
  const [markInboxRead] = useMarkInboxReadMutation({
    update: client =>
      updateApolloCache<MeQuery>(client, MeDocument, cache =>
        produce(cache, draftCache => {
          draftCache.notificationsUnreadCount.count = 0;
        }),
      ),
  });
  useEffect(() => {
    markInboxRead();
  }, []);
  if (data) {
    return (
      <>
        <Navbar title="Inbox" inlayHeight={175} />
        <Container>
          <InnerContainer>
            <InboxWrapper>
              <InboxContainer>
                {data.notifications.notifications.length === 0 && <EmptyInbox>You have no notifications</EmptyInbox>}
                {data.notifications.notifications.map(notification => (
                  <Notification {...notification} />
                ))}
              </InboxContainer>
            </InboxWrapper>
          </InnerContainer>
        </Container>
      </>
    );
  }
  return <Navbar inlayHeight={175} />;
};

export default Inbox;
