import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { mixin } from 'shared/utils/styles';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { Trash, Internal, Users } from 'shared/icons';
import Button from 'shared/components/Button';
import { VisibilityStatus } from 'shared/generated/graphql';
import useOnOutsideClick from 'shared/hooks/onOutsideClick';

export const CardNameTextarea = styled(TextareaAutosize)`
  background-color: #fff;
  border: none;
  box-shadow: inset 0 0 0 2px #0079bf;
  transition: margin 85ms ease-in, background 85ms ease-in;
  line-height: 20px;
  padding: 8px 12px;

  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  height: 54px;
  width: 100%;

  border: none;
  border-radius: 18px;
  box-shadow: none;
  margin-bottom: 4px;
  max-height: 162px;
  min-height: 54px;
  font-size: 14px;
  line-height: 20px;

  color: #000;
`;

export const ListCardContainer = styled.div<{ editable: boolean }>`
  max-width: 320px;
  margin-bottom: 8px;
  border-radius: 18px;

  box-shadow: 0px 2px 2px #00000026;

  ${props => !props.editable && 'cursor: pointer'};
  position: relative;

  background-color: #fff;
`;

export const ListCardInnerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ListCardDetails = styled.div`
  padding: 12px 20px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardTitle = styled.span`
  clear: both;
  margin: 0;
  overflow: hidden;
  text-decoration: none;
  word-wrap: break-word;
  line-height: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  display: flex;
  align-items: center;
`;

const DrivenLogo = styled.div<{ internal: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${props => (props.internal ? '#f8931e' : '#000')};
  }
`;

export const Labels = styled.div`
  min-width: 56px;
  width: auto;
  height: 16px;
`;
export const Label = styled.span`
  font-size: 12px;
  height: 16px;
  min-width: 56px;
  width: auto;
  padding: 0 8px;
  max-width: 198px;
  float: left;
  margin: 0 4px 4px 0;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #000;
`;

const SaveCard = styled(Button)`
  padding: 6px 12px;
`;

const EditorControls = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  padding-bottom: 4px;
`;

const StatusSwitcher = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2px;
  flex: 1 1 0;
  justify-content: flex-end;
`;

const DeleteIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 6px;
  svg {
    fill: rgba(0, 0, 0, 0.85);
  }
  &:hover svg {
    fill: rgba(0, 0, 0, 1);
  }
`;

const InternalIcon = styled.div<{ active: boolean; color: string }>`
  margin: 0 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  ${props =>
    props.active &&
    css`
      svg {
        fill: ${props.color};
      }
    `}
`;

type CardEditorProps = {
  name: string;
  onNameChange: (name: string) => void;
  onDelete: () => void;
  onClose: () => void;
};

const CardEditor: React.FC<CardEditorProps> = ({ onNameChange, name, onDelete, onClose }) => {
  const $container = useRef<HTMLDivElement>(null);
  const $editor = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if ($editor && $editor.current) {
      $editor.current.select();
    }
  });
  useOnOutsideClick(
    $container,
    true,
    () => {
      onClose();
    },
    null,
  );
  return (
    <ListCardInnerContainer ref={$container}>
      <CardNameTextarea
        defaultValue={name}
        ref={$editor}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            e.preventDefault();
            if ($editor && $editor.current) {
              onNameChange($editor.current.value);
              onClose();
            }
          }
        }}
      />
      <EditorControls>
        <SaveCard
          disabled={name.trim() === ''}
          onClick={() => {
            if ($editor && $editor.current) {
              onNameChange(name);
              onClose();
            }
          }}
        >
          Save
        </SaveCard>
        <DeleteIcon onClick={onDelete}>
          <Trash width={12} height={12} />
        </DeleteIcon>
      </EditorControls>
    </ListCardInnerContainer>
  );
};

type CardChangeActions = {
  onDelete: () => void;
  onNameChange: (name: string) => void;
  onOpen: () => void;
};

type CardProps = {
  name: string;
  innerRef?: any;
  handleProps?: any;
  wrapperProps?: any;
  actions: CardChangeActions;
  onOpenToggle: (isOpen: boolean) => void;
};

const Card: React.FC<CardProps> = ({ name, handleProps, wrapperProps, innerRef, actions, onOpenToggle }) => {
  const useLabel = false;
  const [editable, setEditable] = useState(false);
  return (
    <ListCardContainer ref={innerRef} editable={editable} {...wrapperProps}>
      {editable && actions ? (
        <CardEditor
          onClose={() => {
            onOpenToggle(false);
            setEditable(false);
          }}
          name={name}
          onNameChange={actions.onNameChange}
          onDelete={actions.onDelete}
        />
      ) : (
        <ListCardInnerContainer
          onClick={() => {
            actions.onOpen();
          }}
          onContextMenu={e => {
            e.preventDefault();
            setEditable(true);
            onOpenToggle(true);
          }}
        >
          <ListCardDetails {...handleProps}>
            {useLabel && (
              <Labels>
                <Label>Driven Digital</Label>
              </Labels>
            )}
            <CardTitle>{name}</CardTitle>
          </ListCardDetails>
        </ListCardInnerContainer>
      )}
    </ListCardContainer>
  );
};

export default Card;
