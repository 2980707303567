import React from 'react';
import Icon, { IconProps } from './Icon';

const Support: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 1000 1000">
      <g>
        <path
          d="M667.71,642.48c60.77-60.77,76.39-149.56,46.86-224.71L578.79,553.55L461.62,436.38l136.27-136.27
		c-75.48-30.37-165.1-14.96-226.3,46.24c-61.17,61.17-76.57,150.78-46.22,226.28L126.38,771.62c-32.36,32.36-32.36,84.82,0,117.17
		l0,0c32.36,32.36,84.82,32.36,117.17,0L443.04,689.3C518.2,718.8,606.97,703.22,667.71,642.48z"
        />
        <path
          d="M733.97,801.59c17.74-12.41,34.65-26.53,50.49-42.37c15.56-15.56,29.44-32.15,41.69-49.54
		c15.56,10.06,36.65,5.41,46.04-10.75c11.99-20.62,22.1-42.22,30.27-64.64c6.4-17.56-3.52-36.73-21.2-42.25
		c11.39-42.33,15.2-86.26,11.5-129.67c18.39-2.29,31.55-19.4,28.37-37.82c-4.06-23.51-10.16-46.53-18.26-68.91
		c-6.36-17.57-26.27-25.91-43.37-18.79c-17.95-38.73-42.89-75.03-74.8-107c13.03-13.54,12.23-35.44-2.2-47.63
		c-18.22-15.4-37.69-29.11-58.28-41.05c-16.36-9.48-37.28-2.82-45.61,14.06c-40.17-18.82-82.98-30.1-126.33-33.87
		c0.89-18.51-13.72-34.4-32.41-34.42c-23.84-0.02-47.57,2.04-71.02,6.17c-18.4,3.24-30.05,21.42-25.98,39.49
		c-41.53,11.07-81.52,29.39-117.98,54.97c-11.23-14.73-32.64-17.49-46.95-5.48c-9.14,7.68-17.97,15.76-26.41,24.21
		c-8.19,8.19-16.05,16.74-23.51,25.58c-12.23,14.49-9.93,36.09,4.99,47.48c-25.62,36.44-43.99,76.42-55.1,117.95
		c-18.06-4.08-36.25,7.55-39.51,25.95c-4.15,23.45-6.23,47.18-6.23,71.03c0,18.69,15.89,33.32,34.4,32.44
		c3.54,41.08,13.83,81.68,30.87,120.01l70.6-70.6c-29.01-96.71-5.32-205.85,71.08-282.25c110.75-110.75,290.31-110.75,401.06,0
		s110.75,290.31,0,401.06c-75.95,75.95-184.26,99.81-280.55,71.59l-81.14,81.14c0.44,12.8,8.3,24.81,21.19,29.51
		c22.37,8.17,45.39,14.35,68.91,18.49c18.41,3.24,35.57-9.87,37.91-28.25c43.4,3.83,87.33,0.13,129.68-11.12
		c5.45,17.7,24.6,27.69,42.18,21.34c22.44-8.1,44.06-18.14,64.71-30.06C739.26,838.24,743.98,817.17,733.97,801.59z"
        />
      </g>
    </Icon>
  );
};

export default Support;
