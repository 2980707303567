import React from 'react';
import Icon, { IconProps } from './Icon';

const Plan: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 801.03 698.9">
      <path
        d="M352.86,310c-7.08-3.54-16.11-5.33-26.85-5.33H301.23c-2.23,0-3.79.53-4.75,1.62l-.08.09a6.31,6.31,0,0,0-1.3,4.18V483.54h1.83c6.85,0,11.73-.08,14.5-.25,4.17-.25,5.41-.86,6.09-1.42l.18-.19a5.38,5.38,0,0,0,1.34-4.05V412.47h7.17a54.06,54.06,0,0,0,18.12-3,41,41,0,0,0,15.34-9.57,44,44,0,0,0,10.46-17.35c2.46-7.08,3.72-16,3.77-26.51,0-11.88-1.7-21.68-5-29.11A33.72,33.72,0,0,0,352.86,310Zm-14.08,76.64a16.21,16.21,0,0,1-10.52,3.37H319.1V326.14h10.68c4.55,0,8.16,1.09,10.71,3.21a17.4,17.4,0,0,1,5.55,9.32,58.66,58.66,0,0,1,1.69,14.94c0,9.11-.85,16.46-2.46,21.85h0C343.72,380.66,341.53,384.45,338.78,386.68Z"
        transform="translate(-22.62 -24)"
      />
      <path
        d="M409.42,304.71h-1.83c-6.69,0-11.53.09-14.37.25-3.49.21-5.52.88-6.58,2.18l-.11.16a8.25,8.25,0,0,0-1.29,5.07V483.59h61.59a6.94,6.94,0,0,0,3.66-1.06c1.3-.86,1.95-2.46,1.95-4.78v-16.9H409.39Z"
        transform="translate(-22.62 -24)"
      />
      <path
        d="M519.71,306.08l-.31-1.18-1.21-.17c-1.29-.18-2.8-.36-4.46-.51s-3.58-.26-5.3-.26c-5.63,0-8.85,1.67-9.53,4.87L453.81,483.18l9.45.64c2.53.18,4.32.27,5.63.27,5.82,0,7.61-.71,8.53-1.33a6.6,6.6,0,0,0,2.76-4.09L489.46,439h40.42l10.61,44.45,1.35.09c2.83.17,5.09.3,6.92.37,2,.09,3.76.14,5.2.14,4.55,0,7.22-.45,8.6-1.41a4.49,4.49,0,0,0,1.92-5.2Zm4.86,110.74H494.66l7.78-33.24c1.51-5.68,2.86-11.53,4-17.37s2.22-11.53,3.14-16.95a2.93,2.93,0,0,0,.05-.29c0,.09,0,.19,0,.28.89,5.43,2,11.14,3.15,16.95s2.43,11.67,3.77,17.34Z"
        transform="translate(-22.62 -24)"
      />
      <path
        d="M663.62,304.71c-6.71,0-11.56.09-14.42.25-4.34.26-5.56.86-6.25,1.47l-.11.12a5.36,5.36,0,0,0-1.41,4.07V378.1c0,3.64.05,7.91.13,12.68s.21,9.76.38,14.91.33,10.22.49,15.14q.17,5.07.35,9.54l-3.24-9.92q-3-9.15-6-18.27c-2-6.06-3.88-11.71-5.64-16.79s-3.22-9.11-4.37-12L595,304.69H578.25c-3.87,0-6.09,2.13-6.09,5.84v173H574c6.83,0,11.71-.08,14.5-.25,4.15-.25,5.4-.86,6.07-1.42l.16-.16a5.37,5.37,0,0,0,1.41-4.08V408.4c0-3.84-.09-8.32-.26-13.33s-.34-10.25-.5-15.76l-.13-4.22c-.12-4-.25-7.9-.37-11.67-.07-2.25-.16-4.44-.25-6.57q1.57,4.77,3.31,10c2,6.15,4.11,12.42,6.13,18.63s3.89,11.77,5.65,16.69,3.13,8.71,4.16,11.48l29.25,69.94h16.23c3.87,0,6.09-2.13,6.09-5.83v-173Z"
        transform="translate(-22.62 -24)"
      />
      <path
        d="M823.64,370.58c-.86-82.78-32.36-163.41-88.71-227C703,107.57,633.75,44.71,528.41,28a328.24,328.24,0,0,0-118.24,2.88,372,372,0,0,0-94.38,33.68,418.37,418.37,0,0,0-44.28,26.32h56.73c49-25.11,117.23-46.79,195.62-34.19C676.63,81.29,793.05,216.36,794.65,370.91,796.08,508.78,706.18,635.09,576,678.07c-124.15,41-263.47.25-347.55-101.33V152.07H85.72L22.62,256.91h93.65V590.57h29V227.89H73.91l28.16-46.8h97.39V587l3.2,4A353,353,0,0,0,477.42,722.9h0A342.74,342.74,0,0,0,585.1,705.58C656,682.17,716.39,637.09,759.74,575.22,801.69,515.37,824.38,442.69,823.64,370.58Z"
        transform="translate(-22.62 -24)"
      />
    </Icon>
  );
};

export default Plan;
