import React, { useState, useEffect } from 'react';
import {
  User,
  Lock,
  SocialFacebook,
  SocialInstagram,
  SocialTwitter,
  SocialLinkedIn,
  Envelope,
  MapMarkerAlt,
  Cross,
} from 'shared/icons';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { GoogleLogin } from 'react-google-login';
import * as QueryString from 'query-string';
import {
  FormErrorText,
  ContactDetails,
  SocialLink,
  ContactDetail,
  ContactDetailText,
  LoginLink,
  SuccessText,
  ContactDetailLink,
  AlternativeSigninTitle,
  SocialMediaTitle,
  SocialMedia,
  Form,
  Details,
  LogoWrapper,
  DrivenLogo,
  DrivenLogoSubtitle,
  LogoTitle,
  ActionButtons,
  RegisterButton,
  LoginButton,
  ErrorIcon,
  FormError,
  FormIcon,
  FormLabel,
  FormTextInput,
  Wrapper,
  Column,
  LoginFormWrapper,
  LoginFormContainer,
  Title,
  SubTitle,
  ResetButton,
} from './Styles';
import { Link, Switch, Route, useLocation } from 'react-router-dom';

const LoginWithGoogle = styled(GoogleLogin)`
  background-color: rgb(56, 56, 56) !important;
  width: 100%;
  border-radius: 6px !important;
  & > div {
    padding: 12px !important;
    margin: 2px;
    border-radius: 6px !important;
  }

  & > span {
    display: flex;
    flex: 1 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
`;
const CLIENT_ID = '810104162547-719ljvdkfko6hmtaau5rv7chhqta0tte.apps.googleusercontent.com';

type ConfirmResetFormProps = {
  onConfirmReset: (
    data: { password: string; resetToken: string },
    setError: (name: 'password' | 'password_confirm', option: ErrorOption) => void,
    setComplete: (v: boolean) => void,
    setSubmitted: (v: boolean) => void,
  ) => void;
};
const ConfirmResetForm: React.FC<ConfirmResetFormProps> = ({ onConfirmReset }) => {
  const { register, handleSubmit, errors, setError } = useForm<{ password: string; password_confirm: string }>();
  const [wasSubmitted, setSubmitted] = useState(false);
  const [isComplete, setComplete] = useState(true);
  const location = useLocation();
  const params = QueryString.parse(location.search);
  return (
    <>
      <Title>Reset password</Title>
      <Form
        onSubmit={handleSubmit(data => {
          if (data.password !== data.password_confirm) {
            setError('password', { type: 'not_same', message: 'Passwords must match!' });
          } else {
            if (params.resetToken && typeof params.resetToken === 'string') {
              onConfirmReset(
                { password: data.password, resetToken: params.resetToken },
                setError,
                setComplete,
                setSubmitted,
              );
            } else {
              setError('password', { type: 'reset_token', message: 'No valid reset token' });
            }
          }
        })}
      >
        {errors.password && (
          <FormError>
            <ErrorIcon>
              <Cross width={14} height={14} />
            </ErrorIcon>
            <FormErrorText>{errors.password.type}</FormErrorText>
          </FormError>
        )}
        {wasSubmitted ? (
          <>
            <SuccessText>Your password has been updated!</SuccessText>
            <Link to="/login">
              <LoginLink>Back to login</LoginLink>
            </Link>
          </>
        ) : (
          <>
            <FormTextInput
              type="password"
              id="password"
              name="password"
              placeholder="password"
              ref={register({ required: 'Some fields are empty' })}
            />
            <FormTextInput
              type="password"
              id="password_confirm"
              name="password_confirm"
              placeholder="password (confirm)"
              ref={register({ required: 'Some fields are empty' })}
            />
            <ActionButtons>
              <LoginButton type="submit" disabled={!isComplete}>
                Update password
              </LoginButton>
              <ResetButton to="/login">Cancel</ResetButton>
            </ActionButtons>
          </>
        )}
      </Form>
    </>
  );
};

type LoginFormProps = {
  onGoogleLogin: (googleData: any) => void;
  onSubmit: (
    data: LoginFormData,
    setComplete: (val: boolean) => void,
    setError: (name: 'username' | 'password', error: ErrorOption) => void,
  ) => void;
};

type PasswordResetFormProps = {
  onResetPassword: (
    data: { email: string },
    setError: (name: 'email', error: ErrorOption) => void,
    onComplete: (v: boolean) => void,
    onSubmit: (v: boolean) => void,
  ) => void;
};

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ onResetPassword }) => {
  const { register, handleSubmit, errors, setError } = useForm<{ email: string }>();
  const [wasSubmitted, setSubmitted] = useState(false);
  const [isComplete, setComplete] = useState(true);
  return (
    <>
      <Title>Reset password</Title>
      <Form onSubmit={handleSubmit(data => onResetPassword(data, setError, setComplete, setSubmitted))}>
        {errors.email && (
          <FormError>
            <ErrorIcon>
              <Cross width={14} height={14} />
            </ErrorIcon>
            <FormErrorText>An email is required.</FormErrorText>
          </FormError>
        )}
        {wasSubmitted ? (
          <SuccessText>You should receive a confirmation email shortly.</SuccessText>
        ) : (
          <>
            <FormTextInput
              type="text"
              id="email"
              name="email"
              placeholder="email"
              ref={register({ required: 'Some fields are empty' })}
            />
            <ActionButtons>
              <LoginButton type="submit" disabled={!isComplete}>
                Reset password
              </LoginButton>
              <ResetButton to="/login">Cancel</ResetButton>
            </ActionButtons>
          </>
        )}
      </Form>
    </>
  );
};

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, onGoogleLogin }) => {
  const { register, handleSubmit, errors, setError, formState } = useForm<LoginFormData>();
  const [isComplete, setComplete] = useState(true);
  const loginSubmit = (data: LoginFormData) => {
    setComplete(false);
    onSubmit(data, setComplete, setError);
  };
  const handleLogin = async (googleData: any) => {
    onGoogleLogin(googleData);
  };
  return (
    <>
      <Title>client login</Title>
      <SubTitle>Welcome Back!</SubTitle>
      <Form onSubmit={handleSubmit(loginSubmit)}>
        {(errors.username || errors.password) && (
          <FormError>
            <ErrorIcon>
              <Cross width={14} height={14} />
            </ErrorIcon>
            <FormErrorText>{errors.username ? errors.username.message : errors.password?.message}</FormErrorText>
          </FormError>
        )}
        <FormTextInput
          type="text"
          id="username"
          name="username"
          placeholder="username/email"
          ref={register({ required: 'Some fields are empty' })}
        />
        <FormTextInput
          placeholder="password"
          type="password"
          id="password"
          name="password"
          ref={register({ required: 'Some fields are empty' })}
        />
        <ActionButtons>
          <LoginButton type="submit" disabled={!isComplete}>
            Login
          </LoginButton>
          <ResetButton to="/login/reset_password">Forgot password?</ResetButton>
        </ActionButtons>
      </Form>
      <AlternativeSigninTitle>
        <span>or</span>
      </AlternativeSigninTitle>
      <LoginWithGoogle
        clientId={CLIENT_ID}
        buttonText="Login with Google"
        onSuccess={handleLogin}
        onFailure={handleLogin}
        cookiePolicy={'single_host_origin'}
      />
    </>
  );
};

const Login = ({ onSubmit, onResetPassword, onConfirmReset, onGoogleLogin }: LoginProps) => {
  const [isLoading, setLoading] = useState(true);
  const [settings, setSettings] = useState<any>({});
  useEffect(() => {
    fetch('/settings')
      .then(data => {
        return data.json();
      })
      .then(data => {
        setSettings(data);
        setLoading(false);
      });
  }, []);
  return (
    <Wrapper>
      <Column background="#f59320" borderRadius>
        <LoginFormWrapper>
          <LoginFormContainer>
            <Switch>
              <Route exact path="/login/reset_password">
                <PasswordResetForm onResetPassword={onResetPassword} />
              </Route>
              <Route exact path="/login/confirm_reset">
                <ConfirmResetForm onConfirmReset={onConfirmReset} />
              </Route>
              <Route exact path="/login">
                <LoginForm onSubmit={onSubmit} onGoogleLogin={onGoogleLogin} />
              </Route>
            </Switch>
            {!isLoading && (
              <>
                <SocialMediaTitle>
                  <span>follow us</span>
                </SocialMediaTitle>
                <SocialMedia>
                  <SocialLink target="_blank" href={settings.social.facebook}>
                    <SocialFacebook width={90} height={24} />
                  </SocialLink>
                  <SocialLink target="_blank" href={settings.social.instagram}>
                    <SocialInstagram width={90} height={24} />
                  </SocialLink>
                  <SocialLink target="_blank" href={settings.social.linkedin}>
                    <SocialLinkedIn width={90} height={24} />
                  </SocialLink>
                  <SocialLink target="_blank" href={settings.social.twitter}>
                    <SocialTwitter width={90} height={24} />
                  </SocialLink>
                </SocialMedia>
              </>
            )}
          </LoginFormContainer>
        </LoginFormWrapper>
      </Column>
      <Column borderRadius={false} background="#fff">
        <Details>
          <DrivenLogo src="/driven-logo-full.png" />
          <DrivenLogoSubtitle>website project tracker</DrivenLogoSubtitle>
          {!isLoading && (
            <ContactDetails>
              <ContactDetail>
                <Envelope width={40} height={30} />
                <ContactDetailText>
                  <ContactDetailLink>{settings.contact.email}</ContactDetailLink>
                </ContactDetailText>
              </ContactDetail>
              {settings.contact.addresses.map((address: any) => (
                <ContactDetail>
                  <MapMarkerAlt width={40} height={40} />
                  <ContactDetailText>
                    <ContactDetailLink href={address.locationUrl}>{address.location}</ContactDetailLink>
                    <ContactDetailLink href={address.phoneUrl}>{address.phone}</ContactDetailLink>
                  </ContactDetailText>
                </ContactDetail>
              ))}
            </ContactDetails>
          )}
        </Details>
      </Column>
    </Wrapper>
  );
};

export default Login;
