import React, { useState } from 'react';
import styled from 'styled-components';
import { mixin } from 'shared/utils/styles';
import { Trash } from 'shared/icons';
import AddCard from 'shared/components/AddCard';

const Container = styled.div`
  width: 320px;
  margin: 0 10px;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  background: #383838;
  color: #fff;

  border-radius: 28px;
  box-shadow: 0px 0px 4px #0000004d;

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  white-space: normal;
`;

const Header = styled.div`
  margin-top: 8px;
  margin-left: 16px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  position: relative;
  min-height: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const HeaderName = styled.input`
  font-size: 20px;
  font-weight: 300;
  border: none;
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
  background: transparent;
  border-radius: 3px;
  box-shadow: none;
  font-weight: 600;
  margin: -4px 0;
  padding: 4px 8px;

  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  flex-direction: column;
  text-align: start;

  color: #fff;
`;

export const Tasks = styled.div`
  margin: 0 4px;
  padding: 0 4px;
  flex: 1 1 auto;
  min-height: 45px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ListIcon = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: rgba(0, 0, 0, 0.55);
  }
  &:hover svg {
    fill: rgba(0, 0, 0, 1);
  }
`;

type ListProps = {
  name: string;
  editable?: boolean;
  onDelete?: () => void;
  onCreateProject?: (name: string) => void;
  handleProps?: any;
  wrapperProps?: any;
  innerRef?: any;
};

const List: React.FC<ListProps> = ({
  name: initialName,
  editable,
  children,
  onDelete,
  onCreateProject,
  handleProps,
  wrapperProps,
  innerRef,
}) => {
  return (
    <Container {...wrapperProps} ref={innerRef}>
      <Wrapper>
        <Header {...handleProps}>
          <HeaderName readOnly value={initialName} />
        </Header>
        {children}
        {editable && onCreateProject && <AddCard label="Add another project" onSave={n => onCreateProject(n)} />}
      </Wrapper>
    </Container>
  );
};

export default List;
