import React from 'react';
import Icon, { IconProps } from './Icon';

const Internal: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 998.529 998.529">
      <path d="M998.53 0v952.727l-372.43-368.1L998.53 0z" />
      <path d="M0 998.53h952.727l-368.1-372.43L0 998.53z" />
    </Icon>
  );
};

export default Internal;
