import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { mixin } from 'shared/utils/styles';
import Button from 'shared/components/Button';

export const Container = styled.div`
  width: 100%;
  flex: 1 1 auto;
  height: 100%;
  display: inline-block;
  background: #f4f4f4;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
`;

export const Wrapper = styled.div<{ editorOpen: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin: 0 8px;
  margin-bottom: 4px;
  transition: background 85ms ease-in, opacity 40ms ease-in, border-color 85ms ease-in;
  flex: 1 1 auto;

  ${props =>
    !props.editorOpen &&
    css`
      border-radius: 12px;
      &:hover {
        background-color: hsla(0, 0%, 100%, 0.45);
      }
    `}

  ${props =>
    props.editorOpen &&
    css`
      height: auto;
      min-height: 32px;
      padding: 8px;
      transition: background 85ms ease-in, opacity 40ms ease-in, border-color 85ms ease-in;
    `}
`;

export const AddCardButton = styled(Button)`
  padding: 6px 12px;
`;

export const Placeholder = styled.span`
  color: rgba(0, 0, 0);
  display: flex;
  font-size: 18px;
  align-items: center;
  padding: 6px 8px;
  transition: color 85ms ease-in;
  svg {
    fill: #000;
  }
`;

export const AddIconWrapper = styled.div`
  color: #fff;
  margin-right: 6px;
`;

export const ListNameEditorWrapper = styled.div`
  display: flex;
`;
export const ListNameEditor = styled(TextareaAutosize)`
  background-color: #fff;
  border: none;
  box-shadow: 0px 2px 2px #00000026;
  transition: margin 85ms ease-in, background 85ms ease-in;
  line-height: 20px;
  padding: 8px 12px;
  border-radius: 18px;

  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  height: 54px;
  width: 100%;

  border: none;
  margin-bottom: 4px;
  max-height: 162px;
  min-height: 54px;
  font-size: 18px;
  line-height: 20px;

  color: #000;
`;

export const ListAddControls = styled.div`
  height: 32px;
  transition: margin 85ms ease-in, height 85ms ease-in;
  overflow: hidden;
  margin: 4px 0 0;
`;

export const CancelAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
`;
