import React from 'react';
import Icon, { IconProps } from './Icon';

const Tasks: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 1000 1000">
      <g>
        <path
          d="M292.15,181.78V97.53c0-2,1.62-3.63,3.63-3.63l188.14,0c2,0,3.63,1.62,3.63,3.63v84.25
		c0,2-1.62,3.63-3.63,3.63H295.78C293.78,185.41,292.15,183.78,292.15,181.78z"
        />
        <path
          d="M384.49,547.32c19.57,0,37.96,7.62,51.8,21.46l97.84,97.84l164.06-164.06V224.24
		c0-46.71-37.87-84.58-84.58-84.58H508.22v16.15c0,27.72-22.55,50.28-50.28,50.28H321.75c-27.72,0-50.28-22.56-50.28-50.28v-16.15
		h-88.97c-46.71,0-84.58,37.87-84.58,84.58V821.2c0,46.71,37.87,84.58,84.58,84.58h431.1c46.72,0,84.58-37.87,84.58-84.58V709.77
		L569.22,838.74c-9.38,9.37-21.84,14.53-35.09,14.53c-13.25,0-25.72-5.16-35.09-14.53L332.68,672.39
		c-28.56-28.56-28.56-75.04,0-103.6C346.52,554.94,364.92,547.32,384.49,547.32z M161.89,309.01c0-17.84,14.47-32.31,32.31-32.31
		h407.71c17.84,0,32.31,14.47,32.31,32.31v0c0,17.84-14.46,32.31-32.31,32.31H194.2C176.36,341.32,161.89,326.85,161.89,309.01
		L161.89,309.01z M194.2,379.01h227.7c17.84,0,32.31,14.46,32.31,32.31s-14.47,32.31-32.31,32.31H194.2
		c-17.84,0-32.31-14.46-32.31-32.31S176.36,379.01,194.2,379.01z M303.44,545.94H194.2c-17.84,0-32.31-14.47-32.31-32.31
		c0-17.84,14.47-32.31,32.31-32.31h109.23c17.84,0,32.31,14.47,32.31,32.31C335.75,531.48,321.28,545.94,303.44,545.94z"
        />
        <path
          d="M835.4,414.08L544.85,704.63c-5.92,5.92-15.53,5.92-21.45,0L411.92,593.15c-15.15-15.15-39.72-15.15-54.87,0
		l0,0c-15.15,15.15-15.15,39.72,0,54.87l122.21,122.21l44.14,44.14c5.92,5.92,15.53,5.92,21.45,0L589,770.22l301.28-301.28
		c15.15-15.15,15.15-39.72,0-54.87l0,0C875.12,398.93,850.56,398.93,835.4,414.08z"
        />
      </g>
    </Icon>
  );
};

export default Tasks;
