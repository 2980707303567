import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { mixin } from 'shared/utils/styles';
import { Trash } from 'shared/icons';
import AddCard from 'shared/components/AddCard';

const Container = styled.div`
  width: 320px;
  margin: 0 10px;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
`;
const Wrapper = styled.div`
  background: #383838;
  color: #fff;

  border-radius: 28px;
  box-shadow: 0px 0px 4px #0000004d;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  white-space: normal;
`;

const Header = styled.div`
  margin-top: 8px;
  margin-left: 16px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  position: relative;
  min-height: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const HeaderName = styled.input`
  font-size: 20px;
  font-weight: 300;
  border: none;
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
  background: transparent;
  border-radius: 3px;
  box-shadow: none;
  font-weight: 600;
  margin: -4px 0;
  padding: 4px 8px;

  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  flex-direction: column;
  text-align: start;

  color: #fff;
`;

export const Tasks = styled.div<{ editable: boolean }>`
  padding: 24px 12px;
  background: #f4f4f4;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  ${p =>
    !p.editable &&
    css`
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
    `}
  padding-bottom: 0;
  min-height: 80px;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ListIcon = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 24px;
  svg {
    fill: #fff;
  }
  &:hover svg {
    fill: #fff;
  }
`;

type ListProps = {
  name: string;
  editable?: boolean;
  onDelete?: ($target: React.RefObject<HTMLDivElement>) => void;
  onCreateTask?: (name: string) => void;
  handleProps?: any;
  wrapperProps?: any;
  innerRef?: any;
  onChangeName?: (name: string) => void;
};

const List: React.FC<ListProps> = ({
  name: initialName,
  children,
  editable = false,
  onDelete,
  onCreateTask,
  handleProps,
  wrapperProps,
  onChangeName,
  innerRef,
}) => {
  const [name, setName] = useState(initialName);
  const $header = useRef<HTMLInputElement>(null);
  const $deleteBtn = useRef<HTMLDivElement>(null);
  const saveName = () => {
    if (initialName !== name && onChangeName) {
      onChangeName(name);
    }
  };
  return (
    <Container {...wrapperProps} ref={innerRef}>
      <Wrapper>
        <Header {...handleProps}>
          <HeaderName
            readOnly={!editable}
            value={name}
            ref={$header}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                saveName();
              }
            }}
            onBlur={() => saveName()}
            onChange={e => setName(e.currentTarget.value)}
          />
          {onDelete && (
            <ListIcon ref={$deleteBtn} onClick={() => onDelete($deleteBtn)}>
              <Trash width={12} height={12} />
            </ListIcon>
          )}
        </Header>
        {children}
        {editable && onCreateTask && <AddCard onSave={n => onCreateTask(n)} />}
      </Wrapper>
    </Container>
  );
};

export default List;
