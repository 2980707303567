import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  min-width: 1400px;
  flex-grow: 1;
`;

export const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ProjectTitle = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin: 8px 0;
  text-transform: uppercase;
`;

export const BoardContent = styled.div`
  display: flex;
  user-select: none;
  white-space: nowrap;
  overflow: auto hidden;
  padding-bottom: 4px;
  inset: 0px;
  width: 100vw;
  height: 100%;
`;
