import styled, { css } from 'styled-components';
import Button from 'shared/components/Button';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background: #fff;
`;

export const Column = styled.div<{ background: string; borderRadius: boolean }>`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.background};
  ${p =>
    p.borderRadius &&
    css`
      border-top-right-radius: 65px;
      border-bottom-right-radius: 65px;
    `}
`;

export const LoginFormWrapper = styled.div``;

export const LoginFormContainer = styled.div`
  padding: 2rem;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 54px;
  line-height: 54px;
  margin-bottom: 8px;
  text-align: center;
`;

export const SubTitle = styled.h2`
  color: #fff;
  font-size: 24px;
  margin-bottom: 14px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-align: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const FormLabel = styled.label`
  color: #000;
  font-size: 12px;
  position: relative;
  margin-top: 14px;
`;

export const FormTextInput = styled.input`
  width: 100%;
  background: rgba(247, 247, 247, 0.3);
  border: none;
  margin-top: 20px;
  padding: 6px 12px;
  font-size: 18px;
  color: #fff;
  border-radius: 15px;
  &:placeholder {
    color: #fff;
    opacity: 1;
  }
`;

export const FormIcon = styled.div`
  top: 30px;
  left: 16px;
  position: absolute;
  & svg {
    fill: #000;
  }
`;

export const FormError = styled.div`
  font-size: 0.875rem;
  background: rgba(192, 47, 29, 0.4);
  color: rgba(192, 47, 29, 1);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

export const FormErrorText = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const LoginButton = styled(Button)`
  border-radius: 40px;
  background: #383838;
`;

export const ErrorIcon = styled.div`
  background: rgb(192, 47, 29);
  border-radius: 50%;
  margin-right: 4px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: rgba(255, 255, 255, 1);
  }
`;

export const ResetButton = styled(Link)`
  font-size: 22px;
  color: #fff;
  background: none;
  border: none;
`;

export const ActionButtons = styled.div`
  margin-top: 17.5px;
  display: flex;
  justify-content: space-between;
`;

export const RegisterButton = styled(Button)``;

export const LogoTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-left: 12px;
  transition: visibility, opacity, transform 0.25s ease;
  color: #7367f0;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 24px;
  color: rgb(222, 235, 255);
  border-bottom: 1px solid rgba(65, 69, 97, 0.65);
`;

export const DrivenLogo = styled.img`
  padding-bottom: 24px;
  border-bottom: 3px solid #f49221;
  max-width: 540px;
`;

export const DrivenLogoSubtitle = styled.span`
  padding-top: 20px;
  font-size: 42px;
  font-weight: 200;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const AlternativeSigninTitle = styled.h3`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
  line-height: 0.1em;
  margin: 10px 0 20px;
  span {
    padding: 0 10px;
    color: #fff;
    background: #f59320;
  }
`;

export const SocialMediaTitle = styled.h3`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
  line-height: 0.1em;
  margin: 25px 0 20px;
  span {
    padding: 0 10px;
    color: #fff;
    background: #f59320;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #fff;
    margin-right: 24px;
  }
  svg:last-child {
    margin-right: 0;
  }
`;

export const ContactDetails = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  svg {
    fill: #f49221;
    margin-right: 8px;
  }
`;

export const ContactDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const ContactDetailText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactDetailLink = styled.a`
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  color: #383838;
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLink = styled.a`
  padding: 0 8px;
`;

export const LoginLink = styled(Button)`
  padding: 8px 12px;
`;

export const SuccessText = styled.span`
  font-size: 20px;
  color: #fff;
`;
