import React from 'react';
import Icon, { IconProps } from './Icon';

const SocialTwitter: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 366.18284 67.76209">
      <path
        d="M417.62781,24.565c-.54669.89349-1.09335,1.787-1.59719,2.61044,1.42632-.24593,2.96-.58242,4.51192-.7362.49705-.04925,1.04239.38482,1.56564.59775-.33826.46431-.57812,1.11416-1.03519,1.35444a20.25016,20.25016,0,0,1-3.06394,1.16492c-1.07891.35842-2.1769.66008-3.26653.98656l.02116.38154a2.76079,2.76079,0,0,0,.747.22725c6.64377.05073,11.39138,3.53343,15.28584,8.46029a20.25515,20.25515,0,0,1,3.93346,8.36575,2.642,2.642,0,0,0,2.41589,2.30007,25.787,25.787,0,0,0,8.96309-.21723c-1.89716,3.64133-5.68344,3.79718-9.074,4.84807a20.50591,20.50591,0,0,0,9.76063,1.24605,15.30678,15.30678,0,0,1-1.23827,1.19936,15.72964,15.72964,0,0,1-8.56181,2.87424,1.86961,1.86961,0,0,0-1.94668,1.50508c-3.3456,11.10131-10.86126,18.70193-20.80241,24.1338a53.25408,53.25408,0,0,1-21.835,6.287c-13.53149,1.0129-25.65643-2.38887-36.12854-11.112-1.728-1.43938-3.2548-3.12053-4.848-4.71622a4.78555,4.78555,0,0,1-.88454-1.38422c5.33962,3.55126,11.13,5.49426,17.56631,5.03073a25.94041,25.94041,0,0,0,16.874-7.7139,16.9632,16.9632,0,0,1-2.86442-.6279,9.52217,9.52217,0,0,1-2.94994-1.69462,3.15279,3.15279,0,0,1,.18178-4.45317,25.21861,25.21861,0,0,1,2.069-1.73423c-.689-.08377-1.17406-.15461-1.66146-.2001-3.62433-.33828-6.73287-1.60245-8.92626-4.68809-.68339-.96138-.73869-1.68945.36391-2.195,1.46613-.67228,2.98567-1.22907,4.75182-1.94469-.68356-.24189-1.26831-.44044-1.847-.655-3.44351-1.27668-6.32629-3.1903-7.70271-6.78836-.70419-1.84069-.48062-2.20581,1.49966-2.517.84418-.13266,1.69171-.24443,2.5351-.38164a5.07855,5.07855,0,0,0,.75349-.29766c-4.28435-2.8123-7.71274-6.05745-7.73958-11.86259,1.4246.4936,2.537.78877,3.57328,1.2526,7.1839,3.21559,14.40036,6.36617,21.49464,9.7686,2.044.98028,3.73246,2.704,5.56064,4.11679.61468.475,1.15725,1.04242,1.9674,1.78167.3777-.79775.67984-1.35276.91035-1.93584,2.04062-5.16179,4.1707-10.30077,7.551-14.75375a70.4569,70.4569,0,0,1,5.59931-5.97343,4.94836,4.94836,0,0,1,1.04981-.60215l-.14163,1.28892L417.261,24.565Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M185.57839,61.64345a85.63252,85.63252,0,0,1-.59591,9.81034c-1.31439,7.65647-8.15073,13.62892-15.927,14.4804a17.96049,17.96049,0,0,1-13.43255-3.545,1.8934,1.8934,0,0,0-2.71351-.01023c-5.59956,4.2073-11.788,4.79337-18.20743,2.36891-5.98363-2.25985-11.43339-8.60775-11.546-16.18408-.06966-4.68551-.06222-9.373-.01842-14.059a5.89067,5.89067,0,0,1,6.06659-6.16135,6.02266,6.02266,0,0,1,6.26886,6.20967c.06772,4.32033-.00558,8.64267.03542,12.96365a6.26816,6.26816,0,1,0,12.53588-.01972c.07094-4.32023-.0142-8.64294.05068-12.96334a5.92056,5.92056,0,0,1,3.7834-5.74038,6.07945,6.07945,0,0,1,8.559,5.34334c.098,4.3192.00137,8.64243.04348,12.96337a6.35,6.35,0,0,0,3.16209,5.81482,6.18283,6.18283,0,0,0,9.391-5.18114c.08676-4.25877.0235-8.52058.02629-12.781a6.37994,6.37994,0,0,1,1.98565-4.91746,5.87035,5.87035,0,0,1,6.61257-1.24713,5.55107,5.55107,0,0,1,3.7334,5.361c.04732,2.49449.01025,4.99056.01025,7.48594Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M80.63154,53.11259c0-4.62462-.04375-9.2498.01611-13.87365.037-2.85687,2.08388-5.63361,4.643-6.08782,3.25681-.578,5.61614-.3093,7.613,2.88978a6.24989,6.24989,0,0,1,.7992,2.9203c.11328,2.42886.03948,4.86633.043,7.30034.00409,2.85394.005,2.85632,2.78442,2.85768,5.01155.00243,10.02335-.02736,15.03453.01588,2.158.01862,4.23795.27262,5.847,2.0533a5.83814,5.83814,0,0,1,1.18037,6.43913,5.50219,5.50219,0,0,1-5.35946,3.74062c-5.68341.04953-11.36753.01479-17.05135.022-2.39064.003-2.45282.04288-2.42211,2.47723a19.139,19.139,0,0,0,.29,3.62306,6.43419,6.43419,0,0,0,6.6491,5.36226c4.09471.01976,8.18969-.011,12.28434.015A8.08024,8.08024,0,0,1,117.8463,74.192a6.325,6.325,0,0,1,2.49972,6.95179,5.97928,5.97928,0,0,1-4.9709,4.77254,7.41267,7.41267,0,0,1-1.62468.25318c-5.24933-.07333-10.51634.06423-15.74313-.3258-8.35611-.62355-15.88725-7.69024-16.9532-15.97293-.5324-4.13688-.32034-8.36973-.42442-12.55968-.03474-1.3987-.00548-2.799-.00548-4.19855Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M303.55947,70.58579c-3.29953,0-6.59955-.02964-9.8981.027-.60323.01035-1.66688.30692-1.70588.58451a2.794,2.794,0,0,0,.57232,1.97593,6.77677,6.77677,0,0,0,4.88848,2.18436c2.55138.20348,5.11619.26586,7.67669.32275,2.32079.05158,4.64428-.02087,6.96524.02615a4.78636,4.78636,0,0,1,5.03012,4.31786c.32339,3.08091-1.072,5.19883-3.9121,5.91927a4.96339,4.96339,0,0,1-1.25692.22388c-5.67529-.08156-11.37074.08291-17.02051-.34563C286.60776,85.193,278.74809,77.54772,278.199,68.861c-.39064-6.17955.99106-11.57267,5.55554-15.922,3.05253-2.90863,6.89552-4.12469,10.9822-4.49429a27.51355,27.51355,0,0,1,12.45814,1.39623c5.84047,2.22709,9.64569,6.30949,11.10725,12.37241a13.66578,13.66578,0,0,1,.112,4.70683c-.25329,2.14636-1.98776,3.53567-4.40754,3.59777-3.48022.08931-6.96447.0228-10.447.0228Zm1.66728-8.4738c-.39624-2.94357-3.42049-4.62591-7.33241-4.34589-3.552.25426-5.784,1.86892-5.79368,4.34589Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M208.61476,53.52136c.00061-4.68465-.01645-9.36938.00671-14.05391a6.57925,6.57925,0,0,1,4.76827-6.37836c3.437-.77988,6.36751.52867,7.78988,3.58988a6.358,6.358,0,0,1,.51733,2.45979c.06578,2.55361.01729,5.11.02949,7.66516.01083,2.26629.05682,2.30721,2.40288,2.31408,2.81084.00822,5.62368-.05834,8.43182.02933,1.82245.0569,3.644.211,5.09582,1.62729a6.03946,6.03946,0,0,1,1.65609,6.82069,5.9675,5.9675,0,0,1-5.72484,3.78515c-3.17727.02634-6.35494.00058-9.5324.0105-2.277.00711-2.35207.056-2.31755,2.39367a21.1408,21.1408,0,0,0,.31389,3.983,6.43506,6.43506,0,0,0,6.31563,5.09224,70.53307,70.53307,0,0,1,7.66723.33428c3.1777.44311,5.12891,3.07236,5.12643,6.34591-.00249,3.282-1.93117,5.96389-5.14055,6.33367-6.62806.76369-13.24088.83672-18.98165-3.3884-5.38281-3.96166-8.38705-9.23044-8.42105-16.00519C208.59652,62.16067,208.61414,57.841,208.61476,53.52136Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M243.805,53.496c0-4.74437-.03992-9.4892.0143-14.233a6.24844,6.24844,0,0,1,4.75462-6.173c3.42815-.71515,6.30644.5041,7.7174,3.42035a7.099,7.099,0,0,1,.61364,2.80289c.08241,2.613.01317,5.2303.03973,7.84555.01844,1.81539.1564,1.943,2.02,1.95525,2.93226.01923,5.866-.04354,8.79668.02785,2.10335.05123,4.12159.40263,5.61895,2.1631a5.88762,5.88762,0,0,1,1.09695,6.30365,5.59075,5.59075,0,0,1-5.3609,3.75154c-3.41851.095-6.8417.01182-10.26266.03668-1.72709.01255-1.87127.17354-1.91,1.88806-.02336,1.03338-.04684,2.07069.01391,3.10132a6.66506,6.66506,0,0,0,6.8091,6.46747c1.95464.02349,3.91578-.08138,5.86333.04017,5.63231.3515,8.59056,5.74918,5.69,10.30691a6.31928,6.31928,0,0,1-5.58961,2.94206,69.74384,69.74384,0,0,1-9.68084-.39025c-8.60834-1.16929-15.95375-9.47935-16.20248-18.2069-.13336-4.67969-.0237-9.36625-.0237-14.04971Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M322.47152,73.0977c.1984-3.26729.07039-6.59441.661-9.78985,1.36666-7.39412,8.39867-13.3874,15.95825-13.87432,3.40473-.2193,6.82361-.26236,10.23687-.29295a6.65164,6.65164,0,0,1,5.31563,1.96031,6.16568,6.16568,0,0,1,1.3314,6.5679,5.78211,5.78211,0,0,1-5.41347,3.70041c-2.86831.06134-5.73894.00916-8.60849.02133-4.53394.01923-7.13692,2.55812-7.20458,7.12186-.05765,3.88973.02367,7.78155-.03734,11.67119a5.74714,5.74714,0,0,1-4.99325,5.84535,5.99709,5.99709,0,0,1-7.02859-5.44422c-.08655-2.48934-.01686-4.98406-.01686-7.47638Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M203.68982,67.29621c.00174,3.95486.04728,7.91038-.01146,11.86438a6.68757,6.68757,0,0,1-5.80648,6.9103,6.54152,6.54152,0,0,1-7.19638-4.84734,19.72818,19.72818,0,0,1-.36142-3.62039q-.04965-10.49535-.00508-20.99109a15.55029,15.55029,0,0,1,.25057-2.715,6.386,6.386,0,0,1,7.1694-5.53655,6.48,6.48,0,0,1,5.93909,6.524C203.75405,59.0201,203.68809,63.15883,203.68982,67.29621Z"
        transform="translate(-80.61269 -24.56503)"
      />
      <path
        d="M197.01172,31.50418A6.34935,6.34935,0,0,1,203.656,37.9816a6.59964,6.59964,0,1,1-13.19806-.1489A6.24475,6.24475,0,0,1,197.01172,31.50418Z"
        transform="translate(-80.61269 -24.56503)"
      />
    </Icon>
  );
};

export default SocialTwitter;
