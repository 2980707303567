import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useCurrentUser } from 'App/context';
import { useMeQuery } from 'shared/generated/graphql';
import { NavLogo, Menu, AngleRight, Bell } from 'shared/icons';
import { Link, useHistory } from 'react-router-dom';
import useOnOutsideClick from 'shared/hooks/onOutsideClick';
import cache from 'App/cache';
import Button from 'shared/components/Button';
import TaskAssignee from 'shared/components/TaskAssignee';

const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

const MenuItem = styled.li<{ inactive: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 35px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
  ${props =>
    props.inactive &&
    css`
      cursor: default;
      pointer-events: none;
      opacity: 0.6;
    `}
`;

const MenuItemName = styled.span`
  display: flex;
  align-items: center;
  flex: 1 0;
  position: relative;
`;

const MenuItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 6px;
`;

const MenuButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 6px;
`;

const NotificationButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 6px;
  position: relative;
`;
const NotificationBadge = styled.div<{ drawer?: boolean }>`
  position: absolute;
  background: #ff2c2c;
  border-radius: 50%;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  ${props =>
    props.drawer
      ? css`
          top: 1;
          left: -20px;
        `
      : css`
          top: 0;
          left: 0;
        `}
`;

const NotificationBadgeText = styled.span`
  font-size: 14px;
  color: #fff;
  margin-bottom: -2px;
  margin-right: -2px;
`;

const NavControls = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover ${MenuButton} {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const Container = styled.div`
  width: 100%;
`;

const Inlay = styled.div<{ height: number }>`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: ${p => p.height}px;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
  background: transparent linear-gradient(135deg, #f59320 0%, #f57a20 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
`;

const InnerContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 45px;
  margin-top: 32px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 36px;
  svg {
    fill: #fff;
  }
`;

const DrivenDigitalLogo = styled.div`
  width: 126px;
`;

const PageTitle = styled.h1`
  font-size: 28px;
  line-height: 40px;
  font-weight: normal;
  color: #fff;
`;

const NavCenter = styled.div`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;

const UserAvatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

const Username = styled.span`
  padding-left: 4px;
  font-weight: 300;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  padding-top: 4px;
`;

const MenuUsername = styled.span`
  font-size: 28px;
  font-weight: 400;
  color: #000;
  padding-left: 35px;
`;

const MenuContainer = styled.div<{ open: boolean }>`
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  ${props => props.open && 'width: 320px;'}
  transition: all 0.2s ease;
`;

const MenuContent = styled.div`
  height: 100%;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 35px 0;
`;
const SaveButton = styled(Button)`
  padding: 6px 12px;
  margin-left: 4px;
  background: #000;
  color: #fff;
`;
const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PageTitleInput = styled.input`
  width: 100%;

  border: none;
  background: #383838;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 18px;
  color: #fff;
`;
type EditableTitleProps = {
  title: string;
  onChangeTitle: (title: string) => void;
};

const EditableTitle: React.FC<EditableTitleProps> = ({ title: initialTitle, onChangeTitle }) => {
  const [title, setTitle] = useState(initialTitle);
  const [edit, setEdit] = useState(false);
  const $container = useRef<HTMLDivElement>(null);
  useOnOutsideClick($container, edit, () => setEdit(false), null);
  const onSave = () => {
    setEdit(false);
    onChangeTitle(title);
  };
  return (
    <EditContainer ref={$container}>
      {edit ? (
        <>
          <PageTitleInput
            value={title}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                onSave();
              }
            }}
            onChange={e => setTitle(e.currentTarget.value)}
          />
          <SaveButton onClick={onSave}>Save</SaveButton>
        </>
      ) : (
        <PageTitle onClick={() => setEdit(true)}>{title}</PageTitle>
      )}
    </EditContainer>
  );
};

type NavbarProps = {
  title?: string;
  inlayHeight?: number;
  onTitleChange?: (title: string) => void;
};

const Navbar: React.FC<NavbarProps> = ({ title, onTitleChange, inlayHeight = 175 }) => {
  const { data, loading } = useMeQuery();
  const [menuOpen, setMenuOpen] = useState(false);
  const $container = useRef<HTMLDivElement>(null);
  const { setUser, user } = useCurrentUser();
  const history = useHistory();

  const onLogout = () => {
    fetch('/auth/logout', {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { status } = x;
      if (status === 200) {
        cache.reset();
        history.replace('/login');
        setUser(null);
      }
    });
  };
  useOnOutsideClick($container, menuOpen, () => setMenuOpen(false), null);
  if (data && user) {
    const navItems = [
      { inactive: false, label: 'your account', onClick: () => history.push('/profile') },
      { inactive: false, label: 'inbox', onClick: () => history.push('/profile/inbox') },
      { inactive: false, disabled: !user.isAdmin(), label: 'users', onClick: () => history.push('/users') },
      { inactive: false, label: 'projects', onClick: () => history.push('/') },
      { inactive: true, label: 'payment', onClick: () => {} },
      { inactive: true, label: 'privacy policy', onClick: () => {} },
      { inactive: false, label: 'log out', onClick: onLogout },
    ];
    return (
      <>
        <Container>
          <Inlay height={inlayHeight} />
          <InnerContainer>
            {title && (
              <NavCenter>
                {user.isAdmin() && onTitleChange ? (
                  <EditableTitle title={title} onChangeTitle={onTitleChange} />
                ) : (
                  <PageTitle>{title}</PageTitle>
                )}
              </NavCenter>
            )}

            <Link to="/">
              <NavLogo width={175} height={60} />
            </Link>
            <NavControls
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <NotificationButton>
                <Bell width={24} height={24} />
                {data.notificationsUnreadCount.count !== 0 && (
                  <NotificationBadge>
                    <NotificationBadgeText>{data.notificationsUnreadCount.count}</NotificationBadgeText>
                  </NotificationBadge>
                )}
              </NotificationButton>
              <MenuButton>
                <Menu width={24} height={24} />
              </MenuButton>
              <UserProfile>
                <UserAvatar src={data.me.avatar.url} />
                <Username>{data.me.fullName}</Username>
              </UserProfile>
            </NavControls>
          </InnerContainer>
        </Container>
        <MenuContainer ref={$container} open={menuOpen}>
          <MenuContent>
            <MenuUsername>{data.me.fullName}</MenuUsername>
            <MenuList>
              {navItems
                .filter(i => !i.disabled)
                .map(navItem => (
                  <MenuItem key={navItem.label} inactive={navItem.inactive} onClick={navItem.onClick}>
                    <MenuItemName>
                      {navItem.label}
                      {navItem.label === 'inbox' && data.notificationsUnreadCount.count !== 0 && (
                        <NotificationBadge drawer>
                          <NotificationBadgeText>{data.notificationsUnreadCount.count}</NotificationBadgeText>
                        </NotificationBadge>
                      )}
                    </MenuItemName>
                    <MenuItemIcon>
                      <AngleRight width={16} height={16} />
                    </MenuItemIcon>
                  </MenuItem>
                ))}
            </MenuList>
          </MenuContent>
        </MenuContainer>
      </>
    );
  }
  return null;
};

export default Navbar;
