import React from 'react';
import styled, { css } from 'styled-components';
import { Link, useRouteMatch, useHistory, NavLink } from 'react-router-dom';
import { ExternalLink } from 'shared/icons';
import { useCurrentUser } from 'App/context';

const WebsiteIcon = styled(ExternalLink)`
  fill: #6f7782;
  margin-left: 8px;
`;

const ProjectTabsWrapper = styled.div`
  max-width: 960px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #fff;
  margin-top: 32px;
  margin-bottom: 24px;
`;

const ProjectTab = styled(NavLink)`
  border: 1px solid #fff;
  border-radius: 7px 7px 0px 0px;
  padding: 8px 8px 2px 8px;

  cursor: pointer;
  margin: 0 8px;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #fff;
  &.active {
    background: #fff;
    color: #000;
  }
  &:hover {
    background: #fff;
    color: #000;
  }
`;

const ProjectTabExternal = styled.a`
  border: 1px solid #fff;
  border-radius: 7px 7px 0px 0px;
  padding: 8px 8px 2px 8px;

  cursor: pointer;
  margin: 0 8px;
  margin-bottom: -1px;

  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #fff;
  svg {
    fill: #fff;
  }
  &:hover {
    background: #fff;
    color: #000;
    svg {
      fill: #000;
    }
  }
`;

type ProjectTabsProps = {
  projectID: string;
  editable: boolean;
  websiteURL?: string | null;
  meetingURL?: string | null;
};

const ProjectTabs: React.FC<ProjectTabsProps> = ({ projectID, editable, websiteURL, meetingURL }) => {
  let baseURL = `/projects/${projectID}`;
  const { user } = useCurrentUser();
  if (user) {
    return (
      <ProjectTabsWrapper>
        <ProjectTab exact to={baseURL}>
          Tasks
        </ProjectTab>
        <ProjectTab exact to={`${baseURL}/upload`}>
          Files
        </ProjectTab>
        <ProjectTab exact to={`${baseURL}/feedback`}>
          Feedback
        </ProjectTab>
        {user.isAdmin() && (
          <ProjectTab exact to={`${baseURL}/settings`}>
            Settings
          </ProjectTab>
        )}
        {websiteURL && (
          <ProjectTabExternal target="_blank" href={websiteURL}>
            <span>Website</span>
            <WebsiteIcon width={12} height={12} />
          </ProjectTabExternal>
        )}
        {meetingURL && (
          <ProjectTabExternal target="_blank" href={meetingURL}>
            <span>Meeting</span>
            <WebsiteIcon width={12} height={12} />
          </ProjectTabExternal>
        )}
      </ProjectTabsWrapper>
    );
  }
  return null;
};

export default ProjectTabs;
