import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { createBrowserHistory } from 'history';
import { Router, useLocation, useRouteMatch } from 'react-router';
import { PopupProvider } from 'shared/components/PopupMenu';
import { ToastContainer } from 'react-toastify';
import { setAccessToken } from 'shared/utils/accessToken';
import styled, { ThemeProvider } from 'styled-components';
import NormalizeStyles from './NormalizeStyles';
import BaseStyles from './BaseStyles';
import theme from './ThemeStyles';
import Routes from './Routes';
import { UserContext, CurrentUserRaw } from './context';

import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(duration);
dayjs.extend(relativeTime);

const StyledContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
    background: rgba(255, 163, 59);
    color: #000;
  }
  .Toastify__toast--error {
    background: rgba(${props => props.theme.colors.danger});
  }
  .Toastify__toast--warning {
    background: rgba(${props => props.theme.colors.warning});
  }
  .Toastify__toast--success {
    background: rgba(${props => props.theme.colors.success});
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
  .Toastify__close-button {
    display: none;
  }
`;

const history = createBrowserHistory();
type RefreshTokenResponse = {
  accessToken: string;
  isInstalled: boolean;
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<CurrentUserRaw | null>(null);

  useEffect(() => {
    fetch('/auth/refresh_token', {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { status } = x;
      if (status === 400) {
        if (!window.location.pathname.startsWith('/login')) {
          history.replace(`/login?redirect=${window.location.href}`);
        }
      } else {
        const response: RefreshTokenResponse = await x.json();
        const { accessToken, isInstalled } = response;
        const claims: JWTToken = jwtDecode(accessToken);
        const currentUser = {
          id: claims.userId,
          roleCode: claims.roleCode,
        };
        setUser(currentUser);
        setAccessToken(accessToken);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      <UserContext.Provider value={{ user, setUser }}>
        <ThemeProvider theme={theme}>
          <NormalizeStyles />
          <BaseStyles />
          <Router history={history}>
            <PopupProvider>
              {!loading && (
                <>
                  <Routes history={history} />
                </>
              )}
            </PopupProvider>
          </Router>
          <StyledContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={5}
          />
        </ThemeProvider>
      </UserContext.Provider>
    </>
  );
};

export default App;
