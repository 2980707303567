import React from 'react';
import Icon, { IconProps } from './Icon';

const Menu: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 78.72 78.76">
      <circle  cx="10.4" cy="10.4" r="10.4"/><circle  cx="39.36" cy="10.4" r="10.4"/><circle  cx="68.32" cy="10.4" r="10.4"/><circle  cx="10.4" cy="39.38" r="10.4"/><circle  cx="39.36" cy="39.38" r="10.4"/><circle  cx="68.32" cy="39.38" r="10.4"/><circle  cx="10.4" cy="68.36" r="10.4"/><circle  cx="39.36" cy="68.36" r="10.4"/><circle  cx="68.32" cy="68.36" r="10.4"/>

    </Icon>
  );
};

export default Menu;
