import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: {
    primary: '3px',
    alternate: '6px',
  },
  colors: {
    primary: '255, 163, 59',
    secondary: '216, 93, 216',
    alternate: '65, 69, 97',
    success: '40, 199, 111',
    danger: '234, 84, 85',
    warning: '255, 159, 67',
    dark: '30, 30, 30',
    text: {
      primary: '194, 198, 220',
      secondary: '255, 255, 255',
    },
    border: '65, 69, 97',
    bg: {
      primary: '241, 241, 241',
      secondary: '241, 241, 241',
    },
  },
};

export default theme;
