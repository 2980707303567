import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  UUID: string;
  Upload: any;
};




export type ProjectTemplateTask = {
   __typename?: 'ProjectTemplateTask';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  status: VisibilityStatus;
};

export type ProjectTemplateTaskGroup = {
   __typename?: 'ProjectTemplateTaskGroup';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  tasks: Array<ProjectTemplateTask>;
};

export type ProjectTemplate = {
   __typename?: 'ProjectTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskGroups: Array<ProjectTemplateTaskGroup>;
};

export type AttachmentCategory = {
   __typename?: 'AttachmentCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AttachmentTag = {
   __typename?: 'AttachmentTag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum NotiType {
  CommentCreated = 'comment_created'
}

export type NotificationData = {
   __typename?: 'NotificationData';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Notification = {
   __typename?: 'Notification';
  id: Scalars['ID'];
  type: NotiType;
  causedBy: Scalars['UUID'];
  createdAt: Scalars['Time'];
  data: Array<NotificationData>;
  read: Scalars['Boolean'];
  readAt?: Maybe<Scalars['Time']>;
};

export type UploadedBy = {
   __typename?: 'UploadedBy';
  id: Scalars['ID'];
  fullname: Scalars['String'];
  avatar: Avatar;
};

export type Attachment = {
   __typename?: 'Attachment';
  id: Scalars['ID'];
  filepath: Scalars['String'];
  filename: Scalars['String'];
  size: Scalars['Int'];
  category: AttachmentCategory;
  uploadedBy: UploadedBy;
  uploadedAt: Scalars['Time'];
  tags: Array<AttachmentTag>;
};

export enum VisibilityStatus {
  Client = 'client',
  Internal = 'internal'
}

export enum ProjectPhase {
  Plan = 'plan',
  Do = 'do',
  Tidy = 'tidy',
  Act = 'act'
}

export type RefreshToken = {
   __typename?: 'RefreshToken';
  id: Scalars['ID'];
  userId: Scalars['UUID'];
  expiresAt: Scalars['Time'];
  createdAt: Scalars['Time'];
};

export enum RoleCode {
  Admin = 'admin',
  Client = 'client'
}

export type Role = {
   __typename?: 'Role';
  code: RoleCode;
  name: Scalars['String'];
};

export type Avatar = {
   __typename?: 'Avatar';
  url: Scalars['String'];
  default: Scalars['Boolean'];
};

export type UserAccount = {
   __typename?: 'UserAccount';
  id: Scalars['ID'];
  email: Scalars['String'];
  createdAt: Scalars['Time'];
  fullName: Scalars['String'];
  username: Scalars['String'];
  role: Role;
  avatar: Avatar;
};

export type Project = {
   __typename?: 'Project';
  id: Scalars['ID'];
  position: Scalars['Float'];
  name: Scalars['String'];
  createdAt: Scalars['Time'];
  status: ProjectPhase;
  websiteUrl?: Maybe<Scalars['String']>;
  taskGroups: Array<TaskGroup>;
  members: Array<UserAccount>;
  meetingUrl?: Maybe<Scalars['String']>;
};

export type ProjectFeedbackComment = {
   __typename?: 'ProjectFeedbackComment';
  id: Scalars['ID'];
  summary: Scalars['String'];
  createdAt: Scalars['Time'];
  updatedAt?: Maybe<Scalars['Time']>;
  createdBy: UserAccount;
};

export type ProjectFeedback = {
   __typename?: 'ProjectFeedback';
  id: Scalars['ID'];
  name: Scalars['String'];
  summary: Scalars['String'];
  createdAt: Scalars['Time'];
  createdBy: UserAccount;
  updatedAt?: Maybe<Scalars['Time']>;
  comments: Array<ProjectFeedbackComment>;
};

export type TaskGroupMinimal = {
   __typename?: 'TaskGroupMinimal';
  id: Scalars['ID'];
};

export type TaskComment = {
   __typename?: 'TaskComment';
  id: Scalars['ID'];
  summary: Scalars['String'];
  createdAt: Scalars['Time'];
  updatedAt?: Maybe<Scalars['Time']>;
  createdBy: UserAccount;
};

export type TaskBadges = {
   __typename?: 'TaskBadges';
  comments: Scalars['Int'];
  description: Scalars['Boolean'];
};

export type Task = {
   __typename?: 'Task';
  id: Scalars['ID'];
  name: Scalars['String'];
  complete: Scalars['Boolean'];
  position: Scalars['Float'];
  status: VisibilityStatus;
  taskGroup: TaskGroupMinimal;
  comments: Array<TaskComment>;
  badges: TaskBadges;
  description: Scalars['String'];
};

export type TaskGroup = {
   __typename?: 'TaskGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  tasks: Array<Task>;
};

export type Query = {
   __typename?: 'Query';
  allFeedback: AllFeedbackPayload;
  attachments: AttachmentsPayload;
  categories: CategoriesPayload;
  feedback: FeedbackPayload;
  findProject: Project;
  findTask: Task;
  findUser: UserAccount;
  me: UserAccount;
  notifications: NotificationPayload;
  notificationsUnreadCount: NotificationsUnreadCountPayload;
  projectViewLog: ProjectViewLogPayload;
  projects: Array<Project>;
  requestZipStatus: RequestZipStatusPayload;
  searchMembers: Array<MemberSearchResult>;
  tags: TagsPayload;
  templates: Array<ProjectTemplate>;
  users: Array<UserAccount>;
};


export type QueryAllFeedbackArgs = {
  input: AllFeedback;
};


export type QueryAttachmentsArgs = {
  input: AttachmentsFilter;
};


export type QueryCategoriesArgs = {
  input: CategoriesFilter;
};


export type QueryFeedbackArgs = {
  input: Feedback;
};


export type QueryFindProjectArgs = {
  input: FindProject;
};


export type QueryFindTaskArgs = {
  input: FindTask;
};


export type QueryFindUserArgs = {
  input: FindUser;
};


export type QueryProjectViewLogArgs = {
  input: ProjectViewLog;
};


export type QueryRequestZipStatusArgs = {
  input: RequestZipStatus;
};


export type QuerySearchMembersArgs = {
  input: MemberSearchFilter;
};


export type QueryTagsArgs = {
  input: TagsFilter;
};

export type ProjectViewLog = {
  projectID: Scalars['UUID'];
};

export type ProjectViewLogEntryUser = {
   __typename?: 'ProjectViewLogEntryUser';
  userID: Scalars['UUID'];
  fullname: Scalars['String'];
  username: Scalars['String'];
};

export type ProjectViewLogEntry = {
   __typename?: 'ProjectViewLogEntry';
  user: ProjectViewLogEntryUser;
  count: Scalars['Int'];
  lastViewed: Scalars['Time'];
};

export type ProjectViewLogPayload = {
   __typename?: 'ProjectViewLogPayload';
  projectID: Scalars['UUID'];
  logs?: Maybe<Array<ProjectViewLogEntry>>;
};

export type Feedback = {
  feedbackID?: Maybe<Scalars['UUID']>;
  projectID: Scalars['UUID'];
};

export type FeedbackPayload = {
   __typename?: 'FeedbackPayload';
  feedback?: Maybe<ProjectFeedback>;
};

export type AllFeedback = {
  projectID: Scalars['UUID'];
};

export type AllFeedbackPayload = {
   __typename?: 'AllFeedbackPayload';
  feedback: Array<ProjectFeedback>;
};

export type FindTask = {
  taskID: Scalars['UUID'];
};

export type CategoriesFilter = {
  projectID: Scalars['UUID'];
};

export type TagsFilter = {
  projectID: Scalars['UUID'];
};

export type TagsPayload = {
   __typename?: 'TagsPayload';
  tags: Array<AttachmentTag>;
};

export type CategoriesPayload = {
   __typename?: 'CategoriesPayload';
  categories: Array<AttachmentCategory>;
};

export type AttachmentsFilter = {
  projectID: Scalars['UUID'];
};

export type AttachmentsPayload = {
   __typename?: 'AttachmentsPayload';
  attachments: Array<Attachment>;
};

export type FindProject = {
  projectID: Scalars['UUID'];
};

export type Mutation = {
   __typename?: 'Mutation';
  createAttachmentCategory: CreateAttachmentCategoryPayload;
  createAttachmentTag: CreateAttachmentTagPayload;
  createFeedbackComment: CreateFeedbackCommentPayload;
  createProject: CreateProjectPayload;
  createProjectFeedback: CreateProjectFeedbackPayload;
  createProjectMember: CreateProjectMemberPayload;
  createProjectTemplate: CreateProjectTemplatePayload;
  createRefreshToken: RefreshToken;
  createTask: CreateTaskPayload;
  createTaskComment: CreateTaskCommentPayload;
  createTaskGroup: CreateTaskGroupPayload;
  createUserAccount: UserAccount;
  deleteAttachment: DeleteAttachmentPayload;
  deleteAttachmentCategory: DeleteAttachmentCategoryPayload;
  deleteAttachmentTag: DeleteAttachmentTagPayload;
  deleteFeedbackComment: DeleteFeedbackCommentPayload;
  deleteProject: DeleteProjectPayload;
  deleteProjectFeedback: DeleteProjectFeedbackPayload;
  deleteProjectMember: DeleteProjectMemberPayload;
  deleteProjectTemplate: DeleteProjectTemplatePayload;
  deleteTask: DeleteTaskPayload;
  deleteTaskComment: DeleteTaskCommentPayload;
  deleteTaskGroup: DeleteTaskGroupPayload;
  deleteUserAccount: DeleteUserAccountPayload;
  logoutUser: Scalars['Boolean'];
  markInboxRead: MarkInboxReadPayload;
  removeAvatar: RemoveAvatarPayload;
  requestZip: RequestZipPayload;
  resetPassword: ResetPasswordPayload;
  setMeetingURL: SetMeetingUrlPayload;
  setProjectLocation: SetProjectPhasePayload;
  setProjectName: SetProjectNamePayload;
  setProjectPhase: SetProjectPhasePayload;
  setWebsiteURL: SetWebsiteUrlPayload;
  toggleAttachmentTag: ToggleAttachmentTagPayload;
  toggleTaskComplete: ToggleTaskCompletePayload;
  updateFeedbackComment: UpdateFeedbackCommentPayload;
  updateProjectFeedbackName: UpdateProjectFeedbackNamePayload;
  updateProjectFeedbackSummary: UpdateProjectFeedbackSummaryPayload;
  updateProjectTemplateName: UpdateProjectTemplateNamePayload;
  updateTaskComment: UpdateTaskCommentPayload;
  updateTaskDescription: UpdateTaskDescriptionPayload;
  updateTaskGroupName: UpdateTaskGroupNamePayload;
  updateTaskGroupPosition: UpdateTaskGroupPositionPayload;
  updateTaskLocation: UpdateTaskLocationPayload;
  updateTaskName: UpdateTaskNamePayload;
  updateTaskStatus: UpdateTaskStatusPayload;
  updateUserFullname: UpdateUserFullnamePayload;
  updateUserPassword: UpdateUserPasswordPayload;
  updateUserRole: UpdateUserRolePayload;
};


export type MutationCreateAttachmentCategoryArgs = {
  input: CreateAttachmentCategory;
};


export type MutationCreateAttachmentTagArgs = {
  input: CreateAttachmentTag;
};


export type MutationCreateFeedbackCommentArgs = {
  input: CreateFeedbackComment;
};


export type MutationCreateProjectArgs = {
  input: CreateProject;
};


export type MutationCreateProjectFeedbackArgs = {
  input: CreateProjectFeedback;
};


export type MutationCreateProjectMemberArgs = {
  input: CreateProjectMember;
};


export type MutationCreateProjectTemplateArgs = {
  input: CreateProjectTemplate;
};


export type MutationCreateRefreshTokenArgs = {
  input: NewRefreshToken;
};


export type MutationCreateTaskArgs = {
  input: CreateTask;
};


export type MutationCreateTaskCommentArgs = {
  input: CreateTaskComment;
};


export type MutationCreateTaskGroupArgs = {
  input: CreateTaskGroup;
};


export type MutationCreateUserAccountArgs = {
  input: NewUserAccount;
};


export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachment;
};


export type MutationDeleteAttachmentCategoryArgs = {
  input: DeleteAttachmentCategory;
};


export type MutationDeleteAttachmentTagArgs = {
  input: DeleteAttachmentTag;
};


export type MutationDeleteFeedbackCommentArgs = {
  input: DeleteFeedbackComment;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProject;
};


export type MutationDeleteProjectFeedbackArgs = {
  input: DeleteProjectFeedback;
};


export type MutationDeleteProjectMemberArgs = {
  input: DeleteProjectMember;
};


export type MutationDeleteProjectTemplateArgs = {
  input: DeleteProjectTemplate;
};


export type MutationDeleteTaskArgs = {
  input: DeleteTask;
};


export type MutationDeleteTaskCommentArgs = {
  input: DeleteTaskComment;
};


export type MutationDeleteTaskGroupArgs = {
  input: DeleteTaskGroup;
};


export type MutationDeleteUserAccountArgs = {
  input: DeleteUserAccount;
};


export type MutationLogoutUserArgs = {
  input: LogoutUser;
};


export type MutationRequestZipArgs = {
  input: RequestZip;
};


export type MutationResetPasswordArgs = {
  input: ResetPassword;
};


export type MutationSetMeetingUrlArgs = {
  input: SetMeetingUrl;
};


export type MutationSetProjectLocationArgs = {
  input: SetProjectLocation;
};


export type MutationSetProjectNameArgs = {
  input: SetProjectName;
};


export type MutationSetProjectPhaseArgs = {
  input: SetProjectPhase;
};


export type MutationSetWebsiteUrlArgs = {
  input: SetWebsiteUrl;
};


export type MutationToggleAttachmentTagArgs = {
  input: ToggleAttachmentTag;
};


export type MutationToggleTaskCompleteArgs = {
  input: ToggleTaskComplete;
};


export type MutationUpdateFeedbackCommentArgs = {
  input: UpdateFeedbackComment;
};


export type MutationUpdateProjectFeedbackNameArgs = {
  input: UpdateProjectFeedbackName;
};


export type MutationUpdateProjectFeedbackSummaryArgs = {
  input: UpdateProjectFeedbackSummary;
};


export type MutationUpdateProjectTemplateNameArgs = {
  input: UpdateProjectTemplateName;
};


export type MutationUpdateTaskCommentArgs = {
  input: UpdateTaskComment;
};


export type MutationUpdateTaskDescriptionArgs = {
  input: UpdateTaskDescription;
};


export type MutationUpdateTaskGroupNameArgs = {
  input: UpdateTaskGroupName;
};


export type MutationUpdateTaskGroupPositionArgs = {
  input: UpdateTaskGroupPosition;
};


export type MutationUpdateTaskLocationArgs = {
  input: UpdateTaskLocation;
};


export type MutationUpdateTaskNameArgs = {
  input: UpdateTaskName;
};


export type MutationUpdateTaskStatusArgs = {
  input: UpdateTaskStatus;
};


export type MutationUpdateUserFullnameArgs = {
  input: UpdateUserFullname;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPassword;
};


export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRole;
};

export type FindUser = {
  userID: Scalars['UUID'];
};

export type DeleteAttachment = {
  id: Scalars['UUID'];
};

export type DeleteAttachmentPayload = {
   __typename?: 'DeleteAttachmentPayload';
  id: Scalars['UUID'];
};

export type DeleteAttachmentCategory = {
  id: Scalars['UUID'];
};

export type DeleteAttachmentCategoryPayload = {
   __typename?: 'DeleteAttachmentCategoryPayload';
  id: Scalars['UUID'];
};

export type DeleteAttachmentTag = {
  id: Scalars['UUID'];
};

export type DeleteAttachmentTagPayload = {
   __typename?: 'DeleteAttachmentTagPayload';
  id: Scalars['UUID'];
};

export type RequestZipStatus = {
  requestKey: Scalars['UUID'];
};

export type RequestZipStatusPayload = {
   __typename?: 'RequestZipStatusPayload';
  complete: Scalars['Boolean'];
  filepath?: Maybe<Scalars['String']>;
};

export type RequestZip = {
  projectID: Scalars['UUID'];
  attachments: Array<Scalars['UUID']>;
};

export type RequestZipPayload = {
   __typename?: 'RequestZipPayload';
  requestKey: Scalars['UUID'];
};

export type MarkInboxReadPayload = {
   __typename?: 'MarkInboxReadPayload';
  ok: Scalars['Boolean'];
};

export type NotificationsUnreadCountPayload = {
   __typename?: 'NotificationsUnreadCountPayload';
  count: Scalars['Int'];
};

export type NotificationPayload = {
   __typename?: 'NotificationPayload';
  notifications: Array<Notification>;
};

export type ToggleAttachmentTag = {
  attachment: Scalars['UUID'];
  tag: Scalars['UUID'];
};

export type ToggleAttachmentTagPayload = {
   __typename?: 'ToggleAttachmentTagPayload';
  attachment: Attachment;
};

export type CreateAttachmentTag = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
};

export type CreateAttachmentTagPayload = {
   __typename?: 'CreateAttachmentTagPayload';
  tag: AttachmentTag;
};

export type CreateAttachmentCategory = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
};

export type CreateAttachmentCategoryPayload = {
   __typename?: 'CreateAttachmentCategoryPayload';
  category: AttachmentCategory;
};

export type SetProjectLocation = {
  projectID: Scalars['UUID'];
  position: Scalars['Float'];
  phase: ProjectPhase;
};

export type SetProjectLocationPayload = {
   __typename?: 'SetProjectLocationPayload';
  project: Project;
};

export type SetMeetingUrl = {
  projectID: Scalars['UUID'];
  url?: Maybe<Scalars['String']>;
};

export type SetMeetingUrlPayload = {
   __typename?: 'SetMeetingURLPayload';
  project: Project;
};

export type UpdateProjectFeedbackName = {
  feedbackID: Scalars['UUID'];
  name: Scalars['String'];
};

export type UpdateProjectFeedbackNamePayload = {
   __typename?: 'UpdateProjectFeedbackNamePayload';
  feedback: ProjectFeedback;
};

export type UpdateProjectFeedbackSummary = {
  feedbackID: Scalars['UUID'];
  summary: Scalars['String'];
};

export type UpdateProjectFeedbackSummaryPayload = {
   __typename?: 'UpdateProjectFeedbackSummaryPayload';
  feedback: ProjectFeedback;
};

export type CreateProjectMember = {
  projectID: Scalars['UUID'];
  users: Array<Scalars['UUID']>;
};

export type DeleteProjectMember = {
  projectID: Scalars['UUID'];
  userID: Scalars['UUID'];
};

export type CreateProjectMemberPayload = {
   __typename?: 'CreateProjectMemberPayload';
  users: Array<UserAccount>;
};

export type DeleteProjectMemberPayload = {
   __typename?: 'DeleteProjectMemberPayload';
  userID: Scalars['UUID'];
};

export type SetWebsiteUrlPayload = {
   __typename?: 'SetWebsiteURLPayload';
  project: Project;
};

export type SetWebsiteUrl = {
  projectID: Scalars['UUID'];
  websiteURL?: Maybe<Scalars['String']>;
};

export type UpdateFeedbackComment = {
  commentID: Scalars['UUID'];
  message: Scalars['String'];
};

export type UpdateFeedbackCommentPayload = {
   __typename?: 'UpdateFeedbackCommentPayload';
  comment: ProjectFeedbackComment;
};

export type DeleteProjectFeedback = {
  feedbackID: Scalars['UUID'];
};

export type DeleteProjectFeedbackPayload = {
   __typename?: 'DeleteProjectFeedbackPayload';
  feedbackID: Scalars['UUID'];
};

export type DeleteFeedbackComment = {
  feedbackCommentID: Scalars['UUID'];
};

export type DeleteFeedbackCommentPayload = {
   __typename?: 'DeleteFeedbackCommentPayload';
  feedbackCommentID: Scalars['UUID'];
};

export type CreateFeedbackComment = {
  feedbackID: Scalars['UUID'];
  summary: Scalars['String'];
};

export type CreateFeedbackCommentPayload = {
   __typename?: 'CreateFeedbackCommentPayload';
  comment: ProjectFeedbackComment;
};

export type CreateProjectFeedback = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
  summary: Scalars['String'];
};

export type CreateProjectFeedbackPayload = {
   __typename?: 'CreateProjectFeedbackPayload';
  feedback: ProjectFeedback;
};

export type DeleteProject = {
  projectID: Scalars['UUID'];
};

export type DeleteProjectPayload = {
   __typename?: 'DeleteProjectPayload';
  projectID: Scalars['UUID'];
};

export type SetProjectName = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
};

export type SetProjectNamePayload = {
   __typename?: 'SetProjectNamePayload';
  project: Project;
};

export type SetProjectPhase = {
  phase: ProjectPhase;
  projectID: Scalars['UUID'];
};

export type SetProjectPhasePayload = {
   __typename?: 'SetProjectPhasePayload';
  project: Project;
};

export type CreateProject = {
  name: Scalars['String'];
  phase: ProjectPhase;
};

export type CreateProjectPayload = {
   __typename?: 'CreateProjectPayload';
  project: Project;
};

export type CreateProjectTemplate = {
  name: Scalars['String'];
};

export type CreateProjectTemplatePayload = {
   __typename?: 'CreateProjectTemplatePayload';
  template: ProjectTemplate;
};

export type UpdateProjectTemplateName = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type UpdateProjectTemplateNamePayload = {
   __typename?: 'UpdateProjectTemplateNamePayload';
  template: ProjectTemplate;
};

export type DeleteProjectTemplate = {
  id: Scalars['UUID'];
};

export type DeleteProjectTemplatePayload = {
   __typename?: 'DeleteProjectTemplatePayload';
  id: Scalars['UUID'];
};

export type UpdateTaskDescription = {
  taskID: Scalars['UUID'];
  description: Scalars['String'];
};

export type UpdateTaskDescriptionPayload = {
   __typename?: 'UpdateTaskDescriptionPayload';
  task: Task;
};

export type ToggleTaskComplete = {
  taskID: Scalars['UUID'];
  complete: Scalars['Boolean'];
};

export type ToggleTaskCompletePayload = {
   __typename?: 'ToggleTaskCompletePayload';
  task: Task;
};

export type UpdateTaskGroupName = {
  taskGroupID: Scalars['UUID'];
  name: Scalars['String'];
};

export type UpdateTaskGroupNamePayload = {
   __typename?: 'UpdateTaskGroupNamePayload';
  taskGroup: TaskGroup;
};

export type CreateTaskComment = {
  taskID: Scalars['UUID'];
  summary: Scalars['String'];
};

export type CreateTaskCommentPayload = {
   __typename?: 'CreateTaskCommentPayload';
  comment: TaskComment;
};

export type DeleteTaskComment = {
  commentID: Scalars['UUID'];
};

export type DeleteTaskCommentPayload = {
   __typename?: 'DeleteTaskCommentPayload';
  commentID: Scalars['UUID'];
};

export type UpdateTaskComment = {
  commentID: Scalars['UUID'];
  summary: Scalars['String'];
};

export type UpdateTaskCommentPayload = {
   __typename?: 'UpdateTaskCommentPayload';
  comment: TaskComment;
};

export type UpdateTaskLocation = {
  taskID: Scalars['UUID'];
  taskGroupID: Scalars['UUID'];
  position: Scalars['Float'];
};

export type UpdateTaskLocationPayload = {
   __typename?: 'UpdateTaskLocationPayload';
  task: Task;
  previousTaskGroupID: Scalars['UUID'];
};

export type UpdateTaskGroupPosition = {
  taskGroupID: Scalars['UUID'];
  position: Scalars['Float'];
};

export type UpdateTaskGroupPositionPayload = {
   __typename?: 'UpdateTaskGroupPositionPayload';
  taskGroup: TaskGroup;
};

export type UpdateTaskStatus = {
  taskID: Scalars['UUID'];
  status: VisibilityStatus;
};

export type UpdateTaskStatusPayload = {
   __typename?: 'UpdateTaskStatusPayload';
  task: Task;
};

export type UpdateTaskName = {
  taskID: Scalars['UUID'];
  name: Scalars['String'];
};

export type UpdateTaskNamePayload = {
   __typename?: 'UpdateTaskNamePayload';
  task: Task;
};

export type DeleteTask = {
  taskID: Scalars['UUID'];
};

export type DeleteTaskPayload = {
   __typename?: 'DeleteTaskPayload';
  taskID: Scalars['UUID'];
  taskGroupID: Scalars['UUID'];
};

export type DeleteTaskGroup = {
  projectID: Scalars['UUID'];
  taskGroupID: Scalars['UUID'];
};

export type DeleteTaskGroupPayload = {
   __typename?: 'DeleteTaskGroupPayload';
  projectID: Scalars['UUID'];
  taskGroupID: Scalars['UUID'];
};

export type CreateTaskGroup = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
  position: Scalars['Float'];
};

export type CreateTaskGroupPayload = {
   __typename?: 'CreateTaskGroupPayload';
  projectID: Scalars['UUID'];
  taskGroup: TaskGroup;
};

export type CreateTask = {
  taskGroupID: Scalars['UUID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  status: VisibilityStatus;
};

export type CreateTaskPayload = {
   __typename?: 'CreateTaskPayload';
  taskGroupID: Scalars['UUID'];
  task: Task;
};

export type RemoveAvatarPayload = {
   __typename?: 'RemoveAvatarPayload';
  user: UserAccount;
};

export type UpdateUserFullname = {
  fullname: Scalars['String'];
};

export type UpdateUserFullnamePayload = {
   __typename?: 'UpdateUserFullnamePayload';
  user: UserAccount;
};

export type MemberSearchFilter = {
  searchFilter: Scalars['String'];
  projectID?: Maybe<Scalars['UUID']>;
};

export type MemberSearchResult = {
   __typename?: 'MemberSearchResult';
  similarity: Scalars['Int'];
  id: Scalars['String'];
  user?: Maybe<UserAccount>;
};

export type UpdateUserRole = {
  userID: Scalars['UUID'];
  roleCode: RoleCode;
};

export type UpdateUserRolePayload = {
   __typename?: 'UpdateUserRolePayload';
  user: UserAccount;
};

export type ResetPassword = {
  userID: Scalars['UUID'];
  newPassword?: Maybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
   __typename?: 'ResetPasswordPayload';
  userID: Scalars['UUID'];
  ok: Scalars['Boolean'];
};

export type UpdateUserPassword = {
  userID: Scalars['UUID'];
  password: Scalars['String'];
};

export type UpdateUserPasswordPayload = {
   __typename?: 'UpdateUserPasswordPayload';
  ok: Scalars['Boolean'];
  user: UserAccount;
};

export type NewRefreshToken = {
  userID: Scalars['UUID'];
};

export type NewUserAccount = {
  username: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  password: Scalars['String'];
  roleCode: RoleCode;
};

export type LogoutUser = {
  userID: Scalars['UUID'];
};

export type DeleteUserAccount = {
  userID: Scalars['UUID'];
};

export type DeleteUserAccountPayload = {
   __typename?: 'DeleteUserAccountPayload';
  ok: Scalars['Boolean'];
  userID: Scalars['UUID'];
};

export type InboxQueryVariables = {};


export type InboxQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationPayload' }
    & { notifications: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'type' | 'id' | 'causedBy' | 'createdAt' | 'read' | 'readAt'>
      & { data: Array<(
        { __typename?: 'NotificationData' }
        & Pick<NotificationData, 'key' | 'value'>
      )> }
    )> }
  ) }
);

export type ProjectViewLogQueryVariables = {
  projectID: Scalars['UUID'];
};


export type ProjectViewLogQuery = (
  { __typename?: 'Query' }
  & { projectViewLog: (
    { __typename?: 'ProjectViewLogPayload' }
    & Pick<ProjectViewLogPayload, 'projectID'>
    & { logs?: Maybe<Array<(
      { __typename?: 'ProjectViewLogEntry' }
      & Pick<ProjectViewLogEntry, 'count' | 'lastViewed'>
      & { user: (
        { __typename?: 'ProjectViewLogEntryUser' }
        & Pick<ProjectViewLogEntryUser, 'userID' | 'fullname' | 'username'>
      ) }
    )>> }
  ) }
);

export type MarkInboxReadMutationVariables = {};


export type MarkInboxReadMutation = (
  { __typename?: 'Mutation' }
  & { markInboxRead: (
    { __typename?: 'MarkInboxReadPayload' }
    & Pick<MarkInboxReadPayload, 'ok'>
  ) }
);

export type MeQueryVariables = {};


export type MeQuery = (
  { __typename?: 'Query' }
  & { notificationsUnreadCount: (
    { __typename?: 'NotificationsUnreadCountPayload' }
    & Pick<NotificationsUnreadCountPayload, 'count'>
  ), me: (
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'fullName' | 'username' | 'email'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'code'>
    ), avatar: (
      { __typename?: 'Avatar' }
      & Pick<Avatar, 'url' | 'default'>
    ) }
  ) }
);

export type AllFeedbackQueryVariables = {
  projectID: Scalars['UUID'];
};


export type AllFeedbackQuery = (
  { __typename?: 'Query' }
  & { allFeedback: (
    { __typename?: 'AllFeedbackPayload' }
    & { feedback: Array<(
      { __typename?: 'ProjectFeedback' }
      & Pick<ProjectFeedback, 'id' | 'name' | 'createdAt'>
    )> }
  ) }
);

export type ProjectsQueryVariables = {};


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'position' | 'createdAt' | 'status'>
  )> }
);

export type AttachmentsQueryVariables = {
  projectID: Scalars['UUID'];
};


export type AttachmentsQuery = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoriesPayload' }
    & { categories: Array<(
      { __typename?: 'AttachmentCategory' }
      & Pick<AttachmentCategory, 'id' | 'name'>
    )> }
  ), tags: (
    { __typename?: 'TagsPayload' }
    & { tags: Array<(
      { __typename?: 'AttachmentTag' }
      & Pick<AttachmentTag, 'id' | 'name'>
    )> }
  ), attachments: (
    { __typename?: 'AttachmentsPayload' }
    & { attachments: Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id' | 'filepath' | 'filename' | 'size' | 'uploadedAt'>
      & { uploadedBy: (
        { __typename?: 'UploadedBy' }
        & Pick<UploadedBy, 'id' | 'fullname'>
        & { avatar: (
          { __typename?: 'Avatar' }
          & Pick<Avatar, 'url' | 'default'>
        ) }
      ), category: (
        { __typename?: 'AttachmentCategory' }
        & Pick<AttachmentCategory, 'id' | 'name'>
      ), tags: Array<(
        { __typename?: 'AttachmentTag' }
        & Pick<AttachmentTag, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type CategoriesQueryVariables = {
  projectID: Scalars['UUID'];
};


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoriesPayload' }
    & { categories: Array<(
      { __typename?: 'AttachmentCategory' }
      & Pick<AttachmentCategory, 'id' | 'name'>
    )> }
  ), tags: (
    { __typename?: 'TagsPayload' }
    & { tags: Array<(
      { __typename?: 'AttachmentTag' }
      & Pick<AttachmentTag, 'id' | 'name'>
    )> }
  ) }
);

export type CreateAttachmentCategoryMutationVariables = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
};


export type CreateAttachmentCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createAttachmentCategory: (
    { __typename?: 'CreateAttachmentCategoryPayload' }
    & { category: (
      { __typename?: 'AttachmentCategory' }
      & Pick<AttachmentCategory, 'id' | 'name'>
    ) }
  ) }
);

export type CreateProjectFeedbackMutationVariables = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
  summary: Scalars['String'];
};


export type CreateProjectFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { createProjectFeedback: (
    { __typename?: 'CreateProjectFeedbackPayload' }
    & { feedback: (
      { __typename?: 'ProjectFeedback' }
      & Pick<ProjectFeedback, 'id' | 'name' | 'createdAt'>
    ) }
  ) }
);

export type CreateFeedbackCommentMutationVariables = {
  summary: Scalars['String'];
  feedbackID: Scalars['UUID'];
};


export type CreateFeedbackCommentMutation = (
  { __typename?: 'Mutation' }
  & { createFeedbackComment: (
    { __typename?: 'CreateFeedbackCommentPayload' }
    & { comment: (
      { __typename?: 'ProjectFeedbackComment' }
      & Pick<ProjectFeedbackComment, 'id' | 'createdAt' | 'summary'>
      & { createdBy: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'username'>
      ) }
    ) }
  ) }
);

export type CreateProjectMutationVariables = {
  name: Scalars['String'];
  phase: ProjectPhase;
};


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: (
    { __typename?: 'CreateProjectPayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'createdAt' | 'status' | 'position'>
    ) }
  ) }
);

export type CreateProjectMemberMutationVariables = {
  projectID: Scalars['UUID'];
  users: Array<Scalars['UUID']>;
};


export type CreateProjectMemberMutation = (
  { __typename?: 'Mutation' }
  & { createProjectMember: (
    { __typename?: 'CreateProjectMemberPayload' }
    & { users: Array<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'email' | 'fullName' | 'createdAt' | 'username'>
      & { role: (
        { __typename?: 'Role' }
        & Pick<Role, 'code' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateAttachmentTagMutationVariables = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
};


export type CreateAttachmentTagMutation = (
  { __typename?: 'Mutation' }
  & { createAttachmentTag: (
    { __typename?: 'CreateAttachmentTagPayload' }
    & { tag: (
      { __typename?: 'AttachmentTag' }
      & Pick<AttachmentTag, 'id' | 'name'>
    ) }
  ) }
);

export type DeleteAttachmentMutationVariables = {
  id: Scalars['UUID'];
};


export type DeleteAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAttachment: (
    { __typename?: 'DeleteAttachmentPayload' }
    & Pick<DeleteAttachmentPayload, 'id'>
  ) }
);

export type DeleteAttachmentCategoryMutationVariables = {
  id: Scalars['UUID'];
};


export type DeleteAttachmentCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteAttachmentCategory: (
    { __typename?: 'DeleteAttachmentCategoryPayload' }
    & Pick<DeleteAttachmentCategoryPayload, 'id'>
  ) }
);

export type DeleteAttachmentTagMutationVariables = {
  id: Scalars['UUID'];
};


export type DeleteAttachmentTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteAttachmentTag: (
    { __typename?: 'DeleteAttachmentTagPayload' }
    & Pick<DeleteAttachmentTagPayload, 'id'>
  ) }
);

export type DeleteProjectFeedbackMutationVariables = {
  feedbackID: Scalars['UUID'];
};


export type DeleteProjectFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectFeedback: (
    { __typename?: 'DeleteProjectFeedbackPayload' }
    & Pick<DeleteProjectFeedbackPayload, 'feedbackID'>
  ) }
);

export type DeleteFeedbackCommentMutationVariables = {
  feedbackCommentID: Scalars['UUID'];
};


export type DeleteFeedbackCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteFeedbackComment: (
    { __typename?: 'DeleteFeedbackCommentPayload' }
    & Pick<DeleteFeedbackCommentPayload, 'feedbackCommentID'>
  ) }
);

export type DeleteProjectMutationVariables = {
  projectID: Scalars['UUID'];
};


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteProject: (
    { __typename?: 'DeleteProjectPayload' }
    & Pick<DeleteProjectPayload, 'projectID'>
  ) }
);

export type DeleteProjectMemberMutationVariables = {
  projectID: Scalars['UUID'];
  userID: Scalars['UUID'];
};


export type DeleteProjectMemberMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectMember: (
    { __typename?: 'DeleteProjectMemberPayload' }
    & Pick<DeleteProjectMemberPayload, 'userID'>
  ) }
);

export type FeedbackQueryVariables = {
  projectID: Scalars['UUID'];
  feedbackID?: Maybe<Scalars['UUID']>;
};


export type FeedbackQuery = (
  { __typename?: 'Query' }
  & { feedback: (
    { __typename?: 'FeedbackPayload' }
    & { feedback?: Maybe<(
      { __typename?: 'ProjectFeedback' }
      & Pick<ProjectFeedback, 'id' | 'name' | 'createdAt' | 'summary'>
      & { createdBy: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'fullName'>
      ), comments: Array<(
        { __typename?: 'ProjectFeedbackComment' }
        & Pick<ProjectFeedbackComment, 'id' | 'summary' | 'createdAt'>
        & { createdBy: (
          { __typename?: 'UserAccount' }
          & Pick<UserAccount, 'id' | 'fullName'>
        ) }
      )> }
    )> }
  ) }
);

export type FindProjectQueryVariables = {
  projectID: Scalars['UUID'];
};


export type FindProjectQuery = (
  { __typename?: 'Query' }
  & { findProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'createdAt' | 'status' | 'websiteUrl' | 'meetingUrl'>
    & { members: Array<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'fullName' | 'email'>
    )>, taskGroups: Array<(
      { __typename?: 'TaskGroup' }
      & Pick<TaskGroup, 'id' | 'name' | 'position'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'name' | 'position' | 'status' | 'complete'>
        & { badges: (
          { __typename?: 'TaskBadges' }
          & Pick<TaskBadges, 'comments' | 'description'>
        ), taskGroup: (
          { __typename?: 'TaskGroupMinimal' }
          & Pick<TaskGroupMinimal, 'id'>
        ), comments: Array<(
          { __typename?: 'TaskComment' }
          & Pick<TaskComment, 'id' | 'updatedAt' | 'createdAt' | 'summary'>
          & { createdBy: (
            { __typename?: 'UserAccount' }
            & Pick<UserAccount, 'id' | 'fullName' | 'email' | 'createdAt' | 'username'>
            & { role: (
              { __typename?: 'Role' }
              & Pick<Role, 'code' | 'name'>
            ) }
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type RequestZipMutationVariables = {
  projectID: Scalars['UUID'];
  attachments: Array<Scalars['UUID']>;
};


export type RequestZipMutation = (
  { __typename?: 'Mutation' }
  & { requestZip: (
    { __typename?: 'RequestZipPayload' }
    & Pick<RequestZipPayload, 'requestKey'>
  ) }
);

export type RequestZipStatusQueryVariables = {
  requestKey: Scalars['UUID'];
};


export type RequestZipStatusQuery = (
  { __typename?: 'Query' }
  & { requestZipStatus: (
    { __typename?: 'RequestZipStatusPayload' }
    & Pick<RequestZipStatusPayload, 'complete' | 'filepath'>
  ) }
);

export type SetMeetingUrlMutationVariables = {
  projectID: Scalars['UUID'];
  meetingUrl?: Maybe<Scalars['String']>;
};


export type SetMeetingUrlMutation = (
  { __typename?: 'Mutation' }
  & { setMeetingURL: (
    { __typename?: 'SetMeetingURLPayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'meetingUrl'>
    ) }
  ) }
);

export type SetProjectLocationMutationVariables = {
  position: Scalars['Float'];
  phase: ProjectPhase;
  projectID: Scalars['UUID'];
};


export type SetProjectLocationMutation = (
  { __typename?: 'Mutation' }
  & { setProjectLocation: (
    { __typename?: 'SetProjectPhasePayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'status' | 'position'>
    ) }
  ) }
);

export type SetProjectNameMutationVariables = {
  name: Scalars['String'];
  projectID: Scalars['UUID'];
};


export type SetProjectNameMutation = (
  { __typename?: 'Mutation' }
  & { setProjectName: (
    { __typename?: 'SetProjectNamePayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    ) }
  ) }
);

export type SetProjectPhaseMutationVariables = {
  phase: ProjectPhase;
  projectID: Scalars['UUID'];
};


export type SetProjectPhaseMutation = (
  { __typename?: 'Mutation' }
  & { setProjectPhase: (
    { __typename?: 'SetProjectPhasePayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'status'>
    ) }
  ) }
);

export type SetWebsiteUrlMutationVariables = {
  projectID: Scalars['UUID'];
  websiteUrl?: Maybe<Scalars['String']>;
};


export type SetWebsiteUrlMutation = (
  { __typename?: 'Mutation' }
  & { setWebsiteURL: (
    { __typename?: 'SetWebsiteURLPayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'websiteUrl'>
    ) }
  ) }
);

export type TagsQueryVariables = {
  projectID: Scalars['UUID'];
};


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: (
    { __typename?: 'TagsPayload' }
    & { tags: Array<(
      { __typename?: 'AttachmentTag' }
      & Pick<AttachmentTag, 'id' | 'name'>
    )> }
  ) }
);

export type ToggleAttachmentTagMutationVariables = {
  attachment: Scalars['UUID'];
  tag: Scalars['UUID'];
};


export type ToggleAttachmentTagMutation = (
  { __typename?: 'Mutation' }
  & { toggleAttachmentTag: (
    { __typename?: 'ToggleAttachmentTagPayload' }
    & { attachment: (
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id'>
      & { tags: Array<(
        { __typename?: 'AttachmentTag' }
        & Pick<AttachmentTag, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type UpdateFeedbackCommentMutationVariables = {
  message: Scalars['String'];
  commentID: Scalars['UUID'];
};


export type UpdateFeedbackCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateFeedbackComment: (
    { __typename?: 'UpdateFeedbackCommentPayload' }
    & { comment: (
      { __typename?: 'ProjectFeedbackComment' }
      & Pick<ProjectFeedbackComment, 'id' | 'updatedAt' | 'summary'>
    ) }
  ) }
);

export type UpdateProjectFeedbackNameMutationVariables = {
  feedbackID: Scalars['UUID'];
  name: Scalars['String'];
};


export type UpdateProjectFeedbackNameMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectFeedbackName: (
    { __typename?: 'UpdateProjectFeedbackNamePayload' }
    & { feedback: (
      { __typename?: 'ProjectFeedback' }
      & Pick<ProjectFeedback, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateProjectFeedbackSummaryMutationVariables = {
  feedbackID: Scalars['UUID'];
  summary: Scalars['String'];
};


export type UpdateProjectFeedbackSummaryMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectFeedbackSummary: (
    { __typename?: 'UpdateProjectFeedbackSummaryPayload' }
    & { feedback: (
      { __typename?: 'ProjectFeedback' }
      & Pick<ProjectFeedback, 'id' | 'summary'>
    ) }
  ) }
);

export type UsersQueryVariables = {};


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'email' | 'username' | 'createdAt' | 'fullName'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'code' | 'name'>
    ) }
  )> }
);

export type RemoveAvatarMutationVariables = {};


export type RemoveAvatarMutation = (
  { __typename?: 'Mutation' }
  & { removeAvatar: (
    { __typename?: 'RemoveAvatarPayload' }
    & { user: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'default' | 'url'>
      ) }
    ) }
  ) }
);

export type CreateTaskMutationVariables = {
  taskGroupID: Scalars['UUID'];
  name: Scalars['String'];
  position: Scalars['Float'];
  status: VisibilityStatus;
};


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask: (
    { __typename?: 'CreateTaskPayload' }
    & Pick<CreateTaskPayload, 'taskGroupID'>
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name' | 'position' | 'status'>
      & { taskGroup: (
        { __typename?: 'TaskGroupMinimal' }
        & Pick<TaskGroupMinimal, 'id'>
      ), comments: Array<(
        { __typename?: 'TaskComment' }
        & Pick<TaskComment, 'id' | 'createdAt' | 'updatedAt' | 'summary'>
        & { createdBy: (
          { __typename?: 'UserAccount' }
          & Pick<UserAccount, 'id' | 'fullName'>
        ) }
      )> }
    ) }
  ) }
);

export type CreateTaskCommentMutationVariables = {
  taskID: Scalars['UUID'];
  summary: Scalars['String'];
};


export type CreateTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { createTaskComment: (
    { __typename?: 'CreateTaskCommentPayload' }
    & { comment: (
      { __typename?: 'TaskComment' }
      & Pick<TaskComment, 'id' | 'summary' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'fullName' | 'email' | 'createdAt' | 'username'>
        & { role: (
          { __typename?: 'Role' }
          & Pick<Role, 'code' | 'name'>
        ) }
      ) }
    ) }
  ) }
);

export type DeleteTaskMutationVariables = {
  taskID: Scalars['UUID'];
};


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask: (
    { __typename?: 'DeleteTaskPayload' }
    & Pick<DeleteTaskPayload, 'taskID' | 'taskGroupID'>
  ) }
);

export type DeleteTaskCommentMutationVariables = {
  commentID: Scalars['UUID'];
};


export type DeleteTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskComment: (
    { __typename?: 'DeleteTaskCommentPayload' }
    & Pick<DeleteTaskCommentPayload, 'commentID'>
  ) }
);

export type FindTaskQueryVariables = {
  taskID: Scalars['UUID'];
};


export type FindTaskQuery = (
  { __typename?: 'Query' }
  & { findTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'status' | 'description'>
    & { taskGroup: (
      { __typename?: 'TaskGroupMinimal' }
      & Pick<TaskGroupMinimal, 'id'>
    ), comments: Array<(
      { __typename?: 'TaskComment' }
      & Pick<TaskComment, 'id' | 'createdAt' | 'updatedAt' | 'summary'>
      & { createdBy: (
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'id' | 'fullName'>
      ) }
    )> }
  ) }
);

export type ToggleTaskCompleteMutationVariables = {
  taskID: Scalars['UUID'];
  complete: Scalars['Boolean'];
};


export type ToggleTaskCompleteMutation = (
  { __typename?: 'Mutation' }
  & { toggleTaskComplete: (
    { __typename?: 'ToggleTaskCompletePayload' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'complete'>
    ) }
  ) }
);

export type UpdateTaskCommentMutationVariables = {
  commentID: Scalars['UUID'];
  summary: Scalars['String'];
};


export type UpdateTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskComment: (
    { __typename?: 'UpdateTaskCommentPayload' }
    & { comment: (
      { __typename?: 'TaskComment' }
      & Pick<TaskComment, 'id' | 'summary'>
    ) }
  ) }
);

export type UpdateTaskDescriptionMutationVariables = {
  taskID: Scalars['UUID'];
  description: Scalars['String'];
};


export type UpdateTaskDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskDescription: (
    { __typename?: 'UpdateTaskDescriptionPayload' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'description'>
    ) }
  ) }
);

export type UpdateTaskLocationMutationVariables = {
  taskID: Scalars['UUID'];
  taskGroupID: Scalars['UUID'];
  position: Scalars['Float'];
};


export type UpdateTaskLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskLocation: (
    { __typename?: 'UpdateTaskLocationPayload' }
    & Pick<UpdateTaskLocationPayload, 'previousTaskGroupID'>
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'position'>
      & { taskGroup: (
        { __typename?: 'TaskGroupMinimal' }
        & Pick<TaskGroupMinimal, 'id'>
      ) }
    ) }
  ) }
);

export type UpdateTaskNameMutationVariables = {
  taskID: Scalars['UUID'];
  name: Scalars['String'];
};


export type UpdateTaskNameMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskName: (
    { __typename?: 'UpdateTaskNamePayload' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateTaskStatusMutationVariables = {
  taskID: Scalars['UUID'];
  status: VisibilityStatus;
};


export type UpdateTaskStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskStatus: (
    { __typename?: 'UpdateTaskStatusPayload' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
    ) }
  ) }
);

export type CreateTaskGroupMutationVariables = {
  projectID: Scalars['UUID'];
  name: Scalars['String'];
  position: Scalars['Float'];
};


export type CreateTaskGroupMutation = (
  { __typename?: 'Mutation' }
  & { createTaskGroup: (
    { __typename?: 'CreateTaskGroupPayload' }
    & Pick<CreateTaskGroupPayload, 'projectID'>
    & { taskGroup: (
      { __typename?: 'TaskGroup' }
      & Pick<TaskGroup, 'id' | 'name' | 'position'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id'>
      )> }
    ) }
  ) }
);

export type DeleteTaskGroupMutationVariables = {
  projectID: Scalars['UUID'];
  taskGroupID: Scalars['UUID'];
};


export type DeleteTaskGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskGroup: (
    { __typename?: 'DeleteTaskGroupPayload' }
    & Pick<DeleteTaskGroupPayload, 'projectID' | 'taskGroupID'>
  ) }
);

export type UpdateTaskGroupPositionMutationVariables = {
  taskGroupID: Scalars['UUID'];
  position: Scalars['Float'];
};


export type UpdateTaskGroupPositionMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskGroupPosition: (
    { __typename?: 'UpdateTaskGroupPositionPayload' }
    & { taskGroup: (
      { __typename?: 'TaskGroup' }
      & Pick<TaskGroup, 'id' | 'position'>
    ) }
  ) }
);

export type UpdateTaskGroupNameMutationVariables = {
  name: Scalars['String'];
  taskGroupID: Scalars['UUID'];
};


export type UpdateTaskGroupNameMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskGroupName: (
    { __typename?: 'UpdateTaskGroupNamePayload' }
    & { taskGroup: (
      { __typename?: 'TaskGroup' }
      & Pick<TaskGroup, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateUserFullnameMutationVariables = {
  fullname: Scalars['String'];
};


export type UpdateUserFullnameMutation = (
  { __typename?: 'Mutation' }
  & { updateUserFullname: (
    { __typename?: 'UpdateUserFullnamePayload' }
    & { user: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id' | 'fullName'>
    ) }
  ) }
);

export type CreateUserAccountMutationVariables = {
  username: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  password: Scalars['String'];
  roleCode: RoleCode;
};


export type CreateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { createUserAccount: (
    { __typename?: 'UserAccount' }
    & Pick<UserAccount, 'id' | 'email' | 'username' | 'fullName' | 'createdAt'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'code' | 'name'>
    ) }
  ) }
);

export type DeleteUserAccountMutationVariables = {
  userID: Scalars['UUID'];
};


export type DeleteUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserAccount: (
    { __typename?: 'DeleteUserAccountPayload' }
    & Pick<DeleteUserAccountPayload, 'userID'>
  ) }
);

export type ResetPasswordMutationVariables = {
  userID: Scalars['UUID'];
  newPassword: Scalars['String'];
};


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'userID' | 'ok'>
  ) }
);

export type UpdateUserRoleMutationVariables = {
  userID: Scalars['UUID'];
  roleCode: RoleCode;
};


export type UpdateUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateUserRole: (
    { __typename?: 'UpdateUserRolePayload' }
    & { user: (
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id'>
      & { role: (
        { __typename?: 'Role' }
        & Pick<Role, 'code' | 'name'>
      ) }
    ) }
  ) }
);


export const InboxDocument = gql`
    query inbox {
  notifications {
    notifications {
      type
      id
      causedBy
      createdAt
      data {
        key
        value
      }
      read
      readAt
    }
  }
}
    `;

/**
 * __useInboxQuery__
 *
 * To run a query within a React component, call `useInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxQuery({
 *   variables: {
 *   },
 * });
 */
export function useInboxQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InboxQuery, InboxQueryVariables>) {
        return ApolloReactHooks.useQuery<InboxQuery, InboxQueryVariables>(InboxDocument, baseOptions);
      }
export function useInboxLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InboxQuery, InboxQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InboxQuery, InboxQueryVariables>(InboxDocument, baseOptions);
        }
export type InboxQueryHookResult = ReturnType<typeof useInboxQuery>;
export type InboxLazyQueryHookResult = ReturnType<typeof useInboxLazyQuery>;
export type InboxQueryResult = ApolloReactCommon.QueryResult<InboxQuery, InboxQueryVariables>;
export const ProjectViewLogDocument = gql`
    query projectViewLog($projectID: UUID!) {
  projectViewLog(input: {projectID: $projectID}) {
    projectID
    logs {
      user {
        userID
        fullname
        username
      }
      count
      lastViewed
    }
  }
}
    `;

/**
 * __useProjectViewLogQuery__
 *
 * To run a query within a React component, call `useProjectViewLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectViewLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectViewLogQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useProjectViewLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectViewLogQuery, ProjectViewLogQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectViewLogQuery, ProjectViewLogQueryVariables>(ProjectViewLogDocument, baseOptions);
      }
export function useProjectViewLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectViewLogQuery, ProjectViewLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectViewLogQuery, ProjectViewLogQueryVariables>(ProjectViewLogDocument, baseOptions);
        }
export type ProjectViewLogQueryHookResult = ReturnType<typeof useProjectViewLogQuery>;
export type ProjectViewLogLazyQueryHookResult = ReturnType<typeof useProjectViewLogLazyQuery>;
export type ProjectViewLogQueryResult = ApolloReactCommon.QueryResult<ProjectViewLogQuery, ProjectViewLogQueryVariables>;
export const MarkInboxReadDocument = gql`
    mutation markInboxRead {
  markInboxRead {
    ok
  }
}
    `;
export type MarkInboxReadMutationFn = ApolloReactCommon.MutationFunction<MarkInboxReadMutation, MarkInboxReadMutationVariables>;

/**
 * __useMarkInboxReadMutation__
 *
 * To run a mutation, you first call `useMarkInboxReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInboxReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInboxReadMutation, { data, loading, error }] = useMarkInboxReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkInboxReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkInboxReadMutation, MarkInboxReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkInboxReadMutation, MarkInboxReadMutationVariables>(MarkInboxReadDocument, baseOptions);
      }
export type MarkInboxReadMutationHookResult = ReturnType<typeof useMarkInboxReadMutation>;
export type MarkInboxReadMutationResult = ApolloReactCommon.MutationResult<MarkInboxReadMutation>;
export type MarkInboxReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkInboxReadMutation, MarkInboxReadMutationVariables>;
export const MeDocument = gql`
    query me {
  notificationsUnreadCount {
    count
  }
  me {
    id
    fullName
    username
    email
    role {
      code
    }
    avatar {
      url
      default
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const AllFeedbackDocument = gql`
    query allFeedback($projectID: UUID!) {
  allFeedback(input: {projectID: $projectID}) {
    feedback {
      id
      name
      createdAt
    }
  }
}
    `;

/**
 * __useAllFeedbackQuery__
 *
 * To run a query within a React component, call `useAllFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeedbackQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useAllFeedbackQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllFeedbackQuery, AllFeedbackQueryVariables>) {
        return ApolloReactHooks.useQuery<AllFeedbackQuery, AllFeedbackQueryVariables>(AllFeedbackDocument, baseOptions);
      }
export function useAllFeedbackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllFeedbackQuery, AllFeedbackQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllFeedbackQuery, AllFeedbackQueryVariables>(AllFeedbackDocument, baseOptions);
        }
export type AllFeedbackQueryHookResult = ReturnType<typeof useAllFeedbackQuery>;
export type AllFeedbackLazyQueryHookResult = ReturnType<typeof useAllFeedbackLazyQuery>;
export type AllFeedbackQueryResult = ApolloReactCommon.QueryResult<AllFeedbackQuery, AllFeedbackQueryVariables>;
export const ProjectsDocument = gql`
    query projects {
  projects {
    id
    name
    position
    createdAt
    status
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
      }
export function useProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = ApolloReactCommon.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const AttachmentsDocument = gql`
    query attachments($projectID: UUID!) {
  categories(input: {projectID: $projectID}) {
    categories {
      id
      name
    }
  }
  tags(input: {projectID: $projectID}) {
    tags {
      id
      name
    }
  }
  attachments(input: {projectID: $projectID}) {
    attachments {
      id
      filepath
      filename
      size
      uploadedBy {
        id
        fullname
        avatar {
          url
          default
        }
      }
      uploadedAt
      category {
        id
        name
      }
      tags {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAttachmentsQuery__
 *
 * To run a query within a React component, call `useAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useAttachmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttachmentsQuery, AttachmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<AttachmentsQuery, AttachmentsQueryVariables>(AttachmentsDocument, baseOptions);
      }
export function useAttachmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttachmentsQuery, AttachmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttachmentsQuery, AttachmentsQueryVariables>(AttachmentsDocument, baseOptions);
        }
export type AttachmentsQueryHookResult = ReturnType<typeof useAttachmentsQuery>;
export type AttachmentsLazyQueryHookResult = ReturnType<typeof useAttachmentsLazyQuery>;
export type AttachmentsQueryResult = ApolloReactCommon.QueryResult<AttachmentsQuery, AttachmentsQueryVariables>;
export const CategoriesDocument = gql`
    query categories($projectID: UUID!) {
  categories(input: {projectID: $projectID}) {
    categories {
      id
      name
    }
  }
  tags(input: {projectID: $projectID}) {
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
      }
export function useCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = ApolloReactCommon.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CreateAttachmentCategoryDocument = gql`
    mutation createAttachmentCategory($projectID: UUID!, $name: String!) {
  createAttachmentCategory(input: {projectID: $projectID, name: $name}) {
    category {
      id
      name
    }
  }
}
    `;
export type CreateAttachmentCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>;

/**
 * __useCreateAttachmentCategoryMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentCategoryMutation, { data, loading, error }] = useCreateAttachmentCategoryMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateAttachmentCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>(CreateAttachmentCategoryDocument, baseOptions);
      }
export type CreateAttachmentCategoryMutationHookResult = ReturnType<typeof useCreateAttachmentCategoryMutation>;
export type CreateAttachmentCategoryMutationResult = ApolloReactCommon.MutationResult<CreateAttachmentCategoryMutation>;
export type CreateAttachmentCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>;
export const CreateProjectFeedbackDocument = gql`
    mutation createProjectFeedback($projectID: UUID!, $name: String!, $summary: String!) {
  createProjectFeedback(input: {projectID: $projectID, name: $name, summary: $summary}) {
    feedback {
      id
      name
      createdAt
    }
  }
}
    `;
export type CreateProjectFeedbackMutationFn = ApolloReactCommon.MutationFunction<CreateProjectFeedbackMutation, CreateProjectFeedbackMutationVariables>;

/**
 * __useCreateProjectFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateProjectFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFeedbackMutation, { data, loading, error }] = useCreateProjectFeedbackMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      name: // value for 'name'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useCreateProjectFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectFeedbackMutation, CreateProjectFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectFeedbackMutation, CreateProjectFeedbackMutationVariables>(CreateProjectFeedbackDocument, baseOptions);
      }
export type CreateProjectFeedbackMutationHookResult = ReturnType<typeof useCreateProjectFeedbackMutation>;
export type CreateProjectFeedbackMutationResult = ApolloReactCommon.MutationResult<CreateProjectFeedbackMutation>;
export type CreateProjectFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectFeedbackMutation, CreateProjectFeedbackMutationVariables>;
export const CreateFeedbackCommentDocument = gql`
    mutation createFeedbackComment($summary: String!, $feedbackID: UUID!) {
  createFeedbackComment(input: {feedbackID: $feedbackID, summary: $summary}) {
    comment {
      id
      createdAt
      summary
      createdBy {
        id
        username
      }
    }
  }
}
    `;
export type CreateFeedbackCommentMutationFn = ApolloReactCommon.MutationFunction<CreateFeedbackCommentMutation, CreateFeedbackCommentMutationVariables>;

/**
 * __useCreateFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackCommentMutation, { data, loading, error }] = useCreateFeedbackCommentMutation({
 *   variables: {
 *      summary: // value for 'summary'
 *      feedbackID: // value for 'feedbackID'
 *   },
 * });
 */
export function useCreateFeedbackCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFeedbackCommentMutation, CreateFeedbackCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFeedbackCommentMutation, CreateFeedbackCommentMutationVariables>(CreateFeedbackCommentDocument, baseOptions);
      }
export type CreateFeedbackCommentMutationHookResult = ReturnType<typeof useCreateFeedbackCommentMutation>;
export type CreateFeedbackCommentMutationResult = ApolloReactCommon.MutationResult<CreateFeedbackCommentMutation>;
export type CreateFeedbackCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFeedbackCommentMutation, CreateFeedbackCommentMutationVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($name: String!, $phase: ProjectPhase!) {
  createProject(input: {name: $name, phase: $phase}) {
    project {
      id
      name
      createdAt
      status
      position
    }
  }
}
    `;
export type CreateProjectMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phase: // value for 'phase'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateProjectMemberDocument = gql`
    mutation createProjectMember($projectID: UUID!, $users: [UUID!]!) {
  createProjectMember(input: {projectID: $projectID, users: $users}) {
    users {
      id
      email
      fullName
      createdAt
      username
      role {
        code
        name
      }
    }
  }
}
    `;
export type CreateProjectMemberMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMemberMutation, CreateProjectMemberMutationVariables>;

/**
 * __useCreateProjectMemberMutation__
 *
 * To run a mutation, you first call `useCreateProjectMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMemberMutation, { data, loading, error }] = useCreateProjectMemberMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useCreateProjectMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMemberMutation, CreateProjectMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMemberMutation, CreateProjectMemberMutationVariables>(CreateProjectMemberDocument, baseOptions);
      }
export type CreateProjectMemberMutationHookResult = ReturnType<typeof useCreateProjectMemberMutation>;
export type CreateProjectMemberMutationResult = ApolloReactCommon.MutationResult<CreateProjectMemberMutation>;
export type CreateProjectMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMemberMutation, CreateProjectMemberMutationVariables>;
export const CreateAttachmentTagDocument = gql`
    mutation createAttachmentTag($projectID: UUID!, $name: String!) {
  createAttachmentTag(input: {projectID: $projectID, name: $name}) {
    tag {
      id
      name
    }
  }
}
    `;
export type CreateAttachmentTagMutationFn = ApolloReactCommon.MutationFunction<CreateAttachmentTagMutation, CreateAttachmentTagMutationVariables>;

/**
 * __useCreateAttachmentTagMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentTagMutation, { data, loading, error }] = useCreateAttachmentTagMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateAttachmentTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAttachmentTagMutation, CreateAttachmentTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAttachmentTagMutation, CreateAttachmentTagMutationVariables>(CreateAttachmentTagDocument, baseOptions);
      }
export type CreateAttachmentTagMutationHookResult = ReturnType<typeof useCreateAttachmentTagMutation>;
export type CreateAttachmentTagMutationResult = ApolloReactCommon.MutationResult<CreateAttachmentTagMutation>;
export type CreateAttachmentTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAttachmentTagMutation, CreateAttachmentTagMutationVariables>;
export const DeleteAttachmentDocument = gql`
    mutation deleteAttachment($id: UUID!) {
  deleteAttachment(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteAttachmentMutationFn = ApolloReactCommon.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, baseOptions);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = ApolloReactCommon.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const DeleteAttachmentCategoryDocument = gql`
    mutation deleteAttachmentCategory($id: UUID!) {
  deleteAttachmentCategory(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteAttachmentCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>;

/**
 * __useDeleteAttachmentCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentCategoryMutation, { data, loading, error }] = useDeleteAttachmentCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttachmentCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>(DeleteAttachmentCategoryDocument, baseOptions);
      }
export type DeleteAttachmentCategoryMutationHookResult = ReturnType<typeof useDeleteAttachmentCategoryMutation>;
export type DeleteAttachmentCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteAttachmentCategoryMutation>;
export type DeleteAttachmentCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>;
export const DeleteAttachmentTagDocument = gql`
    mutation deleteAttachmentTag($id: UUID!) {
  deleteAttachmentTag(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteAttachmentTagMutationFn = ApolloReactCommon.MutationFunction<DeleteAttachmentTagMutation, DeleteAttachmentTagMutationVariables>;

/**
 * __useDeleteAttachmentTagMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentTagMutation, { data, loading, error }] = useDeleteAttachmentTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttachmentTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttachmentTagMutation, DeleteAttachmentTagMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAttachmentTagMutation, DeleteAttachmentTagMutationVariables>(DeleteAttachmentTagDocument, baseOptions);
      }
export type DeleteAttachmentTagMutationHookResult = ReturnType<typeof useDeleteAttachmentTagMutation>;
export type DeleteAttachmentTagMutationResult = ApolloReactCommon.MutationResult<DeleteAttachmentTagMutation>;
export type DeleteAttachmentTagMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttachmentTagMutation, DeleteAttachmentTagMutationVariables>;
export const DeleteProjectFeedbackDocument = gql`
    mutation deleteProjectFeedback($feedbackID: UUID!) {
  deleteProjectFeedback(input: {feedbackID: $feedbackID}) {
    feedbackID
  }
}
    `;
export type DeleteProjectFeedbackMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectFeedbackMutation, DeleteProjectFeedbackMutationVariables>;

/**
 * __useDeleteProjectFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteProjectFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectFeedbackMutation, { data, loading, error }] = useDeleteProjectFeedbackMutation({
 *   variables: {
 *      feedbackID: // value for 'feedbackID'
 *   },
 * });
 */
export function useDeleteProjectFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectFeedbackMutation, DeleteProjectFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectFeedbackMutation, DeleteProjectFeedbackMutationVariables>(DeleteProjectFeedbackDocument, baseOptions);
      }
export type DeleteProjectFeedbackMutationHookResult = ReturnType<typeof useDeleteProjectFeedbackMutation>;
export type DeleteProjectFeedbackMutationResult = ApolloReactCommon.MutationResult<DeleteProjectFeedbackMutation>;
export type DeleteProjectFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectFeedbackMutation, DeleteProjectFeedbackMutationVariables>;
export const DeleteFeedbackCommentDocument = gql`
    mutation deleteFeedbackComment($feedbackCommentID: UUID!) {
  deleteFeedbackComment(input: {feedbackCommentID: $feedbackCommentID}) {
    feedbackCommentID
  }
}
    `;
export type DeleteFeedbackCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteFeedbackCommentMutation, DeleteFeedbackCommentMutationVariables>;

/**
 * __useDeleteFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackCommentMutation, { data, loading, error }] = useDeleteFeedbackCommentMutation({
 *   variables: {
 *      feedbackCommentID: // value for 'feedbackCommentID'
 *   },
 * });
 */
export function useDeleteFeedbackCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFeedbackCommentMutation, DeleteFeedbackCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFeedbackCommentMutation, DeleteFeedbackCommentMutationVariables>(DeleteFeedbackCommentDocument, baseOptions);
      }
export type DeleteFeedbackCommentMutationHookResult = ReturnType<typeof useDeleteFeedbackCommentMutation>;
export type DeleteFeedbackCommentMutationResult = ApolloReactCommon.MutationResult<DeleteFeedbackCommentMutation>;
export type DeleteFeedbackCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFeedbackCommentMutation, DeleteFeedbackCommentMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($projectID: UUID!) {
  deleteProject(input: {projectID: $projectID}) {
    projectID
  }
}
    `;
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const DeleteProjectMemberDocument = gql`
    mutation deleteProjectMember($projectID: UUID!, $userID: UUID!) {
  deleteProjectMember(input: {projectID: $projectID, userID: $userID}) {
    userID
  }
}
    `;
export type DeleteProjectMemberMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMemberMutation, DeleteProjectMemberMutationVariables>;

/**
 * __useDeleteProjectMemberMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMemberMutation, { data, loading, error }] = useDeleteProjectMemberMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useDeleteProjectMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMemberMutation, DeleteProjectMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMemberMutation, DeleteProjectMemberMutationVariables>(DeleteProjectMemberDocument, baseOptions);
      }
export type DeleteProjectMemberMutationHookResult = ReturnType<typeof useDeleteProjectMemberMutation>;
export type DeleteProjectMemberMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMemberMutation>;
export type DeleteProjectMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMemberMutation, DeleteProjectMemberMutationVariables>;
export const FeedbackDocument = gql`
    query feedback($projectID: UUID!, $feedbackID: UUID) {
  feedback(input: {projectID: $projectID, feedbackID: $feedbackID}) {
    feedback {
      id
      name
      createdAt
      summary
      createdBy {
        id
        fullName
      }
      comments {
        id
        summary
        createdAt
        createdBy {
          id
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useFeedbackQuery__
 *
 * To run a query within a React component, call `useFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      feedbackID: // value for 'feedbackID'
 *   },
 * });
 */
export function useFeedbackQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeedbackQuery, FeedbackQueryVariables>) {
        return ApolloReactHooks.useQuery<FeedbackQuery, FeedbackQueryVariables>(FeedbackDocument, baseOptions);
      }
export function useFeedbackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeedbackQuery, FeedbackQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeedbackQuery, FeedbackQueryVariables>(FeedbackDocument, baseOptions);
        }
export type FeedbackQueryHookResult = ReturnType<typeof useFeedbackQuery>;
export type FeedbackLazyQueryHookResult = ReturnType<typeof useFeedbackLazyQuery>;
export type FeedbackQueryResult = ApolloReactCommon.QueryResult<FeedbackQuery, FeedbackQueryVariables>;
export const FindProjectDocument = gql`
    query findProject($projectID: UUID!) {
  findProject(input: {projectID: $projectID}) {
    id
    name
    createdAt
    status
    websiteUrl
    meetingUrl
    members {
      id
      fullName
      email
    }
    taskGroups {
      id
      name
      position
      tasks {
        id
        name
        position
        status
        complete
        badges {
          comments
          description
        }
        taskGroup {
          id
        }
        comments {
          id
          updatedAt
          createdAt
          summary
          createdBy {
            id
            fullName
            email
            createdAt
            username
            role {
              code
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFindProjectQuery__
 *
 * To run a query within a React component, call `useFindProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProjectQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useFindProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindProjectQuery, FindProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<FindProjectQuery, FindProjectQueryVariables>(FindProjectDocument, baseOptions);
      }
export function useFindProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindProjectQuery, FindProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindProjectQuery, FindProjectQueryVariables>(FindProjectDocument, baseOptions);
        }
export type FindProjectQueryHookResult = ReturnType<typeof useFindProjectQuery>;
export type FindProjectLazyQueryHookResult = ReturnType<typeof useFindProjectLazyQuery>;
export type FindProjectQueryResult = ApolloReactCommon.QueryResult<FindProjectQuery, FindProjectQueryVariables>;
export const RequestZipDocument = gql`
    mutation requestZip($projectID: UUID!, $attachments: [UUID!]!) {
  requestZip(input: {projectID: $projectID, attachments: $attachments}) {
    requestKey
  }
}
    `;
export type RequestZipMutationFn = ApolloReactCommon.MutationFunction<RequestZipMutation, RequestZipMutationVariables>;

/**
 * __useRequestZipMutation__
 *
 * To run a mutation, you first call `useRequestZipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestZipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestZipMutation, { data, loading, error }] = useRequestZipMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useRequestZipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestZipMutation, RequestZipMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestZipMutation, RequestZipMutationVariables>(RequestZipDocument, baseOptions);
      }
export type RequestZipMutationHookResult = ReturnType<typeof useRequestZipMutation>;
export type RequestZipMutationResult = ApolloReactCommon.MutationResult<RequestZipMutation>;
export type RequestZipMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestZipMutation, RequestZipMutationVariables>;
export const RequestZipStatusDocument = gql`
    query requestZipStatus($requestKey: UUID!) {
  requestZipStatus(input: {requestKey: $requestKey}) {
    complete
    filepath
  }
}
    `;

/**
 * __useRequestZipStatusQuery__
 *
 * To run a query within a React component, call `useRequestZipStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestZipStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestZipStatusQuery({
 *   variables: {
 *      requestKey: // value for 'requestKey'
 *   },
 * });
 */
export function useRequestZipStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequestZipStatusQuery, RequestZipStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<RequestZipStatusQuery, RequestZipStatusQueryVariables>(RequestZipStatusDocument, baseOptions);
      }
export function useRequestZipStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequestZipStatusQuery, RequestZipStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RequestZipStatusQuery, RequestZipStatusQueryVariables>(RequestZipStatusDocument, baseOptions);
        }
export type RequestZipStatusQueryHookResult = ReturnType<typeof useRequestZipStatusQuery>;
export type RequestZipStatusLazyQueryHookResult = ReturnType<typeof useRequestZipStatusLazyQuery>;
export type RequestZipStatusQueryResult = ApolloReactCommon.QueryResult<RequestZipStatusQuery, RequestZipStatusQueryVariables>;
export const SetMeetingUrlDocument = gql`
    mutation setMeetingUrl($projectID: UUID!, $meetingUrl: String) {
  setMeetingURL(input: {projectID: $projectID, url: $meetingUrl}) {
    project {
      id
      meetingUrl
    }
  }
}
    `;
export type SetMeetingUrlMutationFn = ApolloReactCommon.MutationFunction<SetMeetingUrlMutation, SetMeetingUrlMutationVariables>;

/**
 * __useSetMeetingUrlMutation__
 *
 * To run a mutation, you first call `useSetMeetingUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMeetingUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMeetingUrlMutation, { data, loading, error }] = useSetMeetingUrlMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      meetingUrl: // value for 'meetingUrl'
 *   },
 * });
 */
export function useSetMeetingUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMeetingUrlMutation, SetMeetingUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<SetMeetingUrlMutation, SetMeetingUrlMutationVariables>(SetMeetingUrlDocument, baseOptions);
      }
export type SetMeetingUrlMutationHookResult = ReturnType<typeof useSetMeetingUrlMutation>;
export type SetMeetingUrlMutationResult = ApolloReactCommon.MutationResult<SetMeetingUrlMutation>;
export type SetMeetingUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMeetingUrlMutation, SetMeetingUrlMutationVariables>;
export const SetProjectLocationDocument = gql`
    mutation setProjectLocation($position: Float!, $phase: ProjectPhase!, $projectID: UUID!) {
  setProjectLocation(input: {phase: $phase, projectID: $projectID, position: $position}) {
    project {
      id
      status
      position
    }
  }
}
    `;
export type SetProjectLocationMutationFn = ApolloReactCommon.MutationFunction<SetProjectLocationMutation, SetProjectLocationMutationVariables>;

/**
 * __useSetProjectLocationMutation__
 *
 * To run a mutation, you first call `useSetProjectLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectLocationMutation, { data, loading, error }] = useSetProjectLocationMutation({
 *   variables: {
 *      position: // value for 'position'
 *      phase: // value for 'phase'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useSetProjectLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetProjectLocationMutation, SetProjectLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<SetProjectLocationMutation, SetProjectLocationMutationVariables>(SetProjectLocationDocument, baseOptions);
      }
export type SetProjectLocationMutationHookResult = ReturnType<typeof useSetProjectLocationMutation>;
export type SetProjectLocationMutationResult = ApolloReactCommon.MutationResult<SetProjectLocationMutation>;
export type SetProjectLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<SetProjectLocationMutation, SetProjectLocationMutationVariables>;
export const SetProjectNameDocument = gql`
    mutation setProjectName($name: String!, $projectID: UUID!) {
  setProjectName(input: {projectID: $projectID, name: $name}) {
    project {
      id
      name
    }
  }
}
    `;
export type SetProjectNameMutationFn = ApolloReactCommon.MutationFunction<SetProjectNameMutation, SetProjectNameMutationVariables>;

/**
 * __useSetProjectNameMutation__
 *
 * To run a mutation, you first call `useSetProjectNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectNameMutation, { data, loading, error }] = useSetProjectNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useSetProjectNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetProjectNameMutation, SetProjectNameMutationVariables>) {
        return ApolloReactHooks.useMutation<SetProjectNameMutation, SetProjectNameMutationVariables>(SetProjectNameDocument, baseOptions);
      }
export type SetProjectNameMutationHookResult = ReturnType<typeof useSetProjectNameMutation>;
export type SetProjectNameMutationResult = ApolloReactCommon.MutationResult<SetProjectNameMutation>;
export type SetProjectNameMutationOptions = ApolloReactCommon.BaseMutationOptions<SetProjectNameMutation, SetProjectNameMutationVariables>;
export const SetProjectPhaseDocument = gql`
    mutation setProjectPhase($phase: ProjectPhase!, $projectID: UUID!) {
  setProjectPhase(input: {phase: $phase, projectID: $projectID}) {
    project {
      id
      status
    }
  }
}
    `;
export type SetProjectPhaseMutationFn = ApolloReactCommon.MutationFunction<SetProjectPhaseMutation, SetProjectPhaseMutationVariables>;

/**
 * __useSetProjectPhaseMutation__
 *
 * To run a mutation, you first call `useSetProjectPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectPhaseMutation, { data, loading, error }] = useSetProjectPhaseMutation({
 *   variables: {
 *      phase: // value for 'phase'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useSetProjectPhaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetProjectPhaseMutation, SetProjectPhaseMutationVariables>) {
        return ApolloReactHooks.useMutation<SetProjectPhaseMutation, SetProjectPhaseMutationVariables>(SetProjectPhaseDocument, baseOptions);
      }
export type SetProjectPhaseMutationHookResult = ReturnType<typeof useSetProjectPhaseMutation>;
export type SetProjectPhaseMutationResult = ApolloReactCommon.MutationResult<SetProjectPhaseMutation>;
export type SetProjectPhaseMutationOptions = ApolloReactCommon.BaseMutationOptions<SetProjectPhaseMutation, SetProjectPhaseMutationVariables>;
export const SetWebsiteUrlDocument = gql`
    mutation setWebsiteUrl($projectID: UUID!, $websiteUrl: String) {
  setWebsiteURL(input: {projectID: $projectID, websiteURL: $websiteUrl}) {
    project {
      id
      websiteUrl
    }
  }
}
    `;
export type SetWebsiteUrlMutationFn = ApolloReactCommon.MutationFunction<SetWebsiteUrlMutation, SetWebsiteUrlMutationVariables>;

/**
 * __useSetWebsiteUrlMutation__
 *
 * To run a mutation, you first call `useSetWebsiteUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWebsiteUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWebsiteUrlMutation, { data, loading, error }] = useSetWebsiteUrlMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useSetWebsiteUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetWebsiteUrlMutation, SetWebsiteUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<SetWebsiteUrlMutation, SetWebsiteUrlMutationVariables>(SetWebsiteUrlDocument, baseOptions);
      }
export type SetWebsiteUrlMutationHookResult = ReturnType<typeof useSetWebsiteUrlMutation>;
export type SetWebsiteUrlMutationResult = ApolloReactCommon.MutationResult<SetWebsiteUrlMutation>;
export type SetWebsiteUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<SetWebsiteUrlMutation, SetWebsiteUrlMutationVariables>;
export const TagsDocument = gql`
    query tags($projectID: UUID!) {
  tags(input: {projectID: $projectID}) {
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return ApolloReactHooks.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = ApolloReactCommon.QueryResult<TagsQuery, TagsQueryVariables>;
export const ToggleAttachmentTagDocument = gql`
    mutation toggleAttachmentTag($attachment: UUID!, $tag: UUID!) {
  toggleAttachmentTag(input: {attachment: $attachment, tag: $tag}) {
    attachment {
      id
      tags {
        id
        name
      }
    }
  }
}
    `;
export type ToggleAttachmentTagMutationFn = ApolloReactCommon.MutationFunction<ToggleAttachmentTagMutation, ToggleAttachmentTagMutationVariables>;

/**
 * __useToggleAttachmentTagMutation__
 *
 * To run a mutation, you first call `useToggleAttachmentTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAttachmentTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAttachmentTagMutation, { data, loading, error }] = useToggleAttachmentTagMutation({
 *   variables: {
 *      attachment: // value for 'attachment'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useToggleAttachmentTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleAttachmentTagMutation, ToggleAttachmentTagMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleAttachmentTagMutation, ToggleAttachmentTagMutationVariables>(ToggleAttachmentTagDocument, baseOptions);
      }
export type ToggleAttachmentTagMutationHookResult = ReturnType<typeof useToggleAttachmentTagMutation>;
export type ToggleAttachmentTagMutationResult = ApolloReactCommon.MutationResult<ToggleAttachmentTagMutation>;
export type ToggleAttachmentTagMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleAttachmentTagMutation, ToggleAttachmentTagMutationVariables>;
export const UpdateFeedbackCommentDocument = gql`
    mutation updateFeedbackComment($message: String!, $commentID: UUID!) {
  updateFeedbackComment(input: {commentID: $commentID, message: $message}) {
    comment {
      id
      updatedAt
      summary
    }
  }
}
    `;
export type UpdateFeedbackCommentMutationFn = ApolloReactCommon.MutationFunction<UpdateFeedbackCommentMutation, UpdateFeedbackCommentMutationVariables>;

/**
 * __useUpdateFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackCommentMutation, { data, loading, error }] = useUpdateFeedbackCommentMutation({
 *   variables: {
 *      message: // value for 'message'
 *      commentID: // value for 'commentID'
 *   },
 * });
 */
export function useUpdateFeedbackCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFeedbackCommentMutation, UpdateFeedbackCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFeedbackCommentMutation, UpdateFeedbackCommentMutationVariables>(UpdateFeedbackCommentDocument, baseOptions);
      }
export type UpdateFeedbackCommentMutationHookResult = ReturnType<typeof useUpdateFeedbackCommentMutation>;
export type UpdateFeedbackCommentMutationResult = ApolloReactCommon.MutationResult<UpdateFeedbackCommentMutation>;
export type UpdateFeedbackCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFeedbackCommentMutation, UpdateFeedbackCommentMutationVariables>;
export const UpdateProjectFeedbackNameDocument = gql`
    mutation updateProjectFeedbackName($feedbackID: UUID!, $name: String!) {
  updateProjectFeedbackName(input: {feedbackID: $feedbackID, name: $name}) {
    feedback {
      id
      name
    }
  }
}
    `;
export type UpdateProjectFeedbackNameMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectFeedbackNameMutation, UpdateProjectFeedbackNameMutationVariables>;

/**
 * __useUpdateProjectFeedbackNameMutation__
 *
 * To run a mutation, you first call `useUpdateProjectFeedbackNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectFeedbackNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectFeedbackNameMutation, { data, loading, error }] = useUpdateProjectFeedbackNameMutation({
 *   variables: {
 *      feedbackID: // value for 'feedbackID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectFeedbackNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectFeedbackNameMutation, UpdateProjectFeedbackNameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectFeedbackNameMutation, UpdateProjectFeedbackNameMutationVariables>(UpdateProjectFeedbackNameDocument, baseOptions);
      }
export type UpdateProjectFeedbackNameMutationHookResult = ReturnType<typeof useUpdateProjectFeedbackNameMutation>;
export type UpdateProjectFeedbackNameMutationResult = ApolloReactCommon.MutationResult<UpdateProjectFeedbackNameMutation>;
export type UpdateProjectFeedbackNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectFeedbackNameMutation, UpdateProjectFeedbackNameMutationVariables>;
export const UpdateProjectFeedbackSummaryDocument = gql`
    mutation updateProjectFeedbackSummary($feedbackID: UUID!, $summary: String!) {
  updateProjectFeedbackSummary(input: {feedbackID: $feedbackID, summary: $summary}) {
    feedback {
      id
      summary
    }
  }
}
    `;
export type UpdateProjectFeedbackSummaryMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectFeedbackSummaryMutation, UpdateProjectFeedbackSummaryMutationVariables>;

/**
 * __useUpdateProjectFeedbackSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateProjectFeedbackSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectFeedbackSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectFeedbackSummaryMutation, { data, loading, error }] = useUpdateProjectFeedbackSummaryMutation({
 *   variables: {
 *      feedbackID: // value for 'feedbackID'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateProjectFeedbackSummaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectFeedbackSummaryMutation, UpdateProjectFeedbackSummaryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectFeedbackSummaryMutation, UpdateProjectFeedbackSummaryMutationVariables>(UpdateProjectFeedbackSummaryDocument, baseOptions);
      }
export type UpdateProjectFeedbackSummaryMutationHookResult = ReturnType<typeof useUpdateProjectFeedbackSummaryMutation>;
export type UpdateProjectFeedbackSummaryMutationResult = ApolloReactCommon.MutationResult<UpdateProjectFeedbackSummaryMutation>;
export type UpdateProjectFeedbackSummaryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectFeedbackSummaryMutation, UpdateProjectFeedbackSummaryMutationVariables>;
export const UsersDocument = gql`
    query users {
  users {
    id
    email
    username
    createdAt
    fullName
    role {
      code
      name
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const RemoveAvatarDocument = gql`
    mutation removeAvatar {
  removeAvatar {
    user {
      id
      avatar {
        default
        url
      }
    }
  }
}
    `;
export type RemoveAvatarMutationFn = ApolloReactCommon.MutationFunction<RemoveAvatarMutation, RemoveAvatarMutationVariables>;

/**
 * __useRemoveAvatarMutation__
 *
 * To run a mutation, you first call `useRemoveAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAvatarMutation, { data, loading, error }] = useRemoveAvatarMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAvatarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAvatarMutation, RemoveAvatarMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAvatarMutation, RemoveAvatarMutationVariables>(RemoveAvatarDocument, baseOptions);
      }
export type RemoveAvatarMutationHookResult = ReturnType<typeof useRemoveAvatarMutation>;
export type RemoveAvatarMutationResult = ApolloReactCommon.MutationResult<RemoveAvatarMutation>;
export type RemoveAvatarMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAvatarMutation, RemoveAvatarMutationVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($taskGroupID: UUID!, $name: String!, $position: Float!, $status: VisibilityStatus!) {
  createTask(input: {taskGroupID: $taskGroupID, name: $name, position: $position, status: $status}) {
    taskGroupID
    task {
      id
      name
      position
      status
      taskGroup {
        id
      }
      comments {
        id
        createdAt
        updatedAt
        summary
        createdBy {
          id
          fullName
        }
      }
    }
  }
}
    `;
export type CreateTaskMutationFn = ApolloReactCommon.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      taskGroupID: // value for 'taskGroupID'
 *      name: // value for 'name'
 *      position: // value for 'position'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, baseOptions);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = ApolloReactCommon.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const CreateTaskCommentDocument = gql`
    mutation createTaskComment($taskID: UUID!, $summary: String!) {
  createTaskComment(input: {taskID: $taskID, summary: $summary}) {
    comment {
      id
      summary
      createdAt
      updatedAt
      createdBy {
        id
        fullName
        email
        createdAt
        username
        role {
          code
          name
        }
      }
    }
  }
}
    `;
export type CreateTaskCommentMutationFn = ApolloReactCommon.MutationFunction<CreateTaskCommentMutation, CreateTaskCommentMutationVariables>;

/**
 * __useCreateTaskCommentMutation__
 *
 * To run a mutation, you first call `useCreateTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskCommentMutation, { data, loading, error }] = useCreateTaskCommentMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useCreateTaskCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaskCommentMutation, CreateTaskCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTaskCommentMutation, CreateTaskCommentMutationVariables>(CreateTaskCommentDocument, baseOptions);
      }
export type CreateTaskCommentMutationHookResult = ReturnType<typeof useCreateTaskCommentMutation>;
export type CreateTaskCommentMutationResult = ApolloReactCommon.MutationResult<CreateTaskCommentMutation>;
export type CreateTaskCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTaskCommentMutation, CreateTaskCommentMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($taskID: UUID!) {
  deleteTask(input: {taskID: $taskID}) {
    taskID
    taskGroupID
  }
}
    `;
export type DeleteTaskMutationFn = ApolloReactCommon.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, baseOptions);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = ApolloReactCommon.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const DeleteTaskCommentDocument = gql`
    mutation deleteTaskComment($commentID: UUID!) {
  deleteTaskComment(input: {commentID: $commentID}) {
    commentID
  }
}
    `;
export type DeleteTaskCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteTaskCommentMutation, DeleteTaskCommentMutationVariables>;

/**
 * __useDeleteTaskCommentMutation__
 *
 * To run a mutation, you first call `useDeleteTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskCommentMutation, { data, loading, error }] = useDeleteTaskCommentMutation({
 *   variables: {
 *      commentID: // value for 'commentID'
 *   },
 * });
 */
export function useDeleteTaskCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaskCommentMutation, DeleteTaskCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaskCommentMutation, DeleteTaskCommentMutationVariables>(DeleteTaskCommentDocument, baseOptions);
      }
export type DeleteTaskCommentMutationHookResult = ReturnType<typeof useDeleteTaskCommentMutation>;
export type DeleteTaskCommentMutationResult = ApolloReactCommon.MutationResult<DeleteTaskCommentMutation>;
export type DeleteTaskCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaskCommentMutation, DeleteTaskCommentMutationVariables>;
export const FindTaskDocument = gql`
    query findTask($taskID: UUID!) {
  findTask(input: {taskID: $taskID}) {
    id
    name
    status
    description
    taskGroup {
      id
    }
    comments {
      id
      createdAt
      updatedAt
      summary
      createdBy {
        id
        fullName
      }
    }
  }
}
    `;

/**
 * __useFindTaskQuery__
 *
 * To run a query within a React component, call `useFindTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTaskQuery({
 *   variables: {
 *      taskID: // value for 'taskID'
 *   },
 * });
 */
export function useFindTaskQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindTaskQuery, FindTaskQueryVariables>) {
        return ApolloReactHooks.useQuery<FindTaskQuery, FindTaskQueryVariables>(FindTaskDocument, baseOptions);
      }
export function useFindTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindTaskQuery, FindTaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindTaskQuery, FindTaskQueryVariables>(FindTaskDocument, baseOptions);
        }
export type FindTaskQueryHookResult = ReturnType<typeof useFindTaskQuery>;
export type FindTaskLazyQueryHookResult = ReturnType<typeof useFindTaskLazyQuery>;
export type FindTaskQueryResult = ApolloReactCommon.QueryResult<FindTaskQuery, FindTaskQueryVariables>;
export const ToggleTaskCompleteDocument = gql`
    mutation toggleTaskComplete($taskID: UUID!, $complete: Boolean!) {
  toggleTaskComplete(input: {taskID: $taskID, complete: $complete}) {
    task {
      id
      complete
    }
  }
}
    `;
export type ToggleTaskCompleteMutationFn = ApolloReactCommon.MutationFunction<ToggleTaskCompleteMutation, ToggleTaskCompleteMutationVariables>;

/**
 * __useToggleTaskCompleteMutation__
 *
 * To run a mutation, you first call `useToggleTaskCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTaskCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTaskCompleteMutation, { data, loading, error }] = useToggleTaskCompleteMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      complete: // value for 'complete'
 *   },
 * });
 */
export function useToggleTaskCompleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleTaskCompleteMutation, ToggleTaskCompleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleTaskCompleteMutation, ToggleTaskCompleteMutationVariables>(ToggleTaskCompleteDocument, baseOptions);
      }
export type ToggleTaskCompleteMutationHookResult = ReturnType<typeof useToggleTaskCompleteMutation>;
export type ToggleTaskCompleteMutationResult = ApolloReactCommon.MutationResult<ToggleTaskCompleteMutation>;
export type ToggleTaskCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleTaskCompleteMutation, ToggleTaskCompleteMutationVariables>;
export const UpdateTaskCommentDocument = gql`
    mutation updateTaskComment($commentID: UUID!, $summary: String!) {
  updateTaskComment(input: {commentID: $commentID, summary: $summary}) {
    comment {
      id
      summary
    }
  }
}
    `;
export type UpdateTaskCommentMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskCommentMutation, UpdateTaskCommentMutationVariables>;

/**
 * __useUpdateTaskCommentMutation__
 *
 * To run a mutation, you first call `useUpdateTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskCommentMutation, { data, loading, error }] = useUpdateTaskCommentMutation({
 *   variables: {
 *      commentID: // value for 'commentID'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateTaskCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskCommentMutation, UpdateTaskCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskCommentMutation, UpdateTaskCommentMutationVariables>(UpdateTaskCommentDocument, baseOptions);
      }
export type UpdateTaskCommentMutationHookResult = ReturnType<typeof useUpdateTaskCommentMutation>;
export type UpdateTaskCommentMutationResult = ApolloReactCommon.MutationResult<UpdateTaskCommentMutation>;
export type UpdateTaskCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskCommentMutation, UpdateTaskCommentMutationVariables>;
export const UpdateTaskDescriptionDocument = gql`
    mutation updateTaskDescription($taskID: UUID!, $description: String!) {
  updateTaskDescription(input: {taskID: $taskID, description: $description}) {
    task {
      id
      description
    }
  }
}
    `;
export type UpdateTaskDescriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>;

/**
 * __useUpdateTaskDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskDescriptionMutation, { data, loading, error }] = useUpdateTaskDescriptionMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTaskDescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>(UpdateTaskDescriptionDocument, baseOptions);
      }
export type UpdateTaskDescriptionMutationHookResult = ReturnType<typeof useUpdateTaskDescriptionMutation>;
export type UpdateTaskDescriptionMutationResult = ApolloReactCommon.MutationResult<UpdateTaskDescriptionMutation>;
export type UpdateTaskDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>;
export const UpdateTaskLocationDocument = gql`
    mutation updateTaskLocation($taskID: UUID!, $taskGroupID: UUID!, $position: Float!) {
  updateTaskLocation(input: {taskID: $taskID, taskGroupID: $taskGroupID, position: $position}) {
    task {
      id
      position
      taskGroup {
        id
      }
    }
    previousTaskGroupID
  }
}
    `;
export type UpdateTaskLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>;

/**
 * __useUpdateTaskLocationMutation__
 *
 * To run a mutation, you first call `useUpdateTaskLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskLocationMutation, { data, loading, error }] = useUpdateTaskLocationMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      taskGroupID: // value for 'taskGroupID'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateTaskLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>(UpdateTaskLocationDocument, baseOptions);
      }
export type UpdateTaskLocationMutationHookResult = ReturnType<typeof useUpdateTaskLocationMutation>;
export type UpdateTaskLocationMutationResult = ApolloReactCommon.MutationResult<UpdateTaskLocationMutation>;
export type UpdateTaskLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>;
export const UpdateTaskNameDocument = gql`
    mutation updateTaskName($taskID: UUID!, $name: String!) {
  updateTaskName(input: {taskID: $taskID, name: $name}) {
    task {
      id
      name
    }
  }
}
    `;
export type UpdateTaskNameMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>;

/**
 * __useUpdateTaskNameMutation__
 *
 * To run a mutation, you first call `useUpdateTaskNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskNameMutation, { data, loading, error }] = useUpdateTaskNameMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateTaskNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>(UpdateTaskNameDocument, baseOptions);
      }
export type UpdateTaskNameMutationHookResult = ReturnType<typeof useUpdateTaskNameMutation>;
export type UpdateTaskNameMutationResult = ApolloReactCommon.MutationResult<UpdateTaskNameMutation>;
export type UpdateTaskNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>;
export const UpdateTaskStatusDocument = gql`
    mutation updateTaskStatus($taskID: UUID!, $status: VisibilityStatus!) {
  updateTaskStatus(input: {taskID: $taskID, status: $status}) {
    task {
      id
      status
    }
  }
}
    `;
export type UpdateTaskStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>(UpdateTaskStatusDocument, baseOptions);
      }
export type UpdateTaskStatusMutationHookResult = ReturnType<typeof useUpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationResult = ApolloReactCommon.MutationResult<UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>;
export const CreateTaskGroupDocument = gql`
    mutation createTaskGroup($projectID: UUID!, $name: String!, $position: Float!) {
  createTaskGroup(input: {projectID: $projectID, name: $name, position: $position}) {
    projectID
    taskGroup {
      id
      name
      position
      tasks {
        id
      }
    }
  }
}
    `;
export type CreateTaskGroupMutationFn = ApolloReactCommon.MutationFunction<CreateTaskGroupMutation, CreateTaskGroupMutationVariables>;

/**
 * __useCreateTaskGroupMutation__
 *
 * To run a mutation, you first call `useCreateTaskGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskGroupMutation, { data, loading, error }] = useCreateTaskGroupMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      name: // value for 'name'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreateTaskGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaskGroupMutation, CreateTaskGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTaskGroupMutation, CreateTaskGroupMutationVariables>(CreateTaskGroupDocument, baseOptions);
      }
export type CreateTaskGroupMutationHookResult = ReturnType<typeof useCreateTaskGroupMutation>;
export type CreateTaskGroupMutationResult = ApolloReactCommon.MutationResult<CreateTaskGroupMutation>;
export type CreateTaskGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTaskGroupMutation, CreateTaskGroupMutationVariables>;
export const DeleteTaskGroupDocument = gql`
    mutation deleteTaskGroup($projectID: UUID!, $taskGroupID: UUID!) {
  deleteTaskGroup(input: {projectID: $projectID, taskGroupID: $taskGroupID}) {
    projectID
    taskGroupID
  }
}
    `;
export type DeleteTaskGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteTaskGroupMutation, DeleteTaskGroupMutationVariables>;

/**
 * __useDeleteTaskGroupMutation__
 *
 * To run a mutation, you first call `useDeleteTaskGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskGroupMutation, { data, loading, error }] = useDeleteTaskGroupMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *      taskGroupID: // value for 'taskGroupID'
 *   },
 * });
 */
export function useDeleteTaskGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaskGroupMutation, DeleteTaskGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaskGroupMutation, DeleteTaskGroupMutationVariables>(DeleteTaskGroupDocument, baseOptions);
      }
export type DeleteTaskGroupMutationHookResult = ReturnType<typeof useDeleteTaskGroupMutation>;
export type DeleteTaskGroupMutationResult = ApolloReactCommon.MutationResult<DeleteTaskGroupMutation>;
export type DeleteTaskGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaskGroupMutation, DeleteTaskGroupMutationVariables>;
export const UpdateTaskGroupPositionDocument = gql`
    mutation updateTaskGroupPosition($taskGroupID: UUID!, $position: Float!) {
  updateTaskGroupPosition(input: {taskGroupID: $taskGroupID, position: $position}) {
    taskGroup {
      id
      position
    }
  }
}
    `;
export type UpdateTaskGroupPositionMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskGroupPositionMutation, UpdateTaskGroupPositionMutationVariables>;

/**
 * __useUpdateTaskGroupPositionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskGroupPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskGroupPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskGroupPositionMutation, { data, loading, error }] = useUpdateTaskGroupPositionMutation({
 *   variables: {
 *      taskGroupID: // value for 'taskGroupID'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateTaskGroupPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskGroupPositionMutation, UpdateTaskGroupPositionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskGroupPositionMutation, UpdateTaskGroupPositionMutationVariables>(UpdateTaskGroupPositionDocument, baseOptions);
      }
export type UpdateTaskGroupPositionMutationHookResult = ReturnType<typeof useUpdateTaskGroupPositionMutation>;
export type UpdateTaskGroupPositionMutationResult = ApolloReactCommon.MutationResult<UpdateTaskGroupPositionMutation>;
export type UpdateTaskGroupPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskGroupPositionMutation, UpdateTaskGroupPositionMutationVariables>;
export const UpdateTaskGroupNameDocument = gql`
    mutation updateTaskGroupName($name: String!, $taskGroupID: UUID!) {
  updateTaskGroupName(input: {taskGroupID: $taskGroupID, name: $name}) {
    taskGroup {
      id
      name
    }
  }
}
    `;
export type UpdateTaskGroupNameMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskGroupNameMutation, UpdateTaskGroupNameMutationVariables>;

/**
 * __useUpdateTaskGroupNameMutation__
 *
 * To run a mutation, you first call `useUpdateTaskGroupNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskGroupNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskGroupNameMutation, { data, loading, error }] = useUpdateTaskGroupNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      taskGroupID: // value for 'taskGroupID'
 *   },
 * });
 */
export function useUpdateTaskGroupNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskGroupNameMutation, UpdateTaskGroupNameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskGroupNameMutation, UpdateTaskGroupNameMutationVariables>(UpdateTaskGroupNameDocument, baseOptions);
      }
export type UpdateTaskGroupNameMutationHookResult = ReturnType<typeof useUpdateTaskGroupNameMutation>;
export type UpdateTaskGroupNameMutationResult = ApolloReactCommon.MutationResult<UpdateTaskGroupNameMutation>;
export type UpdateTaskGroupNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskGroupNameMutation, UpdateTaskGroupNameMutationVariables>;
export const UpdateUserFullnameDocument = gql`
    mutation updateUserFullname($fullname: String!) {
  updateUserFullname(input: {fullname: $fullname}) {
    user {
      id
      fullName
    }
  }
}
    `;
export type UpdateUserFullnameMutationFn = ApolloReactCommon.MutationFunction<UpdateUserFullnameMutation, UpdateUserFullnameMutationVariables>;

/**
 * __useUpdateUserFullnameMutation__
 *
 * To run a mutation, you first call `useUpdateUserFullnameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFullnameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFullnameMutation, { data, loading, error }] = useUpdateUserFullnameMutation({
 *   variables: {
 *      fullname: // value for 'fullname'
 *   },
 * });
 */
export function useUpdateUserFullnameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserFullnameMutation, UpdateUserFullnameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserFullnameMutation, UpdateUserFullnameMutationVariables>(UpdateUserFullnameDocument, baseOptions);
      }
export type UpdateUserFullnameMutationHookResult = ReturnType<typeof useUpdateUserFullnameMutation>;
export type UpdateUserFullnameMutationResult = ApolloReactCommon.MutationResult<UpdateUserFullnameMutation>;
export type UpdateUserFullnameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserFullnameMutation, UpdateUserFullnameMutationVariables>;
export const CreateUserAccountDocument = gql`
    mutation createUserAccount($username: String!, $email: String!, $fullName: String!, $password: String!, $roleCode: RoleCode!) {
  createUserAccount(input: {username: $username, email: $email, fullName: $fullName, password: $password, roleCode: $roleCode}) {
    id
    email
    username
    role {
      code
      name
    }
    fullName
    createdAt
  }
}
    `;
export type CreateUserAccountMutationFn = ApolloReactCommon.MutationFunction<CreateUserAccountMutation, CreateUserAccountMutationVariables>;

/**
 * __useCreateUserAccountMutation__
 *
 * To run a mutation, you first call `useCreateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAccountMutation, { data, loading, error }] = useCreateUserAccountMutation({
 *   variables: {
 *      username: // value for 'username'
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      password: // value for 'password'
 *      roleCode: // value for 'roleCode'
 *   },
 * });
 */
export function useCreateUserAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserAccountMutation, CreateUserAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserAccountMutation, CreateUserAccountMutationVariables>(CreateUserAccountDocument, baseOptions);
      }
export type CreateUserAccountMutationHookResult = ReturnType<typeof useCreateUserAccountMutation>;
export type CreateUserAccountMutationResult = ApolloReactCommon.MutationResult<CreateUserAccountMutation>;
export type CreateUserAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserAccountMutation, CreateUserAccountMutationVariables>;
export const DeleteUserAccountDocument = gql`
    mutation deleteUserAccount($userID: UUID!) {
  deleteUserAccount(input: {userID: $userID}) {
    userID
  }
}
    `;
export type DeleteUserAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>;

/**
 * __useDeleteUserAccountMutation__
 *
 * To run a mutation, you first call `useDeleteUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAccountMutation, { data, loading, error }] = useDeleteUserAccountMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useDeleteUserAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>(DeleteUserAccountDocument, baseOptions);
      }
export type DeleteUserAccountMutationHookResult = ReturnType<typeof useDeleteUserAccountMutation>;
export type DeleteUserAccountMutationResult = ApolloReactCommon.MutationResult<DeleteUserAccountMutation>;
export type DeleteUserAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($userID: UUID!, $newPassword: String!) {
  resetPassword(input: {userID: $userID, newPassword: $newPassword}) {
    userID
    ok
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation updateUserRole($userID: UUID!, $roleCode: RoleCode!) {
  updateUserRole(input: {userID: $userID, roleCode: $roleCode}) {
    user {
      id
      role {
        code
        name
      }
    }
  }
}
    `;
export type UpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      roleCode: // value for 'roleCode'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, baseOptions);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;