import React, { useState, useRef } from 'react';
import { Plus, User, Trash, Paperclip, CheckSquareOutline, At, Smile } from 'shared/icons';
import Editor from 'rich-markdown-editor';
import marked from 'marked';
import styled from 'styled-components';
import Checkmark from 'shared/icons/Checkmark';
import ReactMarkdown from 'react-markdown';

import dayjs from 'dayjs';

import {
  CommentsTitle,
  TaskDetailLabel,
  CommentContainer,
  MetaDetailContent,
  TaskDetailsAddLabelIcon,
  ActionButton,
  AssignUserIcon,
  AssignUserLabel,
  AssignUsersButton,
  AssignedUsersSection,
  ViewRawButton,
  DueDateTitle,
  Container,
  LeftSidebar,
  ContentContainer,
  LeftSidebarContent,
  LeftSidebarSection,
  SidebarTitle,
  SidebarButton,
  SidebarButtonText,
  MarkCompleteButton,
  HeaderContainer,
  HeaderLeft,
  HeaderInnerContainer,
  TaskDetailsTitleWrapper,
  TaskDetailsTitle,
  ExtraActionsSection,
  HeaderRight,
  HeaderActionIcon,
  EditorContainer,
  InnerContentContainer,
  DescriptionContainer,
  Labels,
  ChecklistSection,
  MemberList,
  TaskMember,
  TabBarSection,
  TabBarItem,
  CommentTextArea,
  CommentEditorContainer,
  CommentEditorActions,
  CommentEditorActionIcon,
  CommentEditorSaveButton,
  CommentProfile,
  CommentInnerWrapper,
  ActivitySection,
  FeedbackItem,
  FeedbackBodyItems,
  CommentCreatorName,
  CommentCreatorDate,
  FeedbackItemHeader,
  TaskDetailsEditor,
  EditorWrapper,
} from './Styles';
import Comments from 'Projects/Comments';
import CommentCreator from 'Projects/CommentCreator';

const ChecklistContainer = styled.div``;

type TaskLabelProps = {
  label: TaskLabel;
  onClick: ($target: React.RefObject<HTMLElement>) => void;
};

const TaskLabelItem: React.FC<TaskLabelProps> = ({ label, onClick }) => {
  const $label = useRef<HTMLDivElement>(null);
  return (
    <TaskDetailLabel
      onClick={() => {
        onClick($label);
      }}
      ref={$label}
      color={label.projectLabel.labelColor.colorHex}
    >
      {label.projectLabel.name}
    </TaskDetailLabel>
  );
};

type DetailsEditorProps = {
  description: string;
  onTaskDescriptionChange: (newDescription: string) => void;
  onCancel: () => void;
};

type TaskDetailsProps = {
  me: { id: string; fullName: string; email: string };
  task: { id: string; name: string; description?: string; comments: Array<TaskComment> };
  onTaskNameChange: (id: string, name: string) => void;
  onSaveDescription: (id: string, desc: string) => void;
  onCreateComment: (summary: string) => void;
  onEditComment: (commentID: string, summary: string) => void;
  onDeleteComment: (commentID: string) => void;
};

const TaskDetails: React.FC<TaskDetailsProps> = ({
  me,
  task,
  onTaskNameChange,
  onCreateComment,
  onEditComment,
  onSaveDescription,
  onDeleteComment,
}) => {
  const [taskName, setTaskName] = useState(task.name);
  const [saveTimeout, setSaveTimeout] = useState<any>(null);
  const [showRaw, setShowRaw] = useState(false);
  const [showCommentActions, setShowCommentActions] = useState(false);
  const $noMemberBtn = useRef<HTMLDivElement>(null);
  const $addMemberBtn = useRef<HTMLDivElement>(null);
  const $dueDateBtn = useRef<HTMLDivElement>(null);

  const [taskDescription, setTaskDescription] = useState(task.description ?? '');
  const [editDesc, setEditDesc] = useState(false);
  marked.use({ gfm: true, breaks: true });

  const saveDescription = () => {
    // onTaskDescriptionChange(task, taskDescriptionRef.current);
  };
  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          <TaskDetailsTitleWrapper>
            <TaskDetailsTitle
              value={taskName}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                }
              }}
              onChange={e => {
                setTaskName(e.currentTarget.value);
              }}
              onBlur={() => {
                if (taskName !== task.name) {
                  onTaskNameChange(task.id, taskName);
                }
              }}
            />
          </TaskDetailsTitleWrapper>
        </HeaderContainer>
        <InnerContentContainer>
          {editDesc ? (
            <EditorWrapper>
              <TaskDetailsEditor
                autoFocus
                value={taskDescription}
                onBlur={() => {
                  onSaveDescription(task.id, taskDescription);
                  setEditDesc(false);
                }}
                onChange={e => setTaskDescription(e.currentTarget.value)}
              />
            </EditorWrapper>
          ) : (
            <DescriptionContainer
              onClick={() => setEditDesc(true)}
              dangerouslySetInnerHTML={{ __html: marked.parse(taskDescription) }}
            />
          )}
          <ActivitySection>
            <CommentsTitle>Comments</CommentsTitle>
            <Comments onEdit={onEditComment} onDelete={onDeleteComment} comments={task.comments} />
          </ActivitySection>
        </InnerContentContainer>
        <CommentCreator
          member={{
            id: me.id,
            profileIcon: {
              url: null,
              bgColor: '#ccc',
              initials: me.fullName.charAt(0),
            },
          }}
          onCreateComment={onCreateComment}
        />
      </ContentContainer>
    </Container>
  );
};

export default TaskDetails;
