import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { mixin } from 'shared/utils/styles';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { Bars, Trash, Internal, Users, CheckSquareOutline, CheckSquare, Comments } from 'shared/icons';
import Button from 'shared/components/Button';
import { VisibilityStatus } from 'shared/generated/graphql';
import useOnOutsideClick from 'shared/hooks/onOutsideClick';

export const CardNameTextarea = styled(TextareaAutosize)`
  background-color: #fff;
  border: none;
  box-shadow: inset 0 0 0 2px #0079bf;
  transition: margin 85ms ease-in, background 85ms ease-in;
  line-height: 20px;
  padding: 8px 12px;

  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  height: 54px;
  width: 100%;

  border: none;
  border-radius: 18px;
  box-shadow: none;
  margin-bottom: 4px;
  max-height: 162px;
  min-height: 54px;
  font-size: 14px;
  line-height: 20px;

  color: #000;
`;

export const ListCardContainer = styled.div<{ editable: boolean }>`
  margin-bottom: 8px;
  border-radius: 18px;
  ${mixin.boxShadowCard}
  ${props => !props.editable && 'cursor: pointer'};
  position: relative;

  background-color: #fff;
`;

export const ListCardInnerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ListCardDetails = styled.div<{ complete: boolean }>`
  padding: 12px 20px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.complete && '    opacity: 0.6;'}
`;

export const CardTitle = styled.span`
  clear: both;
  margin: 0;
  overflow: hidden;
  text-decoration: none;
  word-wrap: break-word;
  line-height: 18px;
  font-size: 14px;
  color: #000;

  display: flex;
  align-items: center;
  svg {
    fill: rgb(40, 199, 111);
    stroke: rgb(40, 199, 111);
  }
`;

export const CardTitleText = styled.span<{ complete: boolean }>`
  margin-top: 2px;
  ${props => props.complete && 'padding-left: 4px;'}
`;

const DrivenLogo = styled.div<{ internal: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${props => (props.internal ? '#f8931e' : '#000')};
  }
`;

export const Labels = styled.div`
  min-width: 56px;
  width: auto;
  height: 16px;
`;
export const Label = styled.span`
  font-size: 12px;
  height: 16px;
  min-width: 56px;
  width: auto;
  padding: 0 8px;
  max-width: 198px;
  float: left;
  margin: 0 4px 4px 0;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #000;
`;

const SaveCard = styled(Button)`
  padding: 6px 12px;
`;

const EditorControls = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  padding-bottom: 6px;
  margin: 0 6px;
`;

const StatusSwitcher = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2px;
  flex: 1 1 0;
  justify-content: flex-end;
`;

const DeleteIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 6px;
  svg {
    fill: rgba(0, 0, 0, 0.85);
  }
  &:hover svg {
    fill: rgba(0, 0, 0, 1);
  }
`;

const CompleteToggleIcon = styled.div<{ complete: boolean }>`
  margin: 0 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  ${props =>
    props.complete &&
    css`
      svg {
        fill: rgb(40, 199, 111);
      }
    `}
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  svg {
    fill: #000;
    stroke: #000;
    margin-bottom: 2px;
  }
`;

const BadgeLabel = styled.span`
  margin-left: 4px;
`;

const InternalIcon = styled.div<{ active: boolean; color: string }>`
  margin: 0 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  ${props =>
    props.active &&
    css`
      svg {
        fill: ${props.color};
      }
    `}
`;

type CardEditorProps = {
  name: string;
  onNameChange: (name: string) => void;
  onStatusChange: (status: VisibilityStatus) => void;
  onDelete: () => void;
  status: VisibilityStatus;
  onToggleComplete: (complete: boolean) => void;
  onClose: () => void;
  complete: boolean;
};

const CardEditor: React.FC<CardEditorProps> = ({
  onNameChange,
  onStatusChange,
  name,
  onToggleComplete,
  onDelete,
  complete,
  status: initialStatus,
  onClose,
}) => {
  const $container = useRef<HTMLDivElement>(null);
  const $editor = useRef<HTMLTextAreaElement>(null);
  const [status, setStatus] = useState(initialStatus);
  useEffect(() => {
    if ($editor && $editor.current) {
      $editor.current.select();
    }
  });
  useOnOutsideClick(
    $container,
    true,
    () => {
      onClose();
    },
    null,
  );
  return (
    <ListCardInnerContainer ref={$container}>
      <CardNameTextarea
        defaultValue={name}
        ref={$editor}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            e.preventDefault();
            if ($editor && $editor.current) {
              onNameChange($editor.current.value);
              onClose();
            }
          }
        }}
      />
      <EditorControls>
        <SaveCard
          disabled={name.trim() === ''}
          onClick={() => {
            if ($editor && $editor.current) {
              onNameChange(name);
              onClose();
            }
          }}
        >
          Save
        </SaveCard>
        <DeleteIcon onClick={onDelete}>
          <Trash width={12} height={12} />
        </DeleteIcon>
        <StatusSwitcher>
          <CompleteToggleIcon
            complete={complete}
            onClick={() => {
              onToggleComplete(!complete);
            }}
          >
            {complete ? <CheckSquare width={18} height={18} /> : <CheckSquareOutline width={18} height={18} />}
          </CompleteToggleIcon>
          <InternalIcon
            color="#f8931e"
            active={status === VisibilityStatus.Internal}
            onClick={() => {
              onStatusChange(VisibilityStatus.Internal);
              setStatus(VisibilityStatus.Internal);
            }}
          >
            <Internal width={18} height={18} />
          </InternalIcon>
          <InternalIcon
            color="#000"
            active={status === VisibilityStatus.Client}
            onClick={() => {
              onStatusChange(VisibilityStatus.Client);
              setStatus(VisibilityStatus.Client);
            }}
          >
            <Users width={18} height={18} />
          </InternalIcon>
        </StatusSwitcher>
      </EditorControls>
    </ListCardInnerContainer>
  );
};

type CardChangeActions = {
  onDelete: () => void;
  onNameChange: (name: string) => void;
  onStatusChange: (status: VisibilityStatus) => void;
  onToggleComplete: (complete: boolean) => void;
};

type CardProps = {
  name: string;
  comments?: number;
  status: VisibilityStatus;
  editable?: boolean;
  onClick?: () => void;
  actions?: CardChangeActions;
  description?: boolean;
  innerRef?: any;
  handleProps?: any;
  wrapperProps?: any;
  complete?: boolean;
};

const Card: React.FC<CardProps> = ({
  name,
  status,
  complete = false,
  editable: editAvailable,
  description = false,
  comments = 0,
  actions,
  onClick,
  handleProps,
  wrapperProps,
  innerRef,
}) => {
  const useLabel = false;
  const [editable, setEditable] = useState(false);
  console.log(innerRef);
  return (
    <ListCardContainer
      editable={editable}
      {...wrapperProps}
      ref={r => {
        console.log(r);
        innerRef(r);
      }}
    >
      {editable && actions ? (
        <CardEditor
          onClose={() => setEditable(false)}
          name={name}
          status={status}
          complete={complete}
          onToggleComplete={actions.onToggleComplete}
          onNameChange={actions.onNameChange}
          onStatusChange={actions.onStatusChange}
          onDelete={actions.onDelete}
        />
      ) : (
        <ListCardInnerContainer
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          onContextMenu={e => {
            if (editAvailable) {
              e.preventDefault();
              setEditable(true);
            }
          }}
          {...handleProps}
        >
          <ListCardDetails complete={complete}>
            <CardTitle>
              {complete && <CheckSquare width={16} height={16} />}
              <CardTitleText complete={complete}>{name}</CardTitleText>
              {description && (
                <Badge>
                  <Bars width={14} height={14} />
                </Badge>
              )}
              {comments !== 0 && (
                <Badge>
                  <Comments width={16} height={16} />
                  <BadgeLabel>{comments}</BadgeLabel>
                </Badge>
              )}
            </CardTitle>
            {!useLabel && (
              <DrivenLogo internal={status === VisibilityStatus.Internal}>
                {status === VisibilityStatus.Internal ? (
                  <Internal width={24} height={24} />
                ) : (
                  <Users width={20} height={20} />
                )}
              </DrivenLogo>
            )}
          </ListCardDetails>
        </ListCardInnerContainer>
      )}
    </ListCardContainer>
  );
};

export default Card;
