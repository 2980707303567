import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { mixin } from 'shared/utils/styles';
import Button from 'shared/components/Button';

export const Container = styled.div`
  width: 320px;
  margin: 0 10px;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
`;

export const Wrapper = styled.div<{ editorOpen: boolean }>`
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  height: auto;
  min-height: 32px;
  padding: 4px;
  transition: background 85ms ease-in, opacity 40ms ease-in, border-color 85ms ease-in;
  width: 272px;
  margin: 0 4px;
  margin-right: 8px;
  color: #fff;
  svg {
    fill: #fff;
    stroke: #fff;
  }

  ${props =>
    !props.editorOpen &&
    css`
      border-radius: 12px;
      border: 1px dashed #fff;
    `}

  ${props =>
    props.editorOpen &&
    css`
      background: #383838;
      color: #fff;

      border-radius: 28px;
      box-shadow: 0px 0px 4px #0000004d;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      position: relative;
      white-space: normal;
    `}
`;

export const AddListButton = styled(Button)`
  padding: 6px 12px;
`;

export const Placeholder = styled.span`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  font-weight: 400;
  transition: color 85ms ease-in;
`;

export const AddIconWrapper = styled.div`
  color: #fff;
  margin-right: 6px;
`;

export const ListNameEditorWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  margin-left: 16px;
`;

export const ListNameEditor = styled(TextareaAutosize)`
  font-size: 20px;
  font-weight: 300;
  border: none;
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
  background: transparent;
  border-radius: 3px;
  box-shadow: none;
  font-weight: 600;
  margin: -4px 0;
  padding: 4px 0;

  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  flex-direction: column;
  text-align: start;

  color: #fff;
`;

export const ListAddControls = styled.div`
  height: 32px;
  margin-top: 4px;
  transition: margin 85ms ease-in, height 85ms ease-in;
  overflow: hidden;
  margin-left: 16px;
  margin-bottom: 8px;
`;

export const CancelAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
`;
