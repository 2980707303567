import React from 'react';
import Icon, { IconProps } from './Icon';

const Act: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 801.15 704.81">
      <path d="M238.5,231.54,114,424.57H238.79Zm-29.13,86.24.11,77.52H164.62Z" transform="translate(-25.1 -21.15)" />
      <path
        d="M463.07,286l-.3-1.18-1.22-.18c-1.23-.18-2.71-.35-4.39-.5s-3.53-.26-5.22-.26c-5.55,0-8.72,1.65-9.41,4.82l-44.3,171.26,9.35.64c2.56.18,4.26.26,5.53.26,5.73,0,7.49-.7,8.41-1.32a6.56,6.56,0,0,0,2.73-4l9.1-38.89H473l10.41,43.66,1.36.08c1.32.09,2.54.15,3.67.2l3.08.17c1.94.14,3.65.14,5.16.14,4.48,0,7.11-.45,8.48-1.39a4.5,4.5,0,0,0,1.91-5.16Zm4.71,108.73h-29.3l7.63-32.58c1.53-5.77,2.82-11.36,4-17.08,1-5,2-10.28,3.08-16.66l0-.16a.77.77,0,0,1,0,.15c.91,5.3,1.95,10.91,3.09,16.67s2.39,11.5,3.71,17Z"
        transform="translate(-25.1 -21.15)"
      />
      <path
        d="M540.93,324.94c1.88-5.32,4.11-9.45,6.66-12.33a19.41,19.41,0,0,1,7.82-5.6,26.06,26.06,0,0,1,8.63-1.49,18.71,18.71,0,0,1,6.29,1,31.67,31.67,0,0,1,5.23,2.32,28.42,28.42,0,0,1,3.35,2.2l1.73,1.32,1-1.93a29.27,29.27,0,0,0,2.31-5.79,24,24,0,0,0,1.07-7.21,13.29,13.29,0,0,0-2.26-7.23c-1.5-2.36-4.15-4.34-7.85-5.87s-8.48-2.23-14.85-2.23A50.75,50.75,0,0,0,546,284.16a35.25,35.25,0,0,0-13.84,8c-4.35,3.92-8.26,9.53-11.62,16.69s-6,16.51-7.89,28-2.85,25.8-2.85,42.67a277,277,0,0,0,2,36.29c1.33,9.82,3.27,17.91,5.77,24s5.64,11.06,9.16,14.29a28.79,28.79,0,0,0,12,6.83,51.61,51.61,0,0,0,13.72,1.81l1.05,0A48.92,48.92,0,0,0,569,460.3a28.68,28.68,0,0,0,11.42-6.95,14.3,14.3,0,0,0,4.36-10.05,23.45,23.45,0,0,0-1-6.92,39.62,39.62,0,0,0-2.32-5.93l-1-2-1.79,1.39a32.88,32.88,0,0,1-8.9,4.91,33.22,33.22,0,0,1-12.6,2.32,18.46,18.46,0,0,1-9.06-2.23c-2.67-1.44-5-4.19-7-8.16-2-4.16-3.67-10.15-4.87-17.79s-1.82-17.88-1.82-30.21a242.74,242.74,0,0,1,1.83-32.45A111.32,111.32,0,0,1,540.93,324.94Z"
        transform="translate(-25.1 -21.15)"
      />
      <path
        d="M684.77,301.1V284.56H599.36c-3.84,0-6,2.11-6,5.79V307h33.43v150.6l-3.44,2.77h5.24c7.5,0,11.6-.06,14.17-.22,4.23-.25,5.45-.84,6.14-1.44l.15-.15a5.24,5.24,0,0,0,1.35-4V307h28.31a6.74,6.74,0,0,0,4.33-1.19l.18-.12.14-.17A5.84,5.84,0,0,0,684.77,301.1Z"
        transform="translate(-25.1 -21.15)"
      />
      <path
        d="M774.79,192c-12.12-19.87-39.25-58.6-84.9-94.72C673,84,628.11,48.48,559.48,31.15a349,349,0,0,0-83.77-10H475A361.35,361.35,0,0,0,246.3,103.74h49.55A331.39,331.39,0,0,1,475,50.38h1.41a322,322,0,0,1,76,9.1C615.05,75.31,656.27,108,671.79,120.3c42,33.24,66.91,68.77,78,87,45.57,74.71,48,149.63,47,178.37-3.86,107.08-61.45,180.23-86,206.71C644,664.4,562,686.1,519.05,692.62c-68.73,10.46-132.72,1.15-190.19-27.65a301.38,301.38,0,0,1-90.94-70.07V479.53H54.37V430.58L232.22,171.81h59.29v252.3h47.83V394.83H320.77V142.55H216.83L25.1,421.5v87.38H208.66v96.91l3.51,4.17a331.8,331.8,0,0,0,103.58,81c46.23,23.19,96.57,35,149.63,35a387.5,387.5,0,0,0,58.06-4.51c46.71-7.11,135.94-30.75,208.79-109.26C759,583.43,821.8,503.87,826.07,386.68h0C827.2,355.27,824.45,273.45,774.79,192Z"
        transform="translate(-25.1 -21.15)"
      />
    </Icon>
  );
};

export default Act;
