import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import JwtDecode from 'jwt-decode';
import { setAccessToken } from 'shared/utils/accessToken';
import Login from 'shared/components/Login';
import UserContext from 'App/context';
import * as QueryString from 'query-string';
import { Container } from './Styles';

const Auth = () => {
  const [invalidLoginAttempt, setInvalidLoginAttempt] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const { setUser } = useContext(UserContext);
  const login = (
    data: LoginFormData,
    setComplete: (val: boolean) => void,
    setError: (name: 'username' | 'password', error: ErrorOption) => void,
  ) => {
    fetch('/auth/login', {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        username: data.username,
        password: data.password,
      }),
    }).then(async x => {
      if (x.status === 401) {
        setInvalidLoginAttempt(invalidLoginAttempt + 1);
        setError('username', { type: 'error', message: 'Invalid username or password' });
        setError('password', { type: 'error', message: 'Invalid username or password' });
        setComplete(true);
      } else {
        const response = await x.json();
        const { accessToken } = response;
        const claims: JWTToken = JwtDecode(accessToken);
        const currentUser = {
          id: claims.userId,
          roleCode: claims.roleCode,
        };
        setUser(currentUser);
        setComplete(true);
        setAccessToken(accessToken);

        const params = QueryString.parse(location.search);
        if (params.redirect && typeof params.redirect === 'string') {
          const redirectURL = new URL(params.redirect);
          history.push(redirectURL.pathname);
        } else {
          history.push('/');
        }
      }
    });
  };

  useEffect(() => {
    fetch('/auth/refresh_token', {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { status } = x;
      if (status === 200) {
        history.replace('/projects');
      }
    });
  }, []);

  return (
    <Container>
      <Login
        onSubmit={login}
        onResetPassword={(data, setError, onComplete, onSubmit) => {
          onComplete(false);
          fetch('/auth/password/request_reset', {
            method: 'POST',
            body: JSON.stringify({ email: data.email }),
          }).then(d => {
            onComplete(true);
            onSubmit(true);
          });
        }}
        onGoogleLogin={data => {
          console.log(data);
          console.log(data.tokenId);
          fetch('/auth/google/login', {
            credentials: 'include',
            body: JSON.stringify({
              tokenId: data.tokenId,
            }),
            method: 'POST',
          }).then(async x => {
            if (x.status === 401) {
              setInvalidLoginAttempt(invalidLoginAttempt + 1);
            } else {
              const response = await x.json();
              const { accessToken } = response;
              const claims: JWTToken = JwtDecode(accessToken);
              const currentUser = {
                id: claims.userId,
                roleCode: claims.roleCode,
              };
              setUser(currentUser);
              setAccessToken(accessToken);

              const params = QueryString.parse(location.search);
              if (params.redirect && typeof params.redirect === 'string') {
                const redirectURL = new URL(params.redirect);
                history.push(redirectURL.pathname);
              } else {
                history.push('/');
              }
            }
          });
        }}
        onConfirmReset={(data, setError, setComplete, setSubmitted) => {
          setComplete(false);
          console.log(data);
          fetch('/auth/password/confirm_reset', {
            method: 'POST',
            body: JSON.stringify(data),
          })
            .then(() => {
              setComplete(true);
              setSubmitted(true);
            })
            .catch(e => {
              setError('password', { message: 'Something went wrong while reseting password', type: 'error' });
              console.error(e);
            });
        }}
      />
    </Container>
  );
};

export default Auth;
