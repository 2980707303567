import React from 'react';
import Icon, { IconProps } from './Icon';

const Check: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 769.94 697.89">
      <path
        d="M348.91,324.44c1.81-5.2,4-9.24,6.45-12.05a18.67,18.67,0,0,1,7.55-5.47,24.63,24.63,0,0,1,8.33-1.45,18.16,18.16,0,0,1,6.06,1,29.28,29.28,0,0,1,5.05,2.26,28.87,28.87,0,0,1,3.24,2.15l1.72,1.32,1-1.92a30.18,30.18,0,0,0,2.28-5.63,24,24,0,0,0,1-7,13.07,13.07,0,0,0-2.19-7.1c-1.47-2.32-4-4.26-7.63-5.77s-8.19-2.19-14.33-2.19h-.08a49.37,49.37,0,0,0-13.64,2,34.38,34.38,0,0,0-13.43,7.86c-4.22,3.85-8,9.35-11.27,16.35s-5.81,16.17-7.66,27.44-2.77,25.27-2.77,41.8a277.84,277.84,0,0,0,1.92,35.53c1.3,9.66,3.19,17.58,5.62,23.56s5.48,10.79,8.89,14a28.22,28.22,0,0,0,11.63,6.71,49,49,0,0,0,13.28,1.74h.89A47.26,47.26,0,0,0,376,457a27.89,27.89,0,0,0,11.08-6.86,14.18,14.18,0,0,0,4.21-9.85,23.24,23.24,0,0,0-1-6.78,37.2,37.2,0,0,0-2.25-5.78l-1-2-1.78,1.4a34.41,34.41,0,0,1-20.76,7.08,17.75,17.75,0,0,1-8.76-2.19c-2.57-1.41-4.84-4.09-6.72-8-2-4.07-3.57-9.93-4.73-17.42A201.71,201.71,0,0,1,342.56,377a239.23,239.23,0,0,1,1.77-31.77A110.75,110.75,0,0,1,348.91,324.44Z"
        transform="translate(-27.06 -23.76)"
      />
      <path
        d="M483.34,285h-1.83c-6.51,0-11.16.09-13.81.25-4,.25-5.18.82-5.85,1.4l-.17.17a5.26,5.26,0,0,0-1.3,4v62.82H426.17V285h-1.83c-6.51,0-11.19.09-13.92.25-3.95.23-5.24.77-6,1.5l-.17.2a5.6,5.6,0,0,0-1.08,3.83v166.4h1.84c6.5,0,11.19-.08,13.93-.25,3.95-.24,5.24-.78,6-1.51l.15-.19a5.49,5.49,0,0,0,1.08-3.83V375.52h34.21v81.57h1.84c6.52,0,11.2-.08,13.93-.25,4-.24,5.24-.78,6-1.51l.16-.19a5.52,5.52,0,0,0,1.07-3.83Z"
        transform="translate(-27.06 -23.76)"
      />
      <path
        d="M500.73,290.1l-.08.26V457.13h58.18l.46,0a4.89,4.89,0,0,0,3.61-1.59l.09-.11c.75-1,1.14-2.67,1.31-5.72.13-2.54.21-6.58.24-12v-1.85h-41V374.31h30.56c1.56,0,4.26-.64,4.91-4.92v-.11a132.64,132.64,0,0,0,.49-14.65l0-1.79H523.61V306.15h36.44a4.35,4.35,0,0,0,4-2.09c.66-1.09,1-2.95,1.18-5.66.11-2.21.24-6,.24-11.65v-1.83h-59C504.58,284.94,502,285.86,500.73,290.1Z"
        transform="translate(-27.06 -23.76)"
      />
      <path
        d="M602.36,324.45c1.81-5.21,4-9.25,6.45-12.06a18.76,18.76,0,0,1,7.55-5.47,24.63,24.63,0,0,1,8.33-1.45,18.28,18.28,0,0,1,6.08,1,28.29,28.29,0,0,1,8.27,4.41l1.72,1.32,1-1.91a29.87,29.87,0,0,0,2.28-5.64,24,24,0,0,0,1-7,13.07,13.07,0,0,0-2.19-7.1c-1.47-2.32-4-4.26-7.62-5.77s-8.2-2.19-14.38-2.19h-.09a49.37,49.37,0,0,0-13.64,2,34.53,34.53,0,0,0-13.43,7.86c-4.21,3.85-8,9.35-11.27,16.35s-5.8,16.17-7.64,27.44S572,361.47,572,378a274.09,274.09,0,0,0,1.92,35.52c1.28,9.67,3.17,17.59,5.62,23.57s5.48,10.79,8.88,14a28.22,28.22,0,0,0,11.63,6.71,49.48,49.48,0,0,0,13.28,1.74h.9a47.24,47.24,0,0,0,15.19-2.5,27.64,27.64,0,0,0,11.08-6.86,14.13,14.13,0,0,0,4.21-9.85,23.23,23.23,0,0,0-1-6.78,37.24,37.24,0,0,0-2.26-5.78l-1-2-1.79,1.4a31.33,31.33,0,0,1-8.59,4.8,32.14,32.14,0,0,1-11.92,2.28h-.4a18,18,0,0,1-8.6-2.19c-2.58-1.41-4.84-4.09-6.73-8-2-4.08-3.56-9.94-4.72-17.42A196.69,196.69,0,0,1,595.93,377a239.43,239.43,0,0,1,1.82-31.78A109.93,109.93,0,0,1,602.36,324.45Z"
        transform="translate(-27.06 -23.76)"
      />
      <path
        d="M737.32,293.18c1.26-2.57.77-4.32.14-5.34-1.33-2.13-4.39-3.12-9.64-3.12-1.71,0-3.68.08-5.85.24a33.65,33.65,0,0,0-7.05,1.27l-.75.21L679.39,352V285h-1.83c-6.52,0-11.21.09-13.93.25-4.09.25-5.29.81-6,1.39l-.18.18a5.18,5.18,0,0,0-1.3,4v166.4H658c6.51,0,11.24-.08,14.05-.25,4-.24,5.35-.77,6.07-1.5l.18-.2a5.54,5.54,0,0,0,1.07-3.83V375.16l.29.15A25.3,25.3,0,0,1,686,380a62.94,62.94,0,0,1,8.69,12.52,98,98,0,0,1,6.78,16.42c2,6.2,3.87,13.25,5.68,21s3.53,16.32,5.09,25.55l.24,1.42,8.35.59c2.31.16,4.59.24,6.8.24,3.81,0,6.23-.45,7.61-1.42,1.1-.77,2.35-2.33,2.07-5.47-3.19-23.16-8-42.71-14.42-58.11-6.16-14.88-13.94-26.17-23.14-33.59l37.57-65.78Z"
        transform="translate(-27.06 -23.76)"
      />
      <path
        d="M796.84,362.12C794.52,285.55,766.49,213,715.79,152.4,670.32,98,609.94,57.29,545.78,37.87c-89.5-27.14-172.13-7.93-195.32-1.39a350.32,350.32,0,0,0-122.3,62.19h50.53a323.84,323.84,0,0,1,79.58-34.54c21.43-5.95,97.7-23.44,179.08,1.22C648.5,99,763.42,212.09,768,363c4.52,148.13-96.3,282.66-239.72,319.87-117.93,30.64-243.83-8.9-328.56-103.2l-4.56-5-6.76.3A156.85,156.85,0,0,1,138.85,569c-11-3.14-24.8-7.06-39.42-17.69-12.26-8.92-28.83-25.57-38.1-54.23a110.94,110.94,0,0,1-5.23-27.44l49.67.09a67.24,67.24,0,0,0,15,36.11c17.91,21.44,43,23.51,55.07,24.51l.28,0c11.55.92,42.23,3.35,65.46-19.51,15.95-15.74,22.91-37.83,20.1-63.9-2-18.58-8.79-34.06-20.21-46-18.82-19.7-44.14-22.64-57.75-24.22a122.92,122.92,0,0,0-15.1-.82v-41.1a91.08,91.08,0,0,0,41.1-10.69c14.65-8,24.89-19.6,29.63-33.51,5.13-15.11,4.18-37.43-9.52-53.26-19.3-22.32-55.16-21.15-79.17-8.69a72.94,72.94,0,0,0-27.59,25.57H74.52A112.07,112.07,0,0,1,96.4,211.1c35.71-43.1,90.14-41.07,96.24-40.7,14.42.87,50.71,6,76.19,35.15,5.69,6.53,23,26.41,23.34,55.8.22,18.92-6.64,34.13-12.43,43.56l-3.86,6.29,24.66,14.92,3.81-6.2A110.67,110.67,0,0,0,321,261c-.45-40-23-65.89-30.47-74.4-32.6-37.29-78.1-43.86-96.14-45-7.59-.47-75.46-3-120.25,51.11C52.46,219,45.21,247.59,43,267l-1.88,16h99.51l3.91-8.11c3.15-6.46,9.09-15.3,19.54-20.74,11.72-6.11,33.77-9.71,43.89,2,6.27,7.19,6.51,18.16,4.09,25.3-5.78,17-29.89,27.35-56.14,24.15l-16.12-1.86V408l16.47-2.35a93.49,93.49,0,0,1,24.16-.31c12.73,1.47,28.58,3.3,40.19,15.46,10.54,11,12,24.7,12.42,29.23,1.84,17.08-2.21,31.06-11.7,40.41-13.86,13.65-33.5,12.05-43,11.28-10-.83-25.16-2.07-35.35-14.27-9.34-11.19-9.11-25.18-8.58-30.64L136,441l-107.94-.16-.77,13.56a141,141,0,0,0,6.57,51.46c11.67,36,32.84,57.22,48.54,68.68,18.62,13.57,35.86,18.51,48.52,22.14a185.38,185.38,0,0,0,51.81,7.13A372,372,0,0,0,343.07,705.26a355.16,355.16,0,0,0,106.29,16.39h.19a343,343,0,0,0,86-10.95h0C691.87,670.13,801.77,523.53,796.84,362.12Z"
        transform="translate(-27.06 -23.76)"
      />
    </Icon>
  );
};

export default Check;
