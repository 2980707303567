import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'shared/components/Button';
import { Trash } from 'shared/icons';
import CommentCreator from 'Projects/CommentCreator';
import TaskAssignee from 'shared/components/TaskAssignee';
import Ellipsis from 'shared/icons/Ellipsis';
import Comments from 'Projects/Comments';
import {
  useAllFeedbackQuery,
  useFeedbackQuery,
  useCreateFeedbackCommentMutation,
  FeedbackQuery,
  FeedbackDocument,
  useDeleteProjectFeedbackMutation,
  useUpdateProjectFeedbackNameMutation,
  useUpdateProjectFeedbackSummaryMutation,
  useDeleteFeedbackCommentMutation,
  useUpdateFeedbackCommentMutation,
  useMeQuery,
} from 'shared/generated/graphql';
import dayjs from 'dayjs';
import * as QueryString from 'query-string';
import { useLocation, useRouteMatch, Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import CreateFeedback from './CreateFeedback';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import { usePopup, Popup } from 'shared/components/PopupMenu';
import useOnOutsideClick from 'shared/hooks/onOutsideClick';
import TextareaAutosize from 'react-autosize-textarea';

const WarningLabel = styled.p`
  font-size: 14px;
  margin: 6px 0;
`;

const ConfirmDelete = styled(Button)`
  padding: 6px 12px;
  margin: 6px 0;
  width: 100%;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const SidebarCard = styled.div`
  margin-left: 12px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #00000026;
  border-radius: 18px;
`;

const Container = styled.div`
  display: flex;
  width: 960px;
`;

const Sidebar = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-left: 32px;
`;

const SidebarTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  margin-bottom: 14px;
`;

const EmptyWarning = styled.div`
  padding: 16px;
`;

const FeedbackItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedbackItem = styled(Link)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const FeedbackItemHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 675px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const HeaderTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`;

const LeaveFeedbackBtn = styled(Button)`
  padding: 6px 12px;
`;

const FeedbackCard = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid #e8ecee;
  border-radius: 8px;
`;

const FeedbackHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0;
`;

const FeedbackBody = styled.div`
  padding: 0 24px;
`;

const FeedbackComments = styled.div`
  background: #f6f8f9;
  border-top: 1px solid #e8ecee;
  flex-shrink: 0;
`;

const FeedbackTitle = styled.h3`
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
`;

const SummarySaveButton = styled(Button)`
  padding: 6px 12px;
  margin-top: 4px;
  background: #000;
  color: #fff;
  margin-left: -5px;
`;

const SaveButton = styled(Button)`
  padding: 6px 12px;
  margin-left: 4px;
  background: #000;
  color: #fff;
`;
const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SummaryEditContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageTitleInput = styled.input`
  width: 100%;

  border: none;
  background: #383838;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 18px;
  color: #fff;
`;

type EditableSummaryProps = {
  summary: string;
  onChangeSummary: (summary: string) => void;
  editable: boolean;
};

const EditableSummary: React.FC<EditableSummaryProps> = ({ editable, summary: initialSummary, onChangeSummary }) => {
  const [summary, setSummary] = useState(initialSummary);
  const [edit, setEdit] = useState(false);
  const $container = useRef<HTMLDivElement>(null);
  const $content = useRef<HTMLTextAreaElement>(null);
  useOnOutsideClick($container, edit, () => setEdit(false), null);
  const onSave = () => {
    setEdit(false);
    onChangeSummary(summary);
  };
  useEffect(() => {
    if ($content && $content.current) {
      $content.current.select();
    }
  }, [edit]);
  return (
    <SummaryEditContainer ref={$container}>
      {edit ? (
        <>
          <SummaryEdit
            value={summary}
            onKeyDown={e => {
              if (e.keyCode === 13 && e.ctrlKey) {
                e.preventDefault();
                onSave();
              }
            }}
            onChange={e => setSummary(e.currentTarget.value)}
            ref={$content}
          />
          <SummarySaveButton onClick={onSave}>Save</SummarySaveButton>
        </>
      ) : (
        <Summary
          onClick={() => {
            if (editable) {
              setEdit(true);
            }
          }}
        >
          {summary}
        </Summary>
      )}
    </SummaryEditContainer>
  );
};

type EditableTitleProps = {
  editable: boolean;
  title: string;
  onChangeTitle: (title: string) => void;
};

const EditableTitle: React.FC<EditableTitleProps> = ({ editable, title: initialTitle, onChangeTitle }) => {
  const [title, setTitle] = useState(initialTitle);
  const [edit, setEdit] = useState(false);
  const $container = useRef<HTMLDivElement>(null);
  const $content = useRef<HTMLInputElement>(null);
  useOnOutsideClick($container, edit, () => setEdit(false), null);
  const onSave = () => {
    setEdit(false);
    onChangeTitle(title);
  };
  useEffect(() => {
    if ($content && $content.current) {
      $content.current.select();
    }
  }, [edit]);
  return (
    <EditContainer ref={$container}>
      {edit ? (
        <>
          <PageTitleInput
            value={title}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                onSave();
              }
            }}
            onChange={e => setTitle(e.currentTarget.value)}
            ref={$content}
          />
          <SaveButton onClick={onSave}>Save</SaveButton>
        </>
      ) : (
        <FeedbackTitle
          onClick={() => {
            if (editable) {
              setEdit(true);
            }
          }}
        >
          {title}
        </FeedbackTitle>
      )}
    </EditContainer>
  );
};

const FeedbackControls = styled.div`
  display: flex;
  align-items: center;
`;

const FeedbackControlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const FeedbackBodyItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const CreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

const Summary = styled.div`
  padding-bottom: 24px;
`;

const SummaryTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #000;
`;

const SummaryEdit = styled(TextareaAutosize)`
  width: 100%;
  line-height: 20px;
  border-radius: 6px;
  color: #000;
  background: #fff;
  border: none;
  margin-left: -5px;
  padding: 4px 6px;
  border: 1px solid #ccc;
  transition: max-height 200ms, height 200ms, min-height 200ms;
  min-height: 36px;
  max-height: 36px;
  &:not(:focus) {
    height: 36px;
  }
  &:focus {
    min-height: 80px;
    max-height: none;
    line-height: 20px;
  }
`;

type LatestFeedbackProps = {
  name: string;
  createdBy: { id: string; fullName: string };
  createdAt: string;
  summary: string;
  comments: Array<{ id: string; summary: string; createdAt: string; createdBy: { id: string; fullName: string } }>;
  onCreateComment: (summary: string) => void;
  onDeleteComment: (id: string) => void;
  onEditComment: (id: string, message: string) => void;
  onExtraActions: ($target: React.RefObject<HTMLElement>) => void;
  onChangeName: (name: string) => void;
  onChangeSummary: (summary: string) => void;
  me: { id: string; profileIcon: ProfileIcon };
};
const LatestFeedback: React.FC<LatestFeedbackProps> = ({
  name,
  onChangeSummary,
  onChangeName,
  onExtraActions,
  createdAt,
  createdBy,
  summary,
  me,
  comments,
  onCreateComment,
  onDeleteComment,
  onEditComment,
}) => {
  const $extra = useRef<HTMLDivElement>(null);
  const editable = me.id === createdBy.id;
  return (
    <FeedbackCard>
      <FeedbackHeader>
        <EditableTitle editable={editable} title={name} onChangeTitle={onChangeName} />
        {editable && (
          <FeedbackControls>
            <FeedbackControlIcon ref={$extra} onClick={() => onExtraActions($extra)}>
              <Ellipsis width={16} height={16} />
            </FeedbackControlIcon>
          </FeedbackControls>
        )}
      </FeedbackHeader>
      <FeedbackBody>
        <FeedbackBodyItems>
          <Creator>
            <CreatorName>{createdBy.fullName}</CreatorName>
            <CreatorDate>{dayjs.duration(dayjs(createdAt).diff(dayjs())).humanize(true)}</CreatorDate>
          </Creator>
          <SummaryTitle>Summary</SummaryTitle>
          <EditableSummary editable={editable} summary={summary} onChangeSummary={onChangeSummary} />
        </FeedbackBodyItems>
        <Comments onEdit={onEditComment} onDelete={onDeleteComment} comments={comments} />
      </FeedbackBody>
      <FeedbackComments>
        <CommentCreator member={me} onCreateComment={onCreateComment} />
      </FeedbackComments>
    </FeedbackCard>
  );
};

const CommentCreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CommentCreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

type FeedbackProps = {
  projectID: string;
};

const Feedback: React.FC<FeedbackProps> = ({ projectID }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const params = QueryString.parse(location.search);
  let feedbackID: string | null = null;
  if (typeof params.feedbackID === 'string') {
    feedbackID = params.feedbackID;
  }

  const { hidePopup, showPopup, setTab } = usePopup();
  const [updateProjectFeedbackName] = useUpdateProjectFeedbackNameMutation();
  const [updateProjectFeedbackSummary] = useUpdateProjectFeedbackSummaryMutation();
  const [updateFeedbackComment] = useUpdateFeedbackCommentMutation();
  const [deleteFeedbackComment] = useDeleteFeedbackCommentMutation({
    update: (client, r) =>
      updateApolloCache<FeedbackQuery>(
        client,
        FeedbackDocument,
        cache =>
          produce(cache, draftCache => {
            if (draftCache.feedback.feedback) {
              draftCache.feedback.feedback.comments = draftCache.feedback.feedback.comments.filter(
                c => c.id !== r.data.deleteFeedbackComment.feedbackCommentID,
              );
            }
          }),
        { projectID, feedbackID },
      ),
  });
  const [createFeedbackComment] = useCreateFeedbackCommentMutation({
    update: (client, r) =>
      updateApolloCache<FeedbackQuery>(
        client,
        FeedbackDocument,
        cache =>
          produce(cache, draftCache => {
            if (draftCache.feedback.feedback) {
              draftCache.feedback.feedback.comments.push({ ...r.data.createFeedbackComment.comment });
            }
          }),
        { projectID, feedbackID },
      ),
  });
  const { data: afData, loading: afLoading, refetch: afRefetch } = useAllFeedbackQuery({
    variables: { projectID },
    fetchPolicy: 'network-only',
  });
  const { data: fData, loading: fLoading, refetch } = useFeedbackQuery({
    variables: { projectID, feedbackID },
    fetchPolicy: 'network-only',
  });
  const [deleteProjectFeedback] = useDeleteProjectFeedbackMutation({
    onCompleted: () => {
      refetch();
      afRefetch();
    },
  });
  const { data: mData } = useMeQuery();
  return (
    <Container>
      <ContentContainer>
        <Switch>
          <Route path={`/projects/:projectID/feedback/new`}>
            <CreateFeedback projectID={projectID} />
          </Route>

          <Route path={`/projects/:projectID/feedback`}>
            <Header>
              <HeaderTitle>Latest feedback</HeaderTitle>
              <Link to={`/projects/${projectID}/feedback/new`}>
                <LeaveFeedbackBtn>Leave feedback</LeaveFeedbackBtn>
              </Link>
            </Header>
            {!fLoading && fData && mData && fData.feedback.feedback && (
              <LatestFeedback
                onExtraActions={$target => {
                  console.log($target);
                  showPopup(
                    { ref: $target },
                    <>
                      <Popup tab={0} title={null}>
                        <MenuItems>
                          <MenuItem onClick={() => setTab(1)}>Delete</MenuItem>
                        </MenuItems>
                      </Popup>
                      <Popup tab={1} title="Do you want to delete this feedback?">
                        <WarningLabel>
                          Are you sure you want to delete this feedback? This can not be undone.
                        </WarningLabel>
                        <ConfirmDelete
                          onClick={() => {
                            hidePopup();
                            if (fData.feedback.feedback) {
                              deleteProjectFeedback({ variables: { feedbackID: fData.feedback.feedback.id } });
                            }
                          }}
                          color="danger"
                        >
                          Delete feedback
                        </ConfirmDelete>
                      </Popup>
                    </>,
                  );
                }}
                onChangeName={name => {
                  if (fData.feedback.feedback) {
                    updateProjectFeedbackName({ variables: { name, feedbackID: fData.feedback.feedback.id } });
                  }
                }}
                onChangeSummary={summary => {
                  if (fData.feedback.feedback) {
                    updateProjectFeedbackSummary({ variables: { summary, feedbackID: fData.feedback.feedback.id } });
                  }
                }}
                me={{
                  id: mData.me.id,
                  profileIcon: {
                    initials: mData.me.fullName.charAt(0),
                    url: null,
                    bgColor: '#ccc',
                  },
                }}
                onEditComment={(id, message) => {
                  updateFeedbackComment({ variables: { commentID: id, message } });
                }}
                name={fData.feedback.feedback.name}
                summary={fData.feedback.feedback.summary}
                createdBy={fData.feedback.feedback.createdBy}
                createdAt={fData.feedback.feedback.createdAt}
                comments={fData.feedback.feedback.comments}
                onDeleteComment={id => {
                  deleteFeedbackComment({ variables: { feedbackCommentID: id } });
                  hidePopup();
                }}
                onCreateComment={summary => {
                  if (fData.feedback.feedback) {
                    createFeedbackComment({
                      variables: {
                        feedbackID: fData.feedback.feedback.id,
                        summary,
                      },
                    });
                  }
                }}
              />
            )}
          </Route>
        </Switch>
      </ContentContainer>
      <Sidebar>
        <SidebarTitle>Recent feedback</SidebarTitle>
        <SidebarCard>
          {!afLoading && afData && (
            <FeedbackItems>
              {afData.allFeedback.feedback.length === 0 && <EmptyWarning>No recent feedback</EmptyWarning>}
              {afData.allFeedback.feedback.map(feedback => (
                <FeedbackItem to={`${match.url}?feedbackID=${feedback.id}`} key={feedback.id}>
                  <FeedbackItemHeader>
                    <CommentCreatorName>{feedback.name}</CommentCreatorName>
                    <CommentCreatorDate>
                      {dayjs.duration(dayjs(feedback.createdAt).diff(dayjs())).humanize(true)}
                    </CommentCreatorDate>
                  </FeedbackItemHeader>
                </FeedbackItem>
              ))}
            </FeedbackItems>
          )}
        </SidebarCard>
      </Sidebar>
    </Container>
  );
};

export default Feedback;
