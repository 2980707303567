import React from 'react';
import Icon, { IconProps } from './Icon';

const Inbox: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 1000 1000">
      <g>
        <path
          d="M854.11,263.58H140.02c-9.58,0-12.77,12.81-4.31,17.31l352.04,187.17c7.58,4.03,16.67,4,24.22-0.08
		l346.51-187.12C866.87,276.32,863.65,263.58,854.11,263.58z"
        />
        <path
          d="M480.06,539.54L47.62,309.62c-4.31-2.29-9.5,0.83-9.5,5.71v439.34c0,3.57,2.9,6.47,6.47,6.47h910.83
		c3.57,0,6.47-2.9,6.47-6.47V311.68c0-4.9-5.23-8.02-9.54-5.69L520.11,539.41C507.62,546.15,492.59,546.2,480.06,539.54z"
        />
      </g>
    </Icon>
  );
};

export default Inbox;
