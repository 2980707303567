import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import * as H from 'history';

import Dashboard from 'Dashboard';
import Users from 'Users';
import Inbox from 'Profile/Inbox';
import Login from 'Auth';
import Profile from 'Profile';
import styled from 'styled-components';
import Projects from 'Projects';
import ProjectsEdit from 'Projects/Edit';
import { SignOut } from 'shared/icons';
import { useCurrentUser } from 'App/context';
import cache from 'App/cache';

const MainContent = styled.div`
  padding: 0 0 0 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

type RoutesProps = {
  history: H.History;
};

const Routes: React.FC<RoutesProps> = () => {
  const { setUser } = useCurrentUser();
  const history = useHistory();

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <MainContent>
        <Route exact path="/" component={Dashboard} />
        <Route path="/projects/:projectID" component={Projects} />
        <Route path="/users" component={Users} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/inbox" component={Inbox} />
      </MainContent>
    </Switch>
  );
};

export default Routes;
