import React from 'react';
import Icon, { IconProps } from './Icon';

const NavLogo: React.FC<IconProps> = ({ width = '16px', height = '16px', className }) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 894.6 365.4">
      <g>
	<path  d="M87,182.6c0-12.3,0-24.3,0-36.2c0-0.9,0-1.8,0.2-2.6c0.3-1.4,1.2-2.5,2.6-2.9c3.5-1.1,7.1-0.7,10.7-0.8
		c0.5,0,0.7,0.3,0.7,0.8c0,0.5,0,0.9,0,1.4c0,40.2,0,80.4,0,120.7c0,1.6-0.5,2.4-2,3c-10.2,4.1-20.7,5.3-31.4,3.6
		c-9-1.4-14.1-7.3-16.9-15.5c-2.4-7.1-3.1-14.5-3.3-22c-0.2-9.5,0.1-19,2.5-28.3c1.4-5.7,3.5-11.1,7.2-15.8
		c7-8.9,18.9-11.2,28.8-5.7C86.3,182.4,86.5,182.4,87,182.6z M87,224.5c0-9.4-0.1-18.8,0.1-28.2c0-2-0.7-2.9-2.4-3.5
		c-5.8-2-12.2-1.1-15.9,5.1c-2.3,3.8-3.3,7.9-4,12.2c-1.5,9.5-1.4,19-0.7,28.6c0.3,4.3,1,8.6,2.8,12.5c1.5,3.4,4,5.7,7.8,6.1
		c3.6,0.4,7.2-0.1,10.6-1.5c1.3-0.5,1.8-1.3,1.8-2.8C86.9,243.5,87,234,87,224.5z"/>
	<path  d="M583.2,260.8c-2.8-1-5.3-1.8-7.5-3.3c-3.8-2.6-4.7-5.6-2.8-9.8c2-4.3,5.2-7.6,8.7-10.5c1-0.8,1.1-1.2,0-2
		c-4.5-3.3-7-7.9-8.2-13.2c-2.1-9.2-2.3-18.4,1.1-27.4c3.8-10.1,11.5-15.2,21.9-16.6c3.9-0.5,7.8-0.2,11.6,0.6
		c0.7,0.1,1.4,0.1,2.1,0.1c6.3,0,12.5,0.1,18.8,0c1.4,0,1.9,0.5,2.2,1.7c0.2,0.8,0.4,1.6,0.4,2.4c0.3,2.8-1.1,4.4-4,4.4
		c-2.8,0.1-5.6-0.3-9-0.9c3.7,2.9,6.2,6.3,7.4,10.4c3,10.5,2.3,20.9-2.8,30.6c-3.9,7.5-10.7,11.2-19,12.3c-4.7,0.6-9.4,0.5-14.1-0.7
		c-0.6-0.2-1.1,0-1.6,0.4c-2.1,1.8-4,3.8-5.5,6.2c-1.8,3.1-1.4,4.5,1.9,5.8c3.6,1.5,7.4,2.1,11.1,2.8c6.5,1.2,13.1,1.4,19.5,3.2
		c1.4,0.4,2.7,0.8,4,1.3c8.3,3.3,11.8,9.6,10.9,18.5c-1,10.6-7.2,17.4-16.8,21.3c-7.4,3-15.2,3.2-23,2.7c-3.2-0.2-6.4-0.8-9.5-1.8
		c-10.3-3.4-14.8-12.8-10.9-23c2.2-5.8,6.2-10.3,11-14.1C581.9,261.8,582.4,261.4,583.2,260.8z M617.9,206.4c0-2.4-0.1-4.7-0.5-7.1
		c-1.1-5.8-3.6-10.6-9.5-12.7c-9.1-3.2-20.7-1-24.6,12.2c-1.8,6.1-1.7,12.4-0.8,18.6c0.5,3.7,1.6,7.2,4.2,9.9
		c7.1,7.5,21.6,5.8,27-3.1C617,218.7,617.9,212.6,617.9,206.4z M621,276.5c-0.1-3.9-1-6.8-3.9-8.9c-1.7-1.2-3.6-1.9-5.6-2.5
		c-5.1-1.3-10.3-1.1-15.4-2.3c-4.1-0.9-6.6,0.6-9.1,3.2c-3.4,3.5-6.5,7.2-7.6,12.1c-1.5,6.4,1.2,11.1,7.5,12.9
		c1.4,0.4,2.8,0.7,4.1,0.8c6.9,0.7,13.7,0.7,20.1-2.3C616.9,287,620.7,282.7,621,276.5z"/>
	<path  d="M282.4,228.9c0.1-10.2,0.7-20.3,3.9-30.1c1.9-5.8,4.8-11,9.9-14.7c6.2-4.4,13.2-5.3,20.5-4.5
		c10.4,1.2,16.2,7.3,18.1,18.3c1.4,7.9,0.8,15.8,0.2,23.7c-0.2,2.5-0.7,4.9-1,7.4c-0.1,1-0.6,1.4-1.7,1.6c-11,1.8-22,2.4-33.1,2.4
		c-1.7,0-2.1,0.4-2,2.1c0.3,3.8,0.7,7.6,1.8,11.3c2,6.7,6.3,9.9,13.3,10c6.9,0.1,13.4-1.5,19.6-4.7c1.2-0.6,1.7-0.5,1.9,0.9
		c0.4,2.3,0.6,4.6,0.8,6.8c0.2,2.7-0.9,4.5-3,6c-2.2,1.5-4.7,2.5-7.3,3.2c-6.9,1.9-14,2.3-21.1,1.2c-8.7-1.3-14.3-6.6-17.3-14.7
		c-2.8-7.5-3.4-15.4-3.6-23.3C282.4,230.7,282.4,229.8,282.4,228.9z M321.7,208.7c-0.1-3.2-0.2-7.1-1.4-11c-0.8-2.5-2.3-4.6-4.9-5.3
		c-4.1-1.2-7.8-0.5-10.9,2.6c-2.5,2.5-3.7,5.7-4.7,9c-1.6,5.3-2,10.8-2.4,16.2c-0.1,0.9,0.3,1.1,1.1,1.2c1.9,0.3,3.8,0,5.6-0.1
		c5.1-0.1,10.2-0.4,15.3-1.5c1.1-0.2,1.6-0.6,1.8-1.8C321.5,215.2,321.7,212.4,321.7,208.7z"/>
	<path  d="M356.8,226.7c0-13.2,0-26.3,0-39.5c0-1.5,0.5-2.2,1.9-2.6c9.2-3.3,18.6-5.5,28.4-5.1c3.9,0.2,7.8,0.6,11.6,2
		c6.5,2.6,9.2,7.9,10.1,14.4c0.4,3.1,0.4,6.1,0.4,9.2c0,20.1,0,40.2,0,60.3c0,2.3-0.4,2.8-2.7,2.9c-3.6,0.2-7.2,0.1-10.8,0.2
		c-0.8,0-1.2-0.3-1.1-1.1c0-0.5,0-0.9,0-1.4c0-19.7,0-39.3-0.1-59c0-3.1,0-6.2-0.9-9.3c-1.2-3.8-4-5.6-8-5.6c-4.3,0-8.5,0.4-12.5,2
		c-1.4,0.6-1.9,1.3-1.9,2.8c0.1,22.5,0,45,0,67.5c0,3.5-0.3,3.8-3.8,3.9c-2.9,0.1-5.8,0-8.7,0.2c-1.4,0.1-1.8-0.4-1.8-1.8
		C356.8,253.4,356.8,240.1,356.8,226.7z"/>
	<path  d="M496.2,181.4c0-6.5,0-12.7,0-18.8c0-6.3,0-12.5,0-18.8c0-4,0.8-4.8,4.7-5.3c5.2-0.6,5.2-0.6,5.2,4.5
		c0,39.4,0,78.8,0,118.2c0,1.6-0.4,2.5-2,3.2c-10.1,4-20.6,5.3-31.3,3.6c-8.2-1.3-13.2-6.5-16.1-14c-2-5.3-3-10.9-3.4-16.6
		c-0.8-10.4-0.8-20.8,1.3-31c1.7-8,4.4-15.4,10.2-21.4c5.5-5.6,12.3-7.7,20-7.1C488.8,178.1,492.5,179.1,496.2,181.4z M496.5,208.7
		c-0.1,0-0.2,0-0.2,0c0-5.8,0-11.6,0-17.4c0-1.1-0.3-1.8-1.3-2.4c-6.2-4.1-16.4-3.2-21.9,2.1c-5.1,4.9-7.2,11.3-8.4,17.9
		c-1.6,8.6-1.6,17.4-1,26.1c0.4,5.5,1.1,10.9,3.4,16c1.8,4,4.5,7,8.9,8c6.5,1.5,12.9,0.9,19-1.8c1.2-0.5,1.6-1.3,1.6-2.6
		C496.4,239.3,496.5,224,496.5,208.7z"/>
	<path  d="M287.8,141c18-11.1,35.7-22.1,53.5-33c5.6-3.5,11.2-6.9,16.8-10.5c0.9-0.5,1.4-0.7,2.2,0.2
		c14.2,14.2,28.4,28.4,42.6,42.6c0.2,0.2,0.3,0.3,0.6,0.7C364.9,141,326.5,141,287.8,141z"/>
	<path  d="M408.9,20c0,38.7,0,76.9,0,115.2c-0.6,0.2-0.8-0.3-1.1-0.6c-14-14-28.1-28.1-42.1-42.1c-0.7-0.7-1-1.2-0.4-2.2
		C379.7,67.2,394,44,408.3,20.9C408.4,20.7,408.5,20.5,408.9,20z"/>
	<path  d="M243.4,252.8c1.1-8.5,2-17,3.3-25.4c1.2-8.1,2.9-16.2,4.3-24.3c1.1-6.3,2.6-12.6,3.5-19
		c0.2-1.3,0.9-2.4,2.4-2.6c4-0.6,8-0.3,12-0.4c1.1,0,0.7,0.7,0.6,1.2c-2.3,10.3-4.7,20.5-7,30.8c-2.7,11.7-5.4,23.4-8,35.1
		c-1.5,6.4-3,12.7-4.3,19.1c-0.3,1.4-1,2-2.3,2c-2.6,0.1-5.2,0.1-7.8,0.2c-2,0.1-3.3-0.9-4.4-2.5c-1.1-1.8-1.8-3.7-2.3-5.7
		c-2-8.2-3.8-16.4-5.8-24.6c-2.3-10-4.7-19.9-7-29.8c-1.8-7.4-3.6-14.7-5.3-22.1c-0.4-1.7,0.3-3,2-3.3c4.2-0.7,8.5-0.4,12.7-0.5
		c0.9,0,1,0.8,1.1,1.3c1.8,9.1,3.6,18.1,5.4,27.2c1.5,7.2,2.6,14.5,3.8,21.8c1,6.4,1.7,12.9,2.6,19.3c0.1,0.7,0.3,1.5,0.4,2.2
		C243.2,252.9,243.3,252.8,243.4,252.8z"/>
	<path  d="M788.7,216c-0.2-6.6,0.5-13-0.4-19.3c-0.9-6.1-4.3-9.1-10.5-9.4c-9.1-0.5-18,0.6-26.6,3.7
		c-1.2,0.4-1.6,0.2-2-0.9c-0.4-1.2-0.7-2.5-0.8-3.8c-0.2-2.4,0.8-3.9,3.1-4.8c5.7-2.2,11.7-3,17.7-3.4c5-0.4,10-0.7,15,0.2
		c8,1.5,12.6,6.2,13.7,14.3c0.3,2.1,0.4,4.2,0.4,6.4c-0.1,13.6-0.1,27.3-0.2,40.9c-0.1,7-0.4,14-0.5,21.1c0,1.1-0.5,1.7-1.4,2.2
		c-6.6,3.7-13.7,5.3-21.2,5.5c-3.6,0.1-7.2,0-10.8-0.6c-7.9-1.5-12.6-6.3-14.5-14c-2-8.2-1.9-16.5,0.4-24.7
		c2.7-9.4,9.7-14.6,19.9-15.1c3.1-0.2,6.2-0.2,9.4,0C782.5,214.4,785.5,214.9,788.7,216z M788.7,232.6c0-2.5-0.1-5,0-7.4
		c0.1-1.6-0.6-2.2-2-2.5c-4.8-1-9.7-1.4-14.6-0.8c-6.5,0.7-10.9,4.2-12.6,10.5c-1.6,5.9-1.7,11.9-0.3,17.9c1.3,5.8,4.7,8.8,10.4,9.6
		c1.5,0.2,2.9,0.3,4.4,0.3c4.4,0,8.6-0.8,12.4-2.9c0.9-0.5,1.4-1.2,1.5-2.4C788.3,247.5,788.6,240,788.7,232.6z"/>
	<path  d="M698.9,219.6c0-9.9,0-19.8,0-29.8c0-1.3-0.3-1.8-1.7-1.8c-3.2,0.1-6.5,0-9.7,0.1c-1.1,0-1.6-0.3-1.5-1.5
		c0.1-1.6,0.2-3.3,0.2-4.9c0.1-1.7,0.9-2.4,2.6-2.3c2.8,0.1,5.6-0.1,8.3,0.1c1.4,0.1,1.9-0.3,1.9-1.8c-0.1-8.6,0-17.1,0-25.7
		c0-2.8,0.5-3.4,3.2-3.9c6.4-1,6.4-1,6.4,5.3c0,8.1,0,16.2,0,24.3c0,1.4,0.4,1.8,1.8,1.8c5.8-0.1,11.7,0,17.5-0.1
		c1.7,0,2.1,0.5,2,2.1c-0.1,1.4,0,2.8-0.2,4.2c-0.3,2.2-0.6,2.5-2.8,2.5c-5.4,0-10.9,0.1-16.3,0c-1.6,0-2,0.4-2,2
		c0.1,17.6,0,35.2,0.1,52.8c0,3.1,0.1,6.1,0.6,9.2c1,6.4,4.2,8.7,10.6,7.7c2.4-0.4,4.7-1,6.9-2.1c1.2-0.6,1.6-0.2,1.7,1
		c0,0.6,0.2,1.3,0.3,1.9c0.3,3.4-0.6,5.1-3.8,6.3c-5.3,2-10.7,2.4-16.2,0.9c-5.3-1.5-7.6-5.7-8.7-10.6c-0.8-3.8-1.1-7.6-1.1-11.4
		C698.9,237,698.9,228.3,698.9,219.6z"/>
	<path  d="M126.1,227.6c0-12.9,0-25.9,0-38.8c0-0.8-0.1-1.6,0.8-2.1c8.6-5.5,17.8-8.4,28.1-6.9c4.1,0.6,6.6,2.4,7.1,5.4
		c0.5,3.1-0.1,6.2-1.1,9.2c-0.4,1.2-1.1,0.5-1.7,0.3c-5-2.1-9.9-1.9-14.8,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-2.3,1-3.4,2.4-3.3,5.2
		c0.1,21.4,0.1,42.9,0,64.3c0,3.3-0.3,3.7-3.6,3.8c-2.9,0.1-5.9,0.1-8.8,0.2c-1.6,0.1-2.1-0.3-2.1-2
		C126.1,253.6,126.1,240.6,126.1,227.6z"/>
	<path  d="M834.7,202.3c0,20.6,0,41.1,0,61.7c0,2.4-0.2,2.7-2.5,2.9c-2.1,0.1-4.1,0.1-6.2,0.1c-0.8,0-1.1-0.3-1-1.1
		c0-0.4,0-0.7,0-1.1c0-40.4,0-80.8,0-121.2c0-0.7,0.1-1.4,0.1-2.1c0.1-1.6,1.1-2.5,2.7-2.9c2-0.4,4-0.3,6-0.4c0.7,0,1,0.2,0.9,0.9
		c0,0.5,0,0.9,0,1.4C834.7,161.2,834.7,181.7,834.7,202.3z"/>
	<path  d="M195.6,224.2c0,13.7,0,27.4,0,41.1c0,2.3-0.4,2.9-2.7,3c-3.6,0.2-7.2,0.2-10.8,0.3c-0.8,0-1.2-0.3-1.1-1.1
		c0-0.4,0-0.7,0-1.1c0-26,0-52.1,0-78.1c0-0.5,0-1.1,0-1.6c0.2-3.3,1.4-4.6,4.6-5.2c2.9-0.5,5.9-0.3,8.8-0.4c1.2,0,1.1,0.7,1.1,1.5
		c0,7.7,0,15.5,0,23.2C195.6,211.9,195.6,218.1,195.6,224.2z"/>
	<path  d="M662.6,222.8c0,13.8,0,27.5,0,41.3c0,2.3-0.2,2.6-2.5,2.7c-2.2,0.2-4.4,0.1-6.5,0.1c-0.8,0-1.1-0.3-1-1.1
		c0-0.4,0-0.8,0-1.2c0-26.6,0-53.3,0-79.9c0-4,0.9-5,4.9-5.3c1.1-0.1,2.3,0,3.4-0.1c1.5-0.2,1.7,0.4,1.7,1.8
		C662.6,195,662.6,208.9,662.6,222.8z"/>
	<path  d="M544.8,222.7c0,13.7,0,27.4,0,41.1c0,2.5-0.2,2.8-2.8,3c-2.1,0.1-4.1,0.1-6.2,0.1c-0.8,0-1.1-0.3-1-1
		c0-0.4,0-0.7,0-1.1c0-26.7,0-53.4,0-80.1c0-3.9,0.8-4.8,4.6-5.2c5.4-0.6,5.4-0.6,5.4,4.7C544.8,197,544.8,209.8,544.8,222.7z"/>
	<path  d="M196.6,151.9c-0.1,2.9-0.2,5.2-1,7.4c-1.3,3.4-3.9,5.3-7.4,5.1c-3.6-0.2-5.6-2-6.4-5.9
		c-0.8-3.7-0.9-7.5,0.6-11.1c1.7-4.2,7.2-6.1,11.2-3.8c1.7,1,2.4,2.6,2.7,4.4C196.4,149.4,196.6,150.9,196.6,151.9z"/>
	<path  d="M534.4,150.3c-0.1-1.8,0.1-3.5,0.7-5.1c1-2.6,3.1-3.7,6.1-3.3c2.3,0.3,3.6,1.7,4.1,4.4
		c0.5,2.6,0.4,5.2-0.4,7.7c-0.7,2.3-2.2,3.7-4.6,4.1c-2.7,0.3-4.7-1-5.5-3.6C534.5,153,534.4,151.6,534.4,150.3z"/>
	<path  d="M663.5,149.3c0,1.9-0.2,3.8-1.1,5.5c-1.1,2.4-3.1,3.5-5.7,3.2c-2.4-0.3-3.8-1.8-4.3-4.5
		c-0.4-2.4-0.4-4.8,0.2-7.2c0.8-3.2,2.7-4.6,5.8-4.5c2.8,0.1,4.2,1.5,4.8,4.8C663.5,147.5,663.5,148.4,663.5,149.3z"/>
</g>


    </Icon>
  );
};

export default NavLogo;
