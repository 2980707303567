import React from 'react';
import styled from 'styled-components';
import Button from 'shared/components/Button';
import { Trash } from 'shared/icons';
import CommentCreator from 'Projects/Comments';
import TaskAssignee from 'shared/components/TaskAssignee';
import Ellipsis from 'shared/icons/Ellipsis';
import Modal from 'shared/components/Modal';
import { useHistory } from 'react-router';
import TaskDetails from 'shared/components/TaskDetails';
import {
  useUpdateTaskCommentMutation,
  useDeleteTaskCommentMutation,
  useCreateTaskCommentMutation,
  useUpdateTaskNameMutation,
  useFindTaskQuery,
  FindTaskDocument,
  FindTaskQuery,
  useMeQuery,
  useUpdateTaskDescriptionMutation,
  FindProjectQuery,
  FindProjectDocument,
} from 'shared/generated/graphql';
import updateApolloCache from 'shared/utils/cache';
import produce from 'immer';
import { usePopup } from 'shared/components/PopupMenu';

type DetailsProps = {
  projectID: string;
  taskID: string;
};
const Details: React.FC<DetailsProps> = ({ projectID, taskID }) => {
  const history = useHistory();
  const [setTaskName] = useUpdateTaskNameMutation();
  const [updateTaskComment] = useUpdateTaskCommentMutation();
  const { hidePopup } = usePopup();
  const [deleteTaskComment] = useDeleteTaskCommentMutation({
    update: (client, r) =>
      updateApolloCache<FindTaskQuery>(
        client,
        FindTaskDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.findTask.comments = cache.findTask.comments.filter(
              c => c.id !== r.data.deleteTaskComment.commentID,
            );
          }),
        { taskID },
      ),
  });
  const { data, loading, error } = useFindTaskQuery({ variables: { taskID } });
  const [createTaskComment] = useCreateTaskCommentMutation({
    update: (client, r) => {
      updateApolloCache<FindProjectQuery>(
        client,
        FindProjectDocument,
        cache =>
          produce(cache, draftCache => {
            if (data) {
              const idx = cache.findProject.taskGroups.findIndex(tg => tg.id === data.findTask.taskGroup.id);
              if (idx !== -1) {
                const taskIdx = cache.findProject.taskGroups[idx].tasks.findIndex(t => t.id === taskID);
                if (taskIdx !== -1) {
                  draftCache.findProject.taskGroups[idx].tasks[taskIdx].badges.comments += 1;
                }
              }
            }
          }),
        { projectID },
      );
      updateApolloCache<FindTaskQuery>(
        client,
        FindTaskDocument,
        cache =>
          produce(cache, draftCache => {
            draftCache.findTask.comments.push(r.data.createTaskComment.comment);
          }),
        { taskID },
      );
    },
  });
  const [updateTaskDescription] = useUpdateTaskDescriptionMutation();
  const { data: mData } = useMeQuery();
  if (data && mData) {
    return (
      <Modal
        width={1070}
        onClose={() => {
          history.push(`/projects/${projectID}`);
        }}
        renderContent={() => (
          <TaskDetails
            me={mData.me}
            onSaveDescription={(_id, desc) => {
              updateTaskDescription({ variables: { taskID, description: desc } });
            }}
            onEditComment={(commentID, summary) => {
              updateTaskComment({ variables: { commentID, summary } });
            }}
            onDeleteComment={commentID => {
              hidePopup();
              deleteTaskComment({ variables: { commentID } });
            }}
            onCreateComment={summary => {
              createTaskComment({ variables: { taskID, summary } });
            }}
            onTaskNameChange={(id, name) => {
              setTaskName({ variables: { taskID, name } });
            }}
            task={{
              id: data.findTask.id,
              name: data.findTask.name,
              description: data.findTask.description,
              comments: data.findTask.comments,
            }}
          />
        )}
      />
    );
  }
  return null;
};

export default Details;
