import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import TaskAssignee from 'shared/components/TaskAssignee';
import Ellipsis from 'shared/icons/Ellipsis';
import { usePopup, Popup } from 'shared/components/PopupMenu';
import Button from 'shared/components/Button';
import dayjs from 'dayjs';
import CommentCreator from 'Projects/CommentCreator';
import { useCurrentUser } from 'App/context';

const CommentsContainer = styled.div`
  border-color: #e8ecee;
  border-style: solid none none;
  border-width: 1px;
  padding-top: 8px;
`;

const ExtraActions = styled.div`
  opacity: 0;
  position: absolute;
  top: 4px;
  right: 12px;
  cursor: pointer;
  border-radius: 6px;
  padding: 2px 4px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const CommentBlock = styled.div`
  padding: 8px 24px;
  margin: 0 -24px;
  &:hover ${ExtraActions} {
    opacity: 1;
  }
`;

const CommentBlockContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const CommentProfile = styled(TaskAssignee)`
  margin-right: 8px;
  align-items: start;
`;

const CommentContent = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  overflow-wrap: break-word;
`;
const CommentSummary = styled.div`
  font-size: 14px;
  color: #000;
`;

const CommentContentHeader = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 0 16px 0 0;
`;

const CommentCreatorName = styled.span`
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const CommentCreatorDate = styled.span`
  display: flex;
  padding-left: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #6f7782;
`;

type CommentActionsProps = {
  onDelete: () => void;
  onEdit: () => void;
};

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
`;

const Item = styled.li`
  cursor: pointer;
  padding: 6px 12px;
  width: 100%;
  font-size: 16px;
  color: #000;
  border-radius: 3px;
  &:hover {
    background: rgba(21, 27, 38, 0.04);
  }
`;

const ConfirmButton = styled(Button)`
  width: 100%;
  padding: 6px 12px;
`;

const Description = styled.p`
  font-size: 16px;
  margin: 8px 12px;
`;

const CommentActions: React.FC<CommentActionsProps> = ({ onDelete, onEdit }) => {
  const { setTab } = usePopup();
  return (
    <>
      <Popup tab={0} title={null}>
        <Menu>
          <Item onClick={onEdit}>Edit</Item>
          <Item onClick={() => setTab(2, { width: 312 })}>Delete</Item>
        </Menu>
      </Popup>
      <Popup tab={2} title="Delete comment?">
        <Description>Are you sure you want to delete this comment?</Description>
        <ConfirmButton onClick={() => onDelete()} color="danger">
          Delete comment
        </ConfirmButton>
      </Popup>
    </>
  );
};

type CommentProps = {
  comment: { id: string; summary: string; createdAt: string; createdBy: { id: string; fullName: string } };
  onDelete: () => void;
  onEdit: (message: string) => void;
};

const Comment: React.FC<CommentProps> = ({ comment, onDelete, onEdit }) => {
  const { showPopup, hidePopup } = usePopup();
  const $actions = useRef<HTMLDivElement>(null);
  const { user } = useCurrentUser();
  const isEditable = user ? user.id === comment.createdBy.id : false;
  const [editable, setEditable] = useState(false);
  return (
    <CommentBlock>
      <CommentBlockContainer>
        <CommentProfile
          size={32}
          member={{ id: '1', profileIcon: { bgColor: '#ccc', initials: comment.createdBy.fullName.charAt(0) } }}
        />
        <CommentContent>
          <CommentContentHeader>
            <CommentCreatorName>{comment.createdBy.fullName}</CommentCreatorName>

            <CommentCreatorDate>
              {dayjs.duration(dayjs(comment.createdAt).diff(dayjs())).humanize(true)}
            </CommentCreatorDate>
          </CommentContentHeader>
          {editable ? (
            <CommentCreator
              autoFocus
              background={false}
              onCancel={() => setEditable(false)}
              initialComment={comment.summary}
              onCreateComment={newComment => {
                setEditable(false);
                onEdit(newComment);
              }}
            />
          ) : (
            <CommentSummary>{comment.summary}</CommentSummary>
          )}
        </CommentContent>
        {!editable && isEditable && (
          <ExtraActions
            ref={$actions}
            onClick={() => {
              showPopup(
                {
                  ref: $actions,
                },
                <CommentActions
                  onEdit={() => {
                    hidePopup();
                    setEditable(true);
                  }}
                  onDelete={onDelete}
                />,
                { width: 120, showDiamond: false },
              );
            }}
          >
            <Ellipsis width={12} height={12} />
          </ExtraActions>
        )}
      </CommentBlockContainer>
    </CommentBlock>
  );
};

type CommentsProps = {
  comments: Array<{ id: string; summary: string; createdAt: string; createdBy: { id: string; fullName: string } }>;
  onDelete: (id: string) => void;
  onEdit: (id: string, message: string) => void;
};

const Comments: React.FC<CommentsProps> = ({ comments, onDelete, onEdit }) => {
  return (
    <CommentsContainer>
      {comments.map(comment => (
        <Comment
          key={comment.id}
          onEdit={message => onEdit(comment.id, message)}
          comment={comment}
          onDelete={() => onDelete(comment.id)}
        />
      ))}
    </CommentsContainer>
  );
};

export default Comments;
